import { useEffect, useMemo, useState } from "react";

import { CustomerModel } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";

import { type CustomerRef } from "../../../../types";
import { type AccountConfig } from "./useMarketplaceAccounts";

export type CustomerWithRef = {
  data: WithFirebaseModel<CustomerModel>;
  ref: CustomerRef;
  costAnomalyEnabled?: boolean;
  activeProcurementAccounts?: AccountConfig[];
};

export type CustomersIdsMap = Omit<Map<string, CustomerWithRef>, "set" | "clear" | "delete">;

const transform = (
  data: WithFirebaseModel<CustomerModel>,
  docSnapshot: DocumentSnapshotModel<CustomerModel>
): CustomerWithRef => ({
  data,
  ref: docSnapshot.modelRef,
});

export const useMarketplaceCustomers = (): [CustomersIdsMap, boolean] => {
  const [customersSorted, setCustomerSorted] = useState<boolean>(false);
  const [customers, customersLoading] = useCollectionData(
    getCollection(CustomerModel).where("marketplace.GCP.accountExists", "==", true),
    {
      transform,
    }
  );

  useEffect(() => {
    if (!customersLoading && customers) {
      customers.sort((a, b) => a.data.primaryDomain.localeCompare(b.data.primaryDomain));
      setCustomerSorted(true);
    }
  }, [customers, customersLoading]);

  const customersIdsMap = useMemo(() => {
    const map = new Map<string, CustomerWithRef>();
    if (!customersLoading && customersSorted && customers) {
      customers.forEach((customer) => {
        map.set(customer.ref.id, customer);
      });
    }

    return map;
  }, [customers, customersLoading, customersSorted]);

  return [customersIdsMap, customersLoading];
};
