import { useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsResourceType, type DashboardModelSavedReportsModel, EarlyAccessFeature } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";

import { attributionText, globalText } from "../../../assets/texts";
import { helpURLs } from "../../../assets/urls";
import { LearnMoreAlert } from "../../../Components/Alerts";
import DeleteDialog from "../../../Components/DeleteDialog";
import { DoitConsoleTitle } from "../../../Components/DoitConsoleTitle";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import Hide from "../../../Components/HideChildren/Hide";
import { useFeatureFlag } from "../../../Components/hooks/useFeatureFlag";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { useAuthContext } from "../../../Context/AuthContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { useUserContext } from "../../../Context/UserContext";
import { type AttributionWRef } from "../../../types";
import mixpanel from "../../../utils/mixpanel";
import { addAnalyticsWindowFunction } from "../../../utils/windowInit";
import { useUserEmailNotification } from "../../UserView/UserViewTabs/useUserEmailNotification";
import { attributionsToAnalyticsResources } from "../analyticsResources/utils";
import { useCloudAnalyticsContext } from "../CloudAnalyticsContext";
import AllocationsMigrationAlert from "../components/AllocationsMigrationAlert";
import AssignLabelsButton from "../labels/components/AssignLabelsButton";
import EditPermissionsButton from "../labels/components/EditPermissionsButton";
import { useLabels } from "../labels/hooks";
import { isOwner, useCanEditSelectedObjects, useCanEditSelectedObjectsPermissions } from "../utilities";
import { filterColumns, headers } from "./AttributionBrowserColumns";
import { AttributionRow } from "./AttributionBrowserRow";
import { AttributionSettingsModal } from "./AttributionSettingsModal";
import AttributionShareDialog from "./AttributionShareDialog";
import { useDeleteAttribution } from "./DeleteAttributionValidation";
import { useCreateAttributionHandler } from "./hooks";

export type AttributionWRefRowItem = AttributionWRef & {
  anomalyDetectionText: string;
  subscribed: boolean;
  owner?: string;
};

const { attributions: attributionIds } = cloudAnalytics;

export const AttributionInfo = ({ onHide }: { onHide?: () => void }) => (
  <Hide mdDown>
    <LearnMoreAlert
      text={attributionText.LEARN_MORE_ALERT}
      url={helpURLs.CLOUD_ANALYTICS_ATTRIBUTIONS}
      onClose={onHide}
    />
  </Hide>
);

export type Report = { data: WithFirebaseModel<DashboardModelSavedReportsModel> };

type AttributionBrowserProps = {
  attributions: AttributionWRef[];
  loading: boolean;
};

const isUserOwner = (selected: AttributionWRef[], email: string) =>
  selected.every((s) => isOwner(email, s.data) && s.data.type === AnalyticsResourceType.CUSTOM);

const AttributionBrowser = ({ attributions, loading }: AttributionBrowserProps) => {
  const isEntitledAnalytics = useIsFeatureEntitled("analytics:attributions");
  const isAllocationsFeatureEnabled = useFeatureFlag(EarlyAccessFeature.ALLOCATIONS_PAGES);
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { userModel } = useUserContext({ allowNull: false });
  const routeMatchURL = useRouteMatchURL();
  const { handleDeleteValidationResponse } = useCloudAnalyticsContext();
  const userDailyDigests = useMemo(() => userModel.dailyDigests?.map((d) => d.id) || [], [userModel.dailyDigests]);
  const [labels, labelsLoading] = useLabels();
  const [selected, setSelected] = useState<AttributionWRef[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const isBulk = selected.length >= 1;

  const deleteAttribution = useDeleteAttribution();

  const { updateUserEmailNotification, userEmailNotification } = useUserEmailNotification(userModel.ref);

  const handleDeleteAttribution: () => Promise<void> = useCallback(() => {
    const ids = selected.map((s) => s.ref.id);
    return deleteAttribution({
      ids,
      onResponsePopulated: (data) => {
        handleDeleteValidationResponse(data);
      },
      onResponseEmpty: () => {
        setSelected([]);
        successSnackbar(attributionText.DELETE_ATTR_SUCCESS);
      },
      onError: () => {
        errorSnackbar(attributionText.DELETE_ATTR_ERROR);
      },
    });
  }, [deleteAttribution, errorSnackbar, handleDeleteValidationResponse, selected, successSnackbar]);

  useEffect(() => {
    const updatedSelectedAttributions = attributions.filter((attribution) =>
      selected.some((x) => x.ref.id === attribution.ref.id)
    );

    setSelected(updatedSelectedAttributions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributions]);

  useEffect(() => {
    mixpanel.track("analytics.attributions.list");
  }, []);

  const RowWrapper = useCallback(
    ({ row }: { row: AttributionWRefRowItem }) => (
      <AttributionRow
        row={row}
        labels={labels}
        handleShare={() => {
          setSelected([row]);
          setShareDialogOpen(true);
        }}
        handleSettings={() => {
          setSelected([row]);
          setIsSettingsModalOpen(true);
        }}
        userEmailNotification={userEmailNotification}
        updateUserEmailNotification={updateUserEmailNotification}
      />
    ),
    [
      labels,
      setSelected,
      setShareDialogOpen,
      setIsSettingsModalOpen,
      userEmailNotification,
      updateUserEmailNotification,
    ]
  );

  const getOwner = (attrData: AttributionWRef["data"]) => {
    let owner = "";
    if (attrData.collaborators.length) {
      for (const collaborator of attrData.collaborators) {
        if (collaborator.role === "owner") {
          owner = collaborator.email;
          break;
        }
      }
    }

    return owner;
  };

  const tableAttributions = useMemo<AttributionWRefRowItem[]>(
    () =>
      attributions.map((attr) => ({
        ...attr,
        anomalyDetectionText: attr.data.anomalyDetection ? "Active" : globalText.NA,
        subscribed: userDailyDigests.includes(attr.ref.id),
        owner: getOwner(attr.data),
      })),
    [attributions, userDailyDigests]
  );

  const handleNewAttribution = useCreateAttributionHandler({
    baseUrl: routeMatchURL,
    mixpanelEventName: "analytics.attributions.new",
  });

  useEffect(() => {
    addAnalyticsWindowFunction({ handleNewAttribution });
  }, [handleNewAttribution]);

  const customAttributions: AttributionWRef[] = useMemo(
    () => attributions.filter((attr) => attr.data.type === AnalyticsResourceType.CUSTOM),
    [attributions]
  );

  const notOwner = !isUserOwner(selected, currentUser.email);

  const deleteButtonTooltip = useMemo(() => {
    if (notOwner) {
      return attributionText.CANNOT_DELETE;
    }
    if (selected.map((s) => s.data.type).some((s) => s === AnalyticsResourceType.PRESET)) {
      return attributionText.ATTRIBUTION_PRESET_NOT_DELETE;
    }
    return "";
  }, [notOwner, selected]);

  const disableDeleteAttribution = useMemo<boolean>(
    () => notOwner || !selected.length || selected?.some((s) => s.data.type === AnalyticsResourceType.PRESET),
    [notOwner, selected]
  );

  const attributionsWithoutPresets = useMemo(() => selected.filter((x) => x.data.type !== "preset"), [selected]);

  const closeSharingDialog = useCallback(() => {
    setShareDialogOpen(false);
    setSelected([]);
  }, [setShareDialogOpen, setSelected]);

  const closeSettingsDialog = useCallback(() => {
    setIsSettingsModalOpen(false);
    setSelected([]);
  }, [setIsSettingsModalOpen, setSelected]);

  const closeDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(false);
  }, [setDeleteDialogOpen]);

  const canEditSelectedAttributions = useCanEditSelectedObjects(
    currentUser.email,
    attributionsWithoutPresets.map((x) => x.data)
  );

  const canEditPermissions = useCanEditSelectedObjectsPermissions(
    currentUser.email,
    attributionsWithoutPresets.map((x) => x.data)
  );

  if (labelsLoading) {
    return (
      <Box
        sx={{
          p: 1,
        }}
      >
        <FilterTableSkeleton />
      </Box>
    );
  }

  if (!labels) {
    return null;
  }

  return (
    <>
      <DoitConsoleTitle pageName="Attributions" />
      <Grid
        container
        sx={{
          alignItems: "center",
        }}
      >
        {!customAttributions.length && !loading && (
          <Grid size={12}>
            <AttributionInfo />
          </Grid>
        )}
        {loading ? (
          <Box
            sx={{
              p: 1,
            }}
          >
            <FilterTableSkeleton />
          </Box>
        ) : (
          <Grid size={12}>
            <FilterTable
              showRowsSelection={true}
              onRowsSelected={setSelected}
              tableItems={tableAttributions}
              rowComponent={RowWrapper}
              headerColumns={headers}
              filterColumns={filterColumns}
              filterBarPlaceholder={attributionText.FILTER_ATTRIBUTIONS}
              persistenceKey="cloud_analytics_attributions_v1"
              itemUniqIdentifierField="ref.id"
              defaultSortingColumnValue="data.timeModified"
              toolbarProps={{
                title: "Attributions",
              }}
            >
              <Grid>
                <Tooltip title={deleteButtonTooltip}>
                  <span>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setDeleteDialogOpen(true);
                      }}
                      disabled={disableDeleteAttribution || isAllocationsFeatureEnabled}
                      data-cy={attributionIds.browser.delete}
                    >
                      {globalText.DELETE}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
              <Grid>
                <AssignLabelsButton
                  labels={labels}
                  selectedResources={attributionsToAnalyticsResources(selected)}
                  disabled={!canEditSelectedAttributions || isAllocationsFeatureEnabled}
                />
              </Grid>
              <Grid>
                <EditPermissionsButton
                  selectedResources={attributionsToAnalyticsResources(selected)}
                  onClick={() => {
                    setShareDialogOpen(true);
                  }}
                  disabled={!canEditPermissions || isAllocationsFeatureEnabled}
                />
              </Grid>
              <Grid>
                <Hide mdDown>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNewAttribution}
                    data-cy={attributionIds.browser.newAttribution}
                    disabled={isAllocationsFeatureEnabled || !isEntitledAnalytics}
                  >
                    {attributionText.CREATE_NEW_ATTRIBUTION}
                  </Button>
                </Hide>
              </Grid>
              {isAllocationsFeatureEnabled && (
                <Grid size={12} sx={{ mt: 1 }}>
                  <AllocationsMigrationAlert />
                </Grid>
              )}
            </FilterTable>
          </Grid>
        )}
      </Grid>
      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          title={attributionText.DELETE_SELECTED}
          message={attributionText.DELETE_MESSAGE}
          onDelete={handleDeleteAttribution}
          onClose={closeDeleteDialog}
        />
      )}
      {shareDialogOpen && (
        <AttributionShareDialog
          {...{
            title: isBulk ? attributionText.SHARE_ATTRIBUTIONS : attributionText.SHARE_ATTRIBUTION,
            shareDialogOpen,
            closeDialog: closeSharingDialog,
            attributions: selected.map((x) => ({ ...x.data, id: x.ref.id })),
          }}
        />
      )}
      {isSettingsModalOpen && (
        <AttributionSettingsModal onClose={closeSettingsDialog} attributionId={selected[0]?.ref.id} />
      )}
    </>
  );
};

export default AttributionBrowser;
