import "reflect-metadata";

import {
  type ArrayPropertyOperatorsAny,
  type ArrayPropertyOperatorsSingle,
  type ArrayValueOperators,
  type DocIdOfTModel,
  type DocModelOfTModel,
  type DotPrefix,
  getCollectionName,
  type NormalizeModel,
  type OmitDeclFields,
  type Reference,
  type SubCollectionIdOfTModel,
  type SubCollectionOfTMModel,
  type Timestamp as ModelTimestamp,
  type TModelCtor,
  type Where,
  type WhereFilterOp,
} from "@doitintl/models-types";
import {
  addDoc,
  type AggregateField,
  type AggregateSpec,
  type AggregateSpecData,
  and,
  average,
  collection,
  collectionGroup,
  type CollectionReference,
  count,
  deleteDoc,
  doc,
  type DocumentChange,
  type DocumentChangeType,
  type DocumentData,
  documentId,
  DocumentReference,
  type DocumentSnapshot,
  endAt,
  endBefore,
  type FieldPath,
  type FieldValue,
  type Firestore,
  type FirestoreError,
  getAggregateFromServer,
  getDoc,
  getDocFromCache,
  getDocFromServer,
  getDocs,
  getDocsFromCache,
  getDocsFromServer,
  limit,
  limitToLast,
  loadBundle,
  type LoadBundleTask,
  namedQuery,
  onSnapshot,
  or,
  orderBy,
  type OrderByDirection,
  type Query,
  query,
  type QueryCompositeFilterConstraint,
  type QueryConstraint,
  type QueryDocumentSnapshot,
  queryEqual,
  type QueryFieldFilterConstraint,
  type QueryFilterConstraint,
  type QuerySnapshot,
  refEqual,
  runTransaction as firestoreRunTransaction,
  setDoc,
  type SetOptions,
  type SnapshotListenOptions,
  type SnapshotMetadata,
  type SnapshotOptions,
  startAfter,
  startAt,
  sum,
  Timestamp as FirestoreTimestamp,
  type Transaction,
  type Unsubscribe,
  updateDoc,
  where,
  type WriteBatch,
  writeBatch,
} from "firebase/firestore";
import type { IsAny, ReadonlyDeep, UnionToIntersection } from "type-fest";

import { getTracer } from "./Tracer";

type FirebaseErrorCallback = (error: FirestoreError) => void;
export type ErrorCallback = (error: Error) => void;

export type GetSnapshotOptions = {
  source?: "default" | "server" | "cache";
};

const removeLastPart = (str: string) => {
  const lastIndex = str.lastIndexOf("/");
  if (lastIndex === -1) {
    return null;
  }
  return str.substring(0, lastIndex);
};

function getProp(valGetter: { get: (prop: string) => any }, ks: string): any {
  const val = valGetter.get(ks);

  if (!val) {
    return val;
  }

  return convertorSingleton.decodeVal(val);
}

type CombineInner<T, K extends PropertyKey = T extends unknown ? keyof T : never> = T extends unknown
  ? T & Partial<Record<Exclude<K, keyof T>, never>>
  : never;

type Combine<T> = { [K in keyof CombineInner<T>]: CombineInner<T>[K] };

type ConvertModelSingleType<T> = T extends ModelTimestamp | FirestoreTimestamp
  ? FirestoreTimestamp
  : T extends Reference<infer TModel> | FirebaseModelReference<infer TModel>
    ? FirebaseModelReference<TModel>
    : T extends Array<infer U>
      ? Array<ConvertModelSingleType<U>>
      : T extends object
        ? { [K in keyof T]: ConvertModelSingleType<T[K]> }
        : T;

export type WithFirebaseModel<TModel extends DocumentData> = ConvertModelSingleType<TModel>;

type ConvertModelObjectAddValue<T> = T extends ModelTimestamp
  ? FieldValue | ModelTimestamp | FirestoreTimestamp | Date
  : T extends Reference<infer TModel extends DocumentData>
    ? FieldValue | Reference<TModel> | FirebaseModelReference<TModel>
    : T extends Array<infer U>
      ? FieldValue | Array<ConvertModelObjectAddValue<U>>
      : T extends object
        ? { [K in keyof T]: ConvertModelObjectAddValue<T[K]> }
        : FieldValue | T;

export type WithModelValue<TModel extends DocumentData> = ConvertModelObjectAddValue<Combine<TModel>>;

type FlattenWithPathsAndValue<T, Prefix extends string = ""> = {
  [K in keyof T & string]: IsAny<T[K]> extends true
    ? any
    : NonNullable<T[K]> extends
          | Reference<any>
          | FirebaseModelReference<any>
          | ModelTimestamp
          | FirestoreTimestamp
          | Array<any>
      ? { [P in DotPrefix<K, Prefix>]?: ConvertModelObjectAddValue<T[K]> }
      : NonNullable<T[K]> extends object
        ?
            | { [P in DotPrefix<K, Prefix>]?: ConvertModelObjectAddValue<T[K]> }
            | FlattenWithPathsAndValue<NonNullable<T[K]>, DotPrefix<K, Prefix>>
        : { [P in DotPrefix<K, Prefix>]?: ConvertModelObjectAddValue<T[K]> };
}[keyof T & string];

type FlattenWithPathsAndModel<T, Prefix extends string = ""> = {
  [K in keyof T & string]: IsAny<T[K]> extends true
    ? any
    : NonNullable<T[K]> extends
          | Reference<any>
          | FirebaseModelReference<any>
          | ModelTimestamp
          | FirestoreTimestamp
          | Array<any>
      ? { [P in DotPrefix<K, Prefix>]: ConvertModelSingleType<T[K]> }
      : NonNullable<T[K]> extends object
        ?
            | { [P in DotPrefix<K, Prefix>]: ConvertModelSingleType<T[K]> }
            | FlattenWithPathsAndModel<NonNullable<T[K]>, DotPrefix<K, Prefix>>
        : { [P in DotPrefix<K, Prefix>]: ConvertModelSingleType<T[K]> };
}[keyof T & string];

type UnionToOptionalObject<T> = {
  [K in T extends infer U ? keyof U : never]?: T extends { [P in K]?: infer V } ? V : never;
};

export type UpdateFields<TModel extends DocumentData> = UnionToOptionalObject<
  FlattenWithPathsAndValue<NormalizeModel<OmitDeclFields<TModel>>>
>;

export type ModelFields<TModel extends DocumentData> = UnionToIntersection<
  FlattenWithPathsAndModel<NormalizeModel<OmitDeclFields<TModel>>>
>;

abstract class BaseFirebaseDocumentSnapshotModel<TModel extends DocumentData> {
  protected constructor(protected readonly firebaseDoc: DocumentSnapshot<TModel>) {}

  get<TFields extends ModelFields<TModel>, TPath extends keyof TFields>(
    fieldPath: TPath
  ): ConvertModelSingleType<TFields[TPath]>;

  get(ks: string): any {
    return getProp(this.firebaseDoc, ks);
  }

  get id(): string {
    return this.firebaseDoc.id;
  }

  get metadata(): SnapshotMetadata {
    return this.firebaseDoc.metadata;
  }

  exists(): boolean {
    return this.firebaseDoc.exists();
  }

  get nativeRef(): DocumentReference<TModel> {
    return this.firebaseDoc.ref;
  }

  get modelRef(): FirebaseModelReference<TModel> {
    return new FirebaseModelReference<TModel>(new LazyDoc(this.firebaseDoc.ref));
  }

  get ref(): FirebaseModelReference<TModel> {
    return this.modelRef;
  }
}

export class FirebaseDocumentSnapshotModel<
  TModel extends DocumentData,
> extends BaseFirebaseDocumentSnapshotModel<TModel> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(firebaseDoc: DocumentSnapshot<TModel>) {
    // NOSONAR
    super(firebaseDoc);
  }

  // to be used only on legacy code that doesn't store models yet
  get snapshot(): DocumentSnapshot<TModel> {
    return this.firebaseDoc;
  }

  asModelData(options?: SnapshotOptions): WithFirebaseModel<TModel> | undefined {
    const nativeData = this.firebaseDoc.data(options);
    if (!nativeData) {
      return undefined;
    }
    return convertorSingleton.fromFirestoreData<TModel>(nativeData);
  }

  data(options?: SnapshotOptions) {
    return this.asModelData(options);
  }
}

export class FirebaseQueryDocumentSnapshotModel<
  TModel extends DocumentData,
> extends BaseFirebaseDocumentSnapshotModel<TModel> {
  constructor(private readonly firebaseQueryDoc: QueryDocumentSnapshot<TModel>) {
    super(firebaseQueryDoc);
  }

  // to be used only on legacy code that doesn't store models yet
  get snapshot(): QueryDocumentSnapshot<TModel> {
    return this.firebaseQueryDoc;
  }

  asModelData(options?: SnapshotOptions): WithFirebaseModel<TModel> {
    const nativeData = this.firebaseQueryDoc.data(options);
    return convertorSingleton.fromFirestoreData<TModel>(nativeData);
  }

  data(options?: SnapshotOptions) {
    return this.asModelData(options);
  }
}

const handleListenError = <TModel extends DocumentData>(
  { type, listenOn }: { type: "doc"; listenOn: DocumentReference<TModel> } | { type: "query"; listenOn: Query<TModel> },
  execCallback: (snapshot: any) => void,
  path: string,
  errorCallback: FirebaseErrorCallback | undefined,
  marker: Error,
  options: SnapshotListenOptions | undefined,
  queryKeys: string[] = []
): Unsubscribe => {
  const span = getTracer().startInactiveSpan({
    name: `listen ${type} ${path}`,
    op: "firestore",
    attributes: queryKeys.length > 0 ? { queryKeys } : {},
  });
  const wrapErrorCallback = (err: FirestoreError) => {
    span?.end();
    listenErrorCallbackSingleton?.(path, err, marker);
    if (errorCallback) {
      errorCallback(err);
    }
  };

  if (type === "doc") {
    return onSnapshot(
      listenOn,
      options as SnapshotListenOptions,
      (snapshot: DocumentSnapshot) => {
        span?.end({ count: 1 });
        execCallback(snapshot);
      },
      wrapErrorCallback
    );
  }

  return onSnapshot(
    listenOn,
    options as SnapshotListenOptions,
    (snapshot) => {
      span?.end({ count: snapshot.size });
      execCallback(snapshot);
    },
    wrapErrorCallback
  );
};

export type FirebaseDocumentSnapshotCallback<TModel extends DocumentData> = (
  snapshot: FirebaseDocumentSnapshotModel<TModel>
) => void;

export type FirebaseModelData<T> = WithFirebaseModel<OmitDeclFields<T>>;

export class PathError extends Error {
  constructor(error: any, path: string) {
    super(`PathError: ${error.message} in path: ${path}`);
  }
}

export class FirebaseModelReference<TModel extends DocumentData> {
  constructor(private readonly docRef: LazyDoc<TModel>) {}

  private getCollection<TSubCollectionModel extends DocumentData>(
    collectionName: string
  ): FirebaseCollectionReferenceModel<TSubCollectionModel> {
    if (this.docRef.hasValue) {
      const nativeCollection = collection(this.docRef.value, collectionName).withConverter(convertorSingleton);
      return new FirebaseCollectionReferenceModel<TSubCollectionModel>(
        new LazyCollection<TSubCollectionModel>(nativeCollection as CollectionReference<TSubCollectionModel>)
      );
    }

    const path = `${this.docRef.path}/${collectionName}`;
    return new FirebaseCollectionReferenceModel<TSubCollectionModel>(new LazyCollection(path));
  }

  collection<TSubCollectionModelName extends string & SubCollectionIdOfTModel<TModel>>(
    collectionName: TSubCollectionModelName
  ): FirebaseCollectionReferenceModel<SubCollectionOfTMModel<TModel, TSubCollectionModelName>> {
    return this.getCollection<SubCollectionOfTMModel<TModel, TSubCollectionModelName>>(collectionName);
  }

  onSnapshotWithOptions(
    options: SnapshotListenOptions | undefined,
    onNext: FirebaseDocumentSnapshotCallback<TModel>,
    onError?: FirebaseErrorCallback
  ): Unsubscribe {
    const marker = new Error();
    return handleListenError(
      { type: "doc", listenOn: this.docRef.value },
      (snapshot: DocumentSnapshot<TModel>) => {
        onNext(new FirebaseDocumentSnapshotModel(snapshot));
      },
      this.docRef.path,
      onError,
      marker,
      options,
      []
    );
  }

  onSnapshot(onNext: FirebaseDocumentSnapshotCallback<TModel>, onError?: FirebaseErrorCallback): Unsubscribe {
    return this.onSnapshotWithOptions({}, onNext, onError);
  }

  isEqual(other: FirebaseModelReference<TModel>): boolean {
    return refEqual(this.docRef.value, other.docRef.value);
  }

  get nativeRef(): DocumentReference<TModel> {
    return this.docRef.value;
  }

  get id(): string {
    return this.docRef.id;
  }

  get firestore(): Firestore {
    return this.docRef.value.firestore;
  }

  get path(): string {
    return this.docRef.path;
  }

  parentModel<TParent extends DocumentData>(): FirebaseCollectionReferenceModel<TParent> {
    if (this.docRef.hasValue) {
      const actualParent = this.docRef.value.parent;

      return new FirebaseCollectionReferenceModel<TParent>(
        new LazyCollection(actualParent as CollectionReference<TParent>)
      );
    }

    return new FirebaseCollectionReferenceModel<TParent>(new LazyCollection(this.docRef.parentPath));
  }

  async get(options?: GetSnapshotOptions): Promise<FirebaseDocumentSnapshotModel<TModel>> {
    const marker = new Error();
    const span = getTracer().startInactiveSpan({ name: `get ${this.docRef.path}`, op: "firestore" });
    try {
      if (options?.source === undefined || options.source === "default") {
        const doc = await getDoc(this.docRef.value);
        return new FirebaseDocumentSnapshotModel<TModel>(doc);
      }

      if (options.source === "server") {
        const doc = await getDocFromServer(this.docRef.value);
        return new FirebaseDocumentSnapshotModel<TModel>(doc);
      }

      const doc = await getDocFromCache(this.docRef.value);
      return new FirebaseDocumentSnapshotModel<TModel>(doc);
    } catch (err: any) {
      listenErrorCallbackSingleton?.(this.path, err, marker);
      throw err;
    } finally {
      span?.end();
    }
  }

  async delete(): Promise<void> {
    const span = getTracer().startInactiveSpan({ name: `delete ${this.docRef.path}`, op: "firestore" });
    try {
      await deleteDoc(this.docRef.value);
    } finally {
      span?.end();
    }
  }

  update(data: UpdateFields<TModel>): Promise<void>;

  update<TFields extends UpdateFields<TModel>, TPath extends keyof TFields>(
    field: TPath,
    value: TFields[TPath],
    ...moreFieldsAndValues: any[]
  ): Promise<void>;

  update(fieldOrData: UpdateFields<TModel>, value?: any, ...moreFieldsAndValues: any[]): Promise<void> {
    const convertData = (v: any) => convertorSingleton.toFirestore(v as TModel);

    const updaterWithCustomMessage = async (path: string, callback: () => Promise<void>) => {
      try {
        await callback();
      } catch (err) {
        throw new PathError(err, path);
      }
    };

    if (typeof fieldOrData !== "string") {
      const convertedData = convertData(fieldOrData);
      return updaterWithCustomMessage(this.docRef.path, () => {
        const span = getTracer().startInactiveSpan({ name: `update ${this.docRef.path}`, op: "firestore" });
        return updateDoc(this.docRef.value, convertedData).finally(() => {
          span?.end();
        });
      });
    }

    const keyValuesPair = moreFieldsAndValues.map((val, index) => {
      if (index % 2 === 0) {
        return val;
      }

      return convertData(val);
    });

    return updaterWithCustomMessage(this.docRef.path, () => {
      const span = getTracer().startInactiveSpan({ name: `update ${this.docRef.path}`, op: "firestore" });
      return updateDoc(this.docRef.value, fieldOrData, convertData(value), ...keyValuesPair).finally(() => {
        span?.end();
      });
    });
  }

  set(data: ReadonlyDeep<WithModelValue<TModel>>): Promise<void>;

  set(data: ReadonlyDeep<WithModelValue<Partial<TModel>>>, options: SetOptions): Promise<void>;

  async set(
    data: ReadonlyDeep<WithModelValue<Partial<TModel>>> | ReadonlyDeep<WithModelValue<TModel>>,
    options: SetOptions = {}
  ): Promise<void> {
    const marker = new Error();

    const span = getTracer().startInactiveSpan({ name: `set ${this.docRef.path}`, op: "firestore" });
    try {
      const actualData = convertorSingleton.toFirestore(data as any);
      await setDoc(this.docRef.value, actualData as Partial<TModel>, options);
    } catch (error: any) {
      listenErrorCallbackSingleton?.(this.docRef.path, error, marker);
      throw error;
    } finally {
      span?.end();
    }
  }

  narrow<TNarrow extends DocumentData>() {
    return new FirebaseModelReference<TNarrow>(this.docRef as LazyDoc<any>);
  }
}

export class FirebaseDocumentChangeModel<TModel extends DocumentData> {
  constructor(private readonly snapshotObj: DocumentChange<TModel>) {}

  get type(): DocumentChangeType {
    return this.snapshotObj.type;
  }

  get doc(): FirebaseQueryDocumentSnapshotModel<TModel> {
    return new FirebaseQueryDocumentSnapshotModel<TModel>(this.snapshotObj.doc);
  }

  get oldIndex(): number {
    return this.snapshotObj.oldIndex;
  }

  get newIndex(): number {
    return this.snapshotObj.newIndex;
  }
}

type FirebaseQueryDocumentSnapshotCallback<TModel extends DocumentData> = (
  snapshot: FirebaseQueryDocumentSnapshotModel<TModel>
) => void;

export class FirebaseQuerySnapshotModel<TModel extends DocumentData> {
  constructor(private readonly querySnapshot: QuerySnapshot<TModel>) {}

  get size(): number {
    return this.querySnapshot.size;
  }

  get empty(): boolean {
    return this.querySnapshot.empty;
  }

  get docs(): Array<FirebaseQueryDocumentSnapshotModel<TModel>> {
    return this.querySnapshot.docs.map((currentDoc) => new FirebaseQueryDocumentSnapshotModel<TModel>(currentDoc));
  }

  docChanges(options?: SnapshotListenOptions): Array<FirebaseDocumentChangeModel<TModel>> {
    return this.querySnapshot.docChanges(options).map((snapshot) => new FirebaseDocumentChangeModel<TModel>(snapshot));
  }

  get metadata(): SnapshotMetadata {
    return this.querySnapshot.metadata;
  }

  forEach(callback: FirebaseQueryDocumentSnapshotCallback<TModel>, thisArg?: any): void {
    this.querySnapshot.forEach((snapshot) => {
      callback(new FirebaseQueryDocumentSnapshotModel(snapshot));
    }, thisArg);
  }
}

export type FirebaseQuerySnapshotCallback<TModel extends DocumentData> = (
  snapshot: FirebaseQuerySnapshotModel<TModel>
) => void;

export class Filter<TFilter extends QueryCompositeFilterConstraint | QueryConstraint = QueryConstraint> {
  protected constructor(
    private readonly queryFilterConstraint: TFilter,
    public readonly queryKeys: string[]
  ) {}

  get queryCompositeFilterConstraint(): TFilter {
    return this.queryFilterConstraint;
  }

  static where<TModel extends DocumentData>(): {
    <TFields extends UpdateFields<TModel>, TPath extends keyof TFields>(
      fieldPath: TPath,
      opStr: ArrayValueOperators,
      value: Array<TFields[TPath]>
    ): Filter<QueryFieldFilterConstraint>;

    <TFields extends UpdateFields<TModel>, TPath extends keyof TFields>(
      fieldPath: TPath,
      opStr: ArrayPropertyOperatorsAny,
      value: TFields[TPath] extends Array<infer U> ? Array<U> : never
    ): Filter<QueryFieldFilterConstraint>;

    <TFields extends UpdateFields<TModel>, TPath extends keyof TFields>(
      fieldPath: TPath,
      opStr: ArrayPropertyOperatorsSingle,
      value: TFields[TPath] extends Array<infer U> ? U : never
    ): Filter<QueryFieldFilterConstraint>;

    <TFields extends UpdateFields<TModel>, TPath extends keyof TFields>(
      fieldPath: TPath,
      opStr: WhereFilterOp,
      value: TFields[TPath]
    ): Filter<QueryFieldFilterConstraint>;

    (fieldPath: FieldPath, opStr: WhereFilterOp, value: any): Filter<QueryFieldFilterConstraint>;
  };

  static where() {
    return (fieldPath: string | FieldPath, opStr: WhereFilterOp, value: any): Filter<QueryFieldFilterConstraint> =>
      new Filter(where(fieldPath, opStr, value), [
        `where ${fieldReplacer(fieldPath)} ${opStr} ${JSON.stringify(value, replacer)}`,
      ]);
  }

  static or(...queryConstraints: Filter<QueryFilterConstraint>[]) {
    return new Filter(
      or(...queryConstraints.map((queryConstraint) => queryConstraint.queryCompositeFilterConstraint)),
      ["or", queryConstraints.map((qc) => qc.queryKeys).join(",")]
    );
  }

  static and(...queryConstraints: Filter<QueryFilterConstraint>[]) {
    return new Filter<QueryCompositeFilterConstraint>(
      and(...queryConstraints.map((queryConstraint) => queryConstraint.queryCompositeFilterConstraint)),
      ["and", queryConstraints.map((qc) => qc.queryKeys).join(",")]
    );
  }
}

interface WhereComposite<TResult> {
  where(compositeFilter: Filter<QueryCompositeFilterConstraint>): TResult;
}

export class LazyDoc<TModel extends DocumentData> {
  private actualDocRef: DocumentReference<TModel> | null = null;

  constructor(private readonly docRef: string | DocumentReference<TModel>) {}

  get hasValue(): boolean {
    return !!(this.actualDocRef || typeof this.docRef !== "string");
  }

  get value(): DocumentReference<TModel> {
    if (this.actualDocRef) {
      return this.actualDocRef;
    }

    if (typeof this.docRef !== "string") {
      this.actualDocRef = this.docRef;
      return this.actualDocRef;
    }

    return doc(getDefaultClientIfNeeded(undefined), this.docRef).withConverter(
      convertorSingleton
    ) as DocumentReference<TModel>;
  }

  get path(): string {
    if (typeof this.docRef === "string") {
      return this.docRef;
    }

    return this.docRef.path;
  }

  get parentPath(): string {
    if (typeof this.docRef === "string") {
      const value = removeLastPart(this.path);

      // docs always have parent path
      if (!value) {
        throw new Error(`Invalid path ${this.path}`);
      }

      return value;
    }

    return this.docRef.parent.path;
  }

  get id(): string {
    if (typeof this.docRef === "string") {
      const parts = this.docRef.split("/");
      return parts[parts.length - 1];
    }

    return this.docRef.id;
  }
}

const autoIdNewId = (): string => {
  // Alphanumeric characters
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  // The largest byte value that is a multiple of `char.length`.
  const maxMultiple = Math.floor(256 / chars.length) * chars.length;

  let autoId = "";
  const targetLength = 20;
  while (autoId.length < targetLength) {
    const bytes = randomBytes(40);
    for (let i = 0; i < bytes.length; ++i) {
      // Only accept values that are [0, maxMultiple), this ensures they can
      // be evenly mapped to indices of `chars` via a modulo operation.
      if (autoId.length < targetLength && bytes[i] < maxMultiple) {
        autoId += chars.charAt(bytes[i] % chars.length);
      }
    }
  }

  return autoId;
};

export function randomBytes(nBytes: number): Uint8Array {
  const bytes = new Uint8Array(nBytes);
  globalThis.crypto.getRandomValues(bytes);
  return bytes;
}

class LazyQuery<TModel extends DocumentData> {
  constructor(private readonly query: (() => Query<TModel>) | Query<TModel>) {}

  get value(): Query<TModel> {
    if (typeof this.query === "function") {
      return this.query();
    }
    return this.query;
  }
}

class LazyCollection<TModel extends DocumentData> extends LazyQuery<TModel> {
  private actualCollectionRef: CollectionReference<TModel> | null = null;

  constructor(private readonly collectionRef: CollectionReference<TModel> | string) {
    super(
      typeof collectionRef === "string"
        ? () => collection(getDefaultClientIfNeeded(undefined), collectionRef) as CollectionReference<TModel>
        : collectionRef
    );
  }

  get id(): string {
    if (typeof this.collectionRef === "string") {
      const parts = this.collectionRef.split("/");
      return parts[parts.length - 1];
    }

    return this.collectionRef.id;
  }

  get path(): string {
    if (typeof this.collectionRef === "string") {
      return this.collectionRef;
    }

    return this.collectionRef.path;
  }

  get parentPath(): string | null {
    if (typeof this.collectionRef === "string") {
      return removeLastPart(this.path);
    }

    return this.collectionRef.parent?.path ?? null;
  }

  get hasValue(): boolean {
    return !!(this.actualCollectionRef || typeof this.collectionRef !== "string");
  }

  get value(): CollectionReference<TModel> {
    if (this.actualCollectionRef) {
      return this.actualCollectionRef;
    }

    if (typeof this.collectionRef !== "string") {
      this.actualCollectionRef = this.collectionRef;
      return this.actualCollectionRef;
    }

    return collection(getDefaultClientIfNeeded(undefined), this.collectionRef).withConverter(
      convertorSingleton
    ) as CollectionReference<TModel>;
  }

  doc(documentPath: string) {
    return new LazyDoc<TModel>(`${this.path}/${documentPath}`);
  }
}

const fieldReplacer = (field: FieldPath | string) => {
  if (typeof field === "string") {
    return field;
  }

  if (field.isEqual(documentId())) {
    return "documentId";
  }

  return JSON.stringify(field);
};

const replacer = (key: string, value: any) => {
  // If the key is an empty string, it means it's the root object
  if (value instanceof DocumentReference || value instanceof FirebaseModelReference) {
    return `Reference(${value.path})`;
  }

  if (value instanceof FirestoreTimestamp) {
    return `Timestamp(${value.toDate().toISOString()})`;
  }

  if (value instanceof Filter) {
    return `Filter(${JSON.stringify(value.queryKeys)})`;
  }

  return value;
};

type AggregatorMethod<TModel> = (field: keyof TModel) => AggregateField<number>;

type CountAggregatorMethod = () => AggregateField<number>;

export class FirebaseQueryModel<TModel extends DocumentData>
  implements
    Where<TModel, FirebaseQueryModel<TModel>, FieldPath, DocumentReference>,
    WhereComposite<FirebaseQueryModel<TModel>>
{
  constructor(
    public readonly collectionName: string,
    private readonly firebaseQuery: LazyQuery<TModel>,
    public readonly queryKeys: string[] = []
  ) {}

  getDocsFromCache(): Promise<FirebaseQuerySnapshotModel<TModel>> {
    return getDocsFromCache(this.firebaseQuery.value).then(
      (snapshot) => new FirebaseQuerySnapshotModel<TModel>(snapshot)
    );
  }

  isEqual(other: FirebaseQueryModel<TModel>): boolean {
    return queryEqual(this.firebaseQuery.value, other.firebaseQuery.value);
  }

  async aggregate<AggregateSpecType extends AggregateSpec>(
    aggregateSpecGenerator: ({
      sum,
      count,
      average,
    }: {
      sum: AggregatorMethod<TModel>;
      count: CountAggregatorMethod;
      average: AggregatorMethod<TModel>;
    }) => AggregateSpecType
  ): Promise<AggregateSpecData<AggregateSpecType>> {
    const aggregateSpec = aggregateSpecGenerator({
      sum: sum as AggregatorMethod<TModel>,
      count,
      average: average as AggregatorMethod<TModel>,
    });
    const query = this.firebaseQuery.value;
    const snapshot = await getAggregateFromServer(query, aggregateSpec);
    return snapshot.data();
  }

  where(
    fieldPathOrCompositeFilter: FieldPath | string | Filter<QueryCompositeFilterConstraint>,
    opStr?: WhereFilterOp,
    value?: unknown
  ): FirebaseQueryModel<TModel> {
    if (fieldPathOrCompositeFilter instanceof Filter) {
      const whereQuery = query(this.firebaseQuery.value, fieldPathOrCompositeFilter.queryCompositeFilterConstraint);

      return new FirebaseQueryModel<TModel>(this.collectionName, new LazyQuery<TModel>(whereQuery), [
        ...this.queryKeys,
        ...fieldPathOrCompositeFilter.queryKeys,
      ]);
    }

    if (value === undefined) {
      throw new Error(`field ${JSON.stringify(fieldPathOrCompositeFilter)} queried with undefined value`);
    }

    const actualValue = convertorSingleton.toFirestore(value as TModel);

    const whereQuery = query(this.firebaseQuery.value, where(fieldPathOrCompositeFilter, opStr ?? "==", actualValue));

    return new FirebaseQueryModel<TModel>(this.collectionName, new LazyQuery<TModel>(whereQuery), [
      ...this.queryKeys,
      `where ${fieldReplacer(fieldPathOrCompositeFilter)} ${opStr} ${JSON.stringify(value, replacer)}`,
    ]);
  }

  limit(limitResult: number): FirebaseQueryModel<TModel> {
    const limitQuery = query(this.firebaseQuery.value, limit(limitResult));

    return new FirebaseQueryModel<TModel>(this.collectionName, new LazyQuery(limitQuery), [
      ...this.queryKeys,
      `limit ${limitResult}`,
    ]);
  }

  limitToLast(limitResult: number): FirebaseQueryModel<TModel> {
    const limitToLastByQuery = query(this.firebaseQuery.value, limitToLast(limitResult));

    return new FirebaseQueryModel<TModel>(this.collectionName, new LazyQuery(limitToLastByQuery), [
      ...this.queryKeys,
      `limitToLast ${limitResult}`,
    ]);
  }

  startAfter(...fieldValues: any[]): FirebaseQueryModel<TModel> {
    const startAfterQuery = query(this.firebaseQuery.value, startAfter(...fieldValues));

    return new FirebaseQueryModel<TModel>(this.collectionName, new LazyQuery(startAfterQuery), [
      ...this.queryKeys,
      `startAfter ${JSON.stringify(fieldValues)}`,
    ]);
  }

  startAt(...fieldValues: any[]): FirebaseQueryModel<TModel> {
    const startAtQuery = query(this.firebaseQuery.value, startAt(...fieldValues));

    return new FirebaseQueryModel<TModel>(this.collectionName, new LazyQuery(startAtQuery), [
      ...this.queryKeys,
      `startAt ${JSON.stringify(fieldValues)}`,
    ]);
  }

  endBefore(...fieldValues: any[]): FirebaseQueryModel<TModel> {
    const endBeforeQuery = query(this.firebaseQuery.value, endBefore(...fieldValues));

    return new FirebaseQueryModel<TModel>(this.collectionName, new LazyQuery(endBeforeQuery), [
      ...this.queryKeys,
      `endBefore ${JSON.stringify(fieldValues)}`,
    ]);
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  orderBy<TFields extends UpdateFields<TModel>, TPath extends keyof TFields>(
    fieldPath: TPath | keyof TModel,
    direction?: OrderByDirection
  ): FirebaseQueryModel<TModel>;

  orderBy(fieldPath: string, direction?: OrderByDirection): FirebaseQueryModel<TModel> {
    const orderByQuery = query(this.firebaseQuery.value, orderBy(fieldPath, direction));

    return new FirebaseQueryModel<TModel>(this.collectionName, new LazyQuery(orderByQuery), [
      ...this.queryKeys,
      `orderBy ${fieldPath} ${direction}`,
    ]);
  }

  endAt(...fieldValues: any[]): FirebaseQueryModel<TModel> {
    const orderByQuery = query(this.firebaseQuery.value, endAt(...fieldValues));

    return new FirebaseQueryModel<TModel>(this.collectionName, new LazyQuery(orderByQuery), [
      ...this.queryKeys,
      `endAt ${JSON.stringify(fieldValues)}`,
    ]);
  }

  onSnapshotWithOptions(
    options: SnapshotListenOptions | undefined,
    onNext: FirebaseQuerySnapshotCallback<TModel>,
    onError?: FirebaseErrorCallback
  ): Unsubscribe {
    const marker = new Error();
    return handleListenError(
      { type: "query", listenOn: this.firebaseQuery.value },
      (snapshot: QuerySnapshot<TModel>) => {
        onNext(new FirebaseQuerySnapshotModel<TModel>(snapshot));
      },
      this.collectionName,
      onError,
      marker,
      options,
      this.queryKeys
    );
  }

  onSnapshot(onNext: FirebaseQuerySnapshotCallback<TModel>, onError?: FirebaseErrorCallback): Unsubscribe {
    return this.onSnapshotWithOptions({}, onNext, onError);
  }

  async get(options?: GetSnapshotOptions): Promise<FirebaseQuerySnapshotModel<TModel>> {
    const marker = new Error();

    const span = getTracer().startInactiveSpan({
      name: `query ${this.collectionName}`,
      op: "firestore",
      attributes: this.queryKeys.length > 0 ? { query: this.queryKeys.join(",") } : {},
    });
    try {
      if (!options || options.source === "default") {
        const snapshot = await getDocs(this.firebaseQuery.value);
        return new FirebaseQuerySnapshotModel<TModel>(snapshot);
      }

      if (options.source === "server") {
        const snapshot = await getDocsFromServer(this.firebaseQuery.value);
        return new FirebaseQuerySnapshotModel<TModel>(snapshot);
      }

      const snapshot = await getDocsFromCache(this.firebaseQuery.value);
      return new FirebaseQuerySnapshotModel<TModel>(snapshot);
    } catch (err: any) {
      listenErrorCallbackSingleton?.(this.collectionName, err, marker);
      throw err;
    } finally {
      span?.end();
    }
  }

  get firestore(): Firestore {
    return this.firebaseQuery.value.firestore;
  }

  narrow<TNarrow extends DocumentData>() {
    return new FirebaseQueryModel<TNarrow>(this.collectionName, this.firebaseQuery as LazyQuery<any>);
  }
}

export class FirebaseCollectionReferenceModel<TModel extends DocumentData> extends FirebaseQueryModel<TModel> {
  constructor(private readonly firebaseCollection: LazyCollection<TModel>) {
    super(firebaseCollection.path, firebaseCollection);
  }

  isEqual(other: FirebaseCollectionReferenceModel<TModel>): boolean {
    return queryEqual(this.firebaseCollection.value, other.firebaseCollection.value);
  }

  doc<TDocId extends string & DocIdOfTModel<TModel>>(
    documentPath: TDocId
  ): FirebaseModelReference<DocModelOfTModel<TModel, TDocId>> {
    if (!documentPath) {
      throw new Error("documentPath is required");
    }

    const firebaseCollection = this.firebaseCollection;
    const docRef = this.firebaseCollection.hasValue
      ? new LazyDoc(doc(firebaseCollection.value, documentPath) as DocumentReference<DocModelOfTModel<TModel, TDocId>>)
      : new LazyDoc<DocModelOfTModel<TModel, TDocId>>(`${firebaseCollection.path}/${documentPath}`);

    return new FirebaseModelReference<DocModelOfTModel<TModel, TDocId>>(docRef);
  }

  newDoc(): FirebaseModelReference<TModel> {
    const documentPath = autoIdNewId();
    const firebaseCollection = this.firebaseCollection;
    const docRef = this.firebaseCollection.hasValue
      ? new LazyDoc(doc(firebaseCollection.value, documentPath))
      : new LazyDoc<TModel>(`${firebaseCollection.path}/${documentPath}`);
    return new FirebaseModelReference<TModel>(docRef);
  }

  async add(data: ReadonlyDeep<WithModelValue<OmitDeclFields<TModel>>>): Promise<FirebaseModelReference<TModel>> {
    const marker = new Error();
    try {
      const firebaseDoc = await addDoc(this.firebaseCollection.value, data as TModel);
      return new FirebaseModelReference<TModel>(new LazyDoc(firebaseDoc));
    } catch (err: any) {
      listenErrorCallbackSingleton?.(this.firebaseCollection.path, err, marker);
      throw err;
    }
  }

  get path(): string {
    return this.firebaseCollection.path;
  }

  get id(): string {
    return this.firebaseCollection.id;
  }

  parentModel<TParent extends DocumentData>(): FirebaseModelReference<TParent> | null {
    if (this.firebaseCollection.hasValue) {
      const value = this.firebaseCollection.value.parent;
      if (!value) {
        return value;
      }

      return new FirebaseModelReference<TParent>(new LazyDoc<TParent>(value as DocumentReference<TParent>));
    }

    const parentPath = this.firebaseCollection.parentPath;

    if (!parentPath) {
      return null;
    }

    return new FirebaseModelReference<TParent>(new LazyDoc<TParent>(parentPath));
  }
}

class ModelRefConverter {
  protected constructor() {
    // this is expected
  }

  static create() {
    return new ModelRefConverter();
  }

  static encodeObj(obj: any, encodeFunc: (val: any) => any, result?: any): any {
    if (obj) {
      result = result || {};

      Object.keys(obj).forEach((key) => {
        const val = obj[key];
        if (val === undefined) {
          return;
        }
        result[key] = encodeFunc(val);
      });

      return result;
    }

    return obj;
  }

  private static isPlainObject(val: any) {
    return val && typeof val === "object" && Object.getPrototypeOf(val) === Object.prototype;
  }

  private static isDocumentReference(val: any) {
    return val instanceof DocumentReference;
  }

  private static isArrayRemoveOrUnion(val: any, encodeVal: (val: any) => any) {
    if (!val) {
      return;
    }

    if (val._methodName !== "arrayRemove" && val._methodName !== "arrayUnion") {
      return;
    }

    const [[elementsKey, elementsVal]] = Object.entries(val).filter(([key]) => key !== "_methodName");

    val[elementsKey] = encodeVal(elementsVal);

    return val;
  }

  toFirestore<TModel extends DocumentData>(data: WithModelValue<Partial<TModel>>): any {
    function encodeVal(val: any): any {
      if (val instanceof FirebaseModelReference) {
        return val.nativeRef;
      }

      if (val instanceof LazyDoc) {
        return val.value;
      }

      if (Array.isArray(val)) {
        return val.map((v: any) => encodeVal(v));
      }

      if (ModelRefConverter.isArrayRemoveOrUnion(val, encodeVal)) {
        return val;
      }

      if (ModelRefConverter.isDocumentReference(val)) {
        return val;
      }

      if (ModelRefConverter.isPlainObject(val)) {
        return ModelRefConverter.encodeObj(val, encodeVal);
      }

      return val;
    }

    return encodeVal(data);
  }

  decodeVal(val: any): any {
    if (ModelRefConverter.isDocumentReference(val)) {
      return new FirebaseModelReference(new LazyDoc(val));
    }

    if (Array.isArray(val)) {
      // We need to verify that no array value contains a document transform
      return val.map((v) => this.decodeVal(v));
    }

    if (ModelRefConverter.isPlainObject(val)) {
      return ModelRefConverter.encodeObj(val, (decodedVal) => this.decodeVal(decodedVal));
    }

    return val;
  }

  fromFirestore<TModel extends DocumentData>(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): WithFirebaseModel<TModel> {
    const data = snapshot.data(options);

    return ModelRefConverter.encodeObj(data, (val) => this.decodeVal(val)) as WithFirebaseModel<TModel>;
  }

  fromFirestoreData<TModel extends DocumentData, IDField extends string = "", RefField extends string = "">(
    data: DocumentData,
    creator?: new () => TModel
  ): ModelData<TModel, IDField, RefField> {
    return ModelRefConverter.encodeObj(data, (val) => this.decodeVal(val), creator ? new creator() : {});
  }
}

type ListenErrorCallback = (path: string, err: Error, marker: Error) => void;

const convertorSingleton = ModelRefConverter.create();

let firestoreSingleton: Firestore | null;
let listenErrorCallbackSingleton: ListenErrorCallback | null;

export function setFirestoreSingleton(
  singleton: Firestore | null,
  { listenErrorCallback }: { listenErrorCallback?: ListenErrorCallback } = {}
) {
  listenErrorCallbackSingleton = listenErrorCallback ?? listenErrorCallbackSingleton;
  firestoreSingleton = singleton;
}

function getDefaultClientIfNeeded(firestoreInstance: Firestore | undefined): Firestore {
  if (firestoreInstance) {
    return firestoreInstance;
  }

  if (!firestoreSingleton) {
    throw new Error("setFirestoreSingleton() was not called");
  }

  return firestoreSingleton;
}

export type ModelData<
  T extends DocumentData,
  IDField extends string | undefined = undefined,
  RefField extends string | undefined = undefined,
  SnapshotField extends string | undefined = undefined,
  UseData extends boolean = false,
> = (UseData extends true ? { data: WithFirebaseModel<T> } : WithFirebaseModel<T>) &
  (IDField extends string ? Record<IDField, string> : unknown) &
  (RefField extends string ? Record<RefField, FirebaseModelReference<T>> : unknown) &
  (SnapshotField extends string ? Record<SnapshotField, FirebaseQueryDocumentSnapshotModel<T>> : unknown);

export type IDOptions<
  IDField extends string | undefined = undefined,
  RefField extends string | undefined = undefined,
> = {
  idField?: IDField;
  refField?: RefField;
};

export type Model<TModel> = OmitDeclFields<TModel>;
export type ModelIdRef<TModel extends DocumentData> = ModelData<TModel, "id", "ref">;
export type ModelId<TModel extends DocumentData> = ModelData<TModel, "id">;
export type ModelRef<TModel extends DocumentData> = ModelData<TModel, undefined, "ref">;
export type ModelWithDataAndRef<TModel extends DocumentData> = {
  data: WithFirebaseModel<TModel>;
  ref: FirebaseModelReference<TModel>;
};

type WithId = { id: string };
export type ModelWithDataAndId<TModel extends DocumentData> = {
  data: WithFirebaseModel<TModel>;
} & WithId;

export type ModelWithRefIdAndData<TModel extends DocumentData> = ModelWithDataAndRef<TModel> & WithId;

interface Updater {
  update(documentRef: DocumentReference<any>, data: WithModelValue<any>): this;
  update(
    documentRef: DocumentReference<any>,
    field: string | FieldPath,
    value: unknown,
    ...moreFieldsAndValues: any[]
  ): this;
}

function updateEntity<TModel extends DocumentData, TResult extends Updater>(
  callee: TResult,
  documentRef: FirebaseModelReference<TModel>,
  fieldOrData: UpdateFields<TModel> | string,
  value?: any,
  ...moreFieldsAndValues: any[]
): TResult {
  if (typeof fieldOrData === "string") {
    const convertedData = convertorSingleton.toFirestore(value as TModel);
    return callee.update(documentRef.nativeRef, fieldOrData, convertedData, ...moreFieldsAndValues);
  }

  const convertedData = convertorSingleton.toFirestore(fieldOrData);
  return callee.update(documentRef.nativeRef, convertedData);
}

interface Setter {
  set<TModel extends DocumentData>(
    documentRef: DocumentReference<TModel>,
    data: WithModelValue<OmitDeclFields<TModel>>,
    options?: SetOptions
  ): this;
}

function setEntity<TModel extends DocumentData, TResult extends Setter>(
  callee: TResult,
  documentRef: FirebaseModelReference<TModel>,
  data: WithModelValue<Partial<TModel>>,
  options?: SetOptions
): TResult {
  const convertedData = convertorSingleton.toFirestore(data);

  if (options) {
    return callee.set(documentRef.nativeRef, convertedData, options);
  }

  return callee.set(documentRef.nativeRef, convertedData);
}

export class FirebaseModelWriteBatch {
  constructor(private readonly batch: WriteBatch) {}

  set<TModel extends DocumentData>(
    documentRef: FirebaseModelReference<TModel>,
    data: WithModelValue<Partial<TModel>>,
    options?: SetOptions
  ): FirebaseModelWriteBatch {
    const batch = setEntity<TModel, typeof this.batch>(this.batch, documentRef, data, options);

    return new FirebaseModelWriteBatch(batch);
  }

  update<TModel extends DocumentData, TFields extends UpdateFields<TModel>, TPath extends keyof TFields>(
    documentRef: FirebaseModelReference<TModel>,
    fieldPath: TPath,
    value: TFields[TPath]
  ): FirebaseModelWriteBatch;

  update<TModel extends DocumentData>(
    documentRef: FirebaseModelReference<TModel>,
    data: UpdateFields<TModel>
  ): FirebaseModelWriteBatch;

  update<TModel extends DocumentData>(
    documentRef: FirebaseModelReference<TModel>,
    fieldOrData: UpdateFields<TModel> | string,
    value?: any,
    ...moreFieldsAndValues: any[]
  ): FirebaseModelWriteBatch {
    const batch = updateEntity<TModel, typeof this.batch>(
      this.batch,
      documentRef,
      fieldOrData,
      value,
      ...moreFieldsAndValues
    );

    return new FirebaseModelWriteBatch(batch);
  }

  delete(documentRef: FirebaseModelReference<any>): FirebaseModelWriteBatch {
    return new FirebaseModelWriteBatch(this.batch.delete(documentRef.nativeRef));
  }

  commit(): Promise<void> {
    return this.batch.commit();
  }
}

export class TransactionModel {
  constructor(private readonly transaction: Transaction) {}

  async get<TModel extends DocumentData>(
    documentRef: FirebaseModelReference<TModel>
  ): Promise<FirebaseDocumentSnapshotModel<TModel>> {
    const span = getTracer().startInactiveSpan({ name: `get tx ${documentRef.path}`, op: "firestore" });
    try {
      return new FirebaseDocumentSnapshotModel<TModel>(await this.transaction.get(documentRef.nativeRef));
    } finally {
      span?.end();
    }
  }

  delete<TModel extends DocumentData>(documentRef: FirebaseModelReference<TModel>): TransactionModel {
    return new TransactionModel(this.transaction.delete(documentRef.nativeRef));
  }

  update<TModel extends DocumentData, TFields extends UpdateFields<TModel>, TPath extends keyof TFields>(
    documentRef: FirebaseModelReference<TModel>,
    fieldPath: TPath,
    value: TFields[TPath]
  ): TransactionModel;

  update<TModel extends DocumentData>(
    documentRef: FirebaseModelReference<TModel>,
    data: UpdateFields<TModel>
  ): TransactionModel;

  update<TModel extends DocumentData>(
    documentRef: FirebaseModelReference<TModel>,
    fieldOrData: UpdateFields<TModel> | (string & keyof TModel),
    value?: any,
    ...moreFieldsAndValues: any[]
  ): TransactionModel {
    const tx = updateEntity<TModel, typeof this.transaction>(
      this.transaction,
      documentRef,
      fieldOrData,
      value,
      ...moreFieldsAndValues
    );

    return new TransactionModel(tx);
  }

  set<TModel extends DocumentData>(
    documentRef: FirebaseModelReference<TModel>,
    data: WithModelValue<OmitDeclFields<TModel>>
  ): TransactionModel;

  set<TModel extends DocumentData>(
    documentRef: FirebaseModelReference<TModel>,
    data: WithModelValue<Partial<TModel>>,
    options: SetOptions
  ): TransactionModel;

  set<TModel extends DocumentData>(
    documentRef: FirebaseModelReference<TModel>,
    data: WithModelValue<Partial<TModel>>,
    options?: SetOptions
  ): TransactionModel {
    const tx = setEntity<TModel, typeof this.transaction>(this.transaction, documentRef, data, options);

    return new TransactionModel(tx);
  }
}

export const runTransaction = (
  updateFunction: (transaction: TransactionModel) => Promise<void> | void,
  firestoreInstance?: Firestore
): Promise<void> => {
  const span = getTracer().startInactiveSpan({ name: "tx", op: "firestore" });
  const firestore = getDefaultClientIfNeeded(firestoreInstance);

  return firestoreRunTransaction(firestore, async (transaction) => {
    const transactionWrap = new TransactionModel(transaction);
    const result = updateFunction(transactionWrap);
    if (result instanceof Promise) {
      return result.finally(() => {
        span?.end();
      });
    }
    span?.end();
    return Promise.resolve();
  });
};

export function addIdRef<
  TModel extends DocumentData,
  IDField extends string | undefined = undefined,
  RefField extends string | undefined = undefined,
>(modelData: TModel, doc: DocumentSnapshot<TModel | DocumentData>, options?: IDOptions<IDField, RefField>) {
  if (options?.idField) {
    (modelData as any)[options.idField] = doc.id;
  }

  if (options?.refField) {
    (modelData as any)[options.refField] = new FirebaseModelReference(new LazyDoc(doc.ref));
  }

  return modelData;
}

export function asFirestoreModelFromDocument<
  TModel extends DocumentData,
  IDField extends string = "",
  RefField extends string = "",
>(
  documentSnapshot: DocumentSnapshot<TModel | DocumentData>,
  creator: new () => TModel,
  options?: IDOptions<IDField, RefField>
): ModelData<TModel, IDField, RefField> | undefined {
  const data = documentSnapshot.data();
  if (!data) {
    return undefined;
  }

  const modelData = convertorSingleton.fromFirestoreData<TModel, IDField, RefField>(data, creator);

  return addIdRef(modelData, documentSnapshot, options);
}

export function asFirestoreModelFromSnapshot<
  TModel extends DocumentData,
  IDField extends string = "",
  RefField extends string = "",
>(
  querySnapshot: QueryDocumentSnapshot<TModel | DocumentData>,
  creator: new () => TModel,
  options?: IDOptions<IDField, RefField>
): ModelData<TModel, IDField, RefField> {
  const modelData = convertorSingleton.fromFirestoreData<TModel, IDField, RefField>(querySnapshot.data(), creator);

  return addIdRef(modelData, querySnapshot, options);
}

export function asModelRefFromFirestoreRef<TModel extends DocumentData>(
  ref: DocumentReference
): FirebaseModelReference<TModel> {
  return new FirebaseModelReference<TModel>(new LazyDoc(ref as DocumentReference<TModel>));
}

export const modelFromPath = <TModel extends DocumentData>(
  path: string,
  firestoreInstance?: Firestore
): FirebaseModelReference<TModel> => {
  const firestoreClient = getDefaultClientIfNeeded(firestoreInstance);

  const docRef = doc(firestoreClient, path) as DocumentReference<TModel>;
  return new FirebaseModelReference<TModel>(new LazyDoc(docRef));
};

export function getCollection<TModel extends DocumentData>(
  collectionType: TModelCtor<TModel>,
  firestoreInstance?: Firestore
): FirebaseCollectionReferenceModel<TModel> {
  if (arguments.length > 1 && !firestoreInstance) {
    throw new Error("firestoreInstance cannot be undefined if passed as an argument.");
  }

  const collectionName = getCollectionName(collectionType);

  if (firestoreInstance) {
    const firestoreCollection = collection(firestoreInstance, collectionName).withConverter(
      convertorSingleton
    ) as CollectionReference<TModel>;

    return new FirebaseCollectionReferenceModel<TModel>(new LazyCollection(firestoreCollection));
  }

  return new FirebaseCollectionReferenceModel<TModel>(new LazyCollection(collectionName));
}

export function getCollectionGroup<TModel extends DocumentData>(
  collectionType: TModelCtor<TModel>,
  firestoreInstance?: Firestore
): FirebaseQueryModel<TModel> {
  const collectionName = getCollectionName(collectionType);

  const firestore = getDefaultClientIfNeeded(firestoreInstance);

  const firebaseQuery = collectionGroup(firestore, collectionName).withConverter(
    convertorSingleton
  ) as CollectionReference<TModel>;

  return new FirebaseQueryModel<TModel>(collectionName, new LazyQuery(firebaseQuery));
}

export function getBatch(firestoreInstance?: Firestore): FirebaseModelWriteBatch {
  const firebaseClient = getDefaultClientIfNeeded(firestoreInstance as Firestore);

  return new FirebaseModelWriteBatch(writeBatch(firebaseClient));
}

export function docChangesToToArray<TModel extends DocumentData, TValue>(
  querySnapshot: FirebaseQuerySnapshotModel<TModel>,
  processValue: (docSnapshot: FirebaseQueryDocumentSnapshotModel<TModel>) => TValue,
  items: TValue[]
) {
  querySnapshot.docChanges().forEach((change) => {
    switch (change.type) {
      case "added":
        items.splice(change.newIndex, 0, processValue(change.doc));
        break;

      case "modified":
        items.splice(change.newIndex, 1, processValue(change.doc));
        break;

      case "removed":
        items.splice(change.oldIndex, 1);
        break;
    }
  });
}

// iterate query documents using a callback
export function forEachDoc<TModel extends DocumentData>(
  querySnapshot: FirebaseQuerySnapshotModel<TModel>,
  processValue: (docSnapshot: FirebaseQueryDocumentSnapshotModel<TModel>) => void
) {
  querySnapshot.docs.forEach((firebaseDoc) => {
    processValue(firebaseDoc);
  });
}

// map a query documents using a callback
export function mapDocsToArray<TModel extends DocumentData, TValue>(
  querySnapshot: FirebaseQuerySnapshotModel<TModel>,
  processValue: (docSnapshot: FirebaseQueryDocumentSnapshotModel<TModel>) => TValue
) {
  return querySnapshot.docs.map((firebaseDoc): TValue => processValue(firebaseDoc));
}

// convert TModel into firestore document data
export function convertModel(data: any) {
  return convertorSingleton.toFirestore(data);
}

export const firestoreLoadBundle = (
  bundleData: ReadableStream<Uint8Array> | ArrayBuffer | string | Uint8Array,
  firestoreInstance?: Firestore
): LoadBundleTask => {
  const firestore = getDefaultClientIfNeeded(firestoreInstance);

  return loadBundle(firestore, bundleData as ReadableStream<Uint8Array> | ArrayBuffer | string);
};

export const firestoreNamedQuery = <TModel extends DocumentData>(name: string, firestoreInstance?: Firestore) => {
  const firestore = getDefaultClientIfNeeded(firestoreInstance);
  return namedQuery(firestore, name).then((query) => {
    if (!query) {
      throw new Error(`NamedQuery ${name} not found`);
    }

    return new FirebaseQueryModel<TModel>(name, new LazyQuery<TModel>(query as Query<TModel>));
  });
};

export const getDocsFromNamedQuery = <TModel extends DocumentData>(name: string, firestoreInstance?: Firestore) => {
  const span = getTracer().startInactiveSpan({
    name: `cache ${name}`,
    op: "firestore",
  });

  return firestoreNamedQuery<TModel>(name, firestoreInstance).then((query) =>
    query.getDocsFromCache().then((data) => {
      span?.end({ count: data.size });
      return data;
    })
  );
};
