import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import drilldown from "highcharts/modules/drilldown";
import HighchartsReact from "highcharts-react-official";
import addTreemapModule from "highcharts/modules/treemap";
import Highcharts from "highcharts";
import { blue } from "@mui/material/colors";
import orderBy from "lodash/orderBy";
import { useTheme } from "@mui/material/styles";
import { SuperQueryModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { getColors } from "../../../Pages/CloudAnalytics/utilities";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { SkeletonCard } from "../SkeletonCard";
import { WidgetCardHeader } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { useBigQueryLensDashboardContext } from "./useBigQueryLensDashboardContext";

drilldown(Highcharts);
addTreemapModule(Highcharts);

export default function TableTypeCard({ fallbackComponent, widgetHeight = 200 }) {
  const theme = useTheme();
  const [data, setData] = useState();
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const { selectedTimeframe } = useBigQueryLensDashboardContext();
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(
    () =>
      getCollection(SuperQueryModel)
        .doc("simulation-optimisation")
        .collection("output")
        .doc(customer.id)
        .collection("tables")
        .doc(selectedTimeframe)
        .onSnapshot((docSnapshot) => {
          const docData = docSnapshot.asModelData();
          if (docData) {
            const optimisationObj = {
              data: docData.data,
              ref: docSnapshot.ref,
            };
            setData(optimisationObj);
          } else {
            setData(null);
          }
        }),
    [customer.id, selectedTimeframe]
  );

  useEffect(() => {
    const graphData = data?.data;

    if (!graphData) {
      return;
    }

    let arr = [];
    const valueData = [];
    Object.keys(graphData).forEach((item) => {
      arr.push({
        title: item,
        TB: graphData[item].TB,
        percentage: graphData[item].percentage,
        tables: graphData[item].tables,
      });
    });
    arr = orderBy(arr, ["TB"]).reverse();
    arr.forEach((item, i) => {
      const treemapColors = getColors(theme.palette.mode);

      valueData.push({
        value: Math.sqrt(Math.abs(item.TB)),
        name: `${item.title} (${item.percentage}%)`,
        id: item.title,
        treemapColor: treemapColors[i] || blue[100],
        drilldown: true,
      });

      if (item.tables) {
        item.tables.forEach((table) => {
          valueData.push({
            value: parseFloat(table.value.toFixed(2)),
            color: treemapColors[i] || blue[100],
            name: table.tableName,
            parent: item.title,
          });
        });
      }
    });

    setChartOptions({
      colors: getColors(theme.palette.mode),
      chart: {
        borderColor: "#43A046",
        height: widgetHeight - 20,
        backgroundColor: "transparent",
        events: {
          drilldown(e) {
            Highcharts.fireEvent(this.series[0], "click", {
              point: this.get(e.point.drillId),
            });
          },
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: null,
      },
      plotOptions: {
        treemap: {
          allowDrillToNode: true,

          animation: {
            duration: 600,
          },
          dataLabels: {
            enabled: true,
            style: {
              color: "#000",
              fontWeight: "bold",
              textOutline: "0px contrast",
            },
          },
        },
      },
      drilldown: {
        activeAxisLabelStyle: {
          color: "#fff",
          fontWeight: "bold",
          textOutline: "0px contrast",
        },
        activeDataLabelStyle: {
          color: "#fff",
          fontWeight: "normal",
          textOutline: "0px contrast",
          textDecoration: "blink",
        },
      },
      series: [
        {
          type: "treemap",
          layoutAlgorithm: "squarified",
          allowDrillToNode: true,
          name: "TB scanned",
          animationLimit: 1,
          tooltip: {
            valueSuffix: " TB",
            valueDecimals: 2,
          },
          dataLabels: {
            enabled: false,
            formatter() {
              const { treemapColor, color } = this.point;
              return `<div style="color: ${theme.palette.getContrastText(treemapColor || color)}">${
                this.point.name
              }</div>`;
            },
            style: {
              textOutline: "0px contrast",
            },
          },
          levelIsConstant: false,
          levels: [
            {
              level: 1,
              dataLabels: {
                enabled: true,
                color: theme.palette.text.primary,
              },
              borderWidth: 1,
              borderColor: theme.palette.background.paper,
            },
          ],
          data: valueData,
        },
      ],
    });
  }, [data, widgetHeight, theme.palette.mode, theme.palette]);

  if (data === null) {
    return fallbackComponent;
  }

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Card>
      <WidgetCardHeader title="Scans by Table Type" subheader="evaluate your scans efficiency" />
      <CardContent sx={{ height: widgetHeight, p: 0, m: 0 }}>
        {chartOptions && (
          <HighchartsReact highcharts={Highcharts} options={chartOptions} allowChartUpdate={true} updateArgs={[true]} />
        )}
      </CardContent>
    </Card>
  );
}
