import { useEffect } from "react";

import { useHistory } from "react-router-dom";

import { Loader } from "../../Components/Dashboard/Loader";
import { useAuthContext } from "../../Context/AuthContext";

export const RedirectPage = () => {
  const { customerId } = useAuthContext({ mustHaveUser: true });
  const history = useHistory();

  useEffect(() => {
    if (customerId) {
      history.replace(`/customers/${customerId}/cloud-diagrams`);
    } else {
      history.replace("/customers");
    }
  }, [customerId, history]);

  return <Loader />;
};

export default RedirectPage;
