import { useMemo } from "react";

import {
  type GcpMarketplacePlanHistory,
  type GcpMarketplacePlanHistoryModel,
  MarketplaceModel,
} from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type ModelReference,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";

import { type EntitlementConfig } from "./useMarketplaceEntitlements";

export type PlanHistoryConfig = {
  data: WithFirebaseModel<GcpMarketplacePlanHistoryModel>;
  ref: ModelReference<GcpMarketplacePlanHistoryModel>;
};

export const useMarketplacePlanHistory = (
  entitlements: EntitlementConfig[] | undefined
): [Map<string, GcpMarketplacePlanHistory[]>] => {
  const transformDoc = (
    data: WithFirebaseModel<GcpMarketplacePlanHistoryModel>,
    docSnapshot: DocumentSnapshotModel<GcpMarketplacePlanHistoryModel>
  ): PlanHistoryConfig => ({
    data,
    ref: docSnapshot.ref,
  });

  const dataQuery = getCollection(MarketplaceModel).doc("gcp-marketplace").collection("gcpMarketplacePlanHistory");

  const [planHistory] = useCollectionData(dataQuery, {
    transform: transformDoc,
  });

  const planHistoryMap = useMemo(
    () =>
      (planHistory || [])
        .filter((p) => (entitlements ?? []).map((entitlement) => entitlement.ref.id).includes(p.ref.id))
        .reduce((map, p) => {
          map.set(p.ref.id, p.data.history);
          return map;
        }, new Map<string, GcpMarketplacePlanHistory[]>()),
    [entitlements, planHistory]
  );

  return [planHistoryMap];
};
