import { ConversationHistoryPeriod, type TicketAsset } from "./types";

export const avaPeriods: Record<ConversationHistoryPeriod, string> = {
  [ConversationHistoryPeriod.TODAY]: "Today",
  [ConversationHistoryPeriod.PREV_7]: "Previous 7 days",
  [ConversationHistoryPeriod.PREV_30]: "Previous 30 days",
  [ConversationHistoryPeriod.OLDER]: "Over 30 days",
};

export const NO_PROJECT_ASSET: TicketAsset = {
  asset: "no_project",
  identifier: "No project, general question",
};
