import React from "react";

import { TableCell, Typography } from "@mui/material";
import { type QueryObserverResult } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { CellsWrapper } from "../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { LimitedListWithTooltip } from "../../../../Components/LimitedListWithTooltip";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../utils";
import { capitalizeStartCase } from "../../../../utils/common";
import { dateFormatDayMonthYear } from "../../../../utils/dateTimeFormats";
import { useAzureConnectApi } from "../hooks";
import { type AzureSubscription } from "../types";

type Props = {
  row: AzureSubscription;
  refetch: () => Promise<QueryObserverResult<AzureSubscription[], unknown>>;
};

export const RowComponent = ({ row, refetch }: Props) => {
  const { customer } = useCustomerContext();
  const api = useAzureConnectApi();
  const handleUnlinkClicked = (customerId: string, tenantId: string) => {
    api
      .removeAzureRole(customerId, tenantId)
      .then(() => refetch().catch())
      .catch(consoleErrorWithSentry);
  };

  const menuOptions: ThreeDotsMenuOption[] = [
    {
      label: <Typography>UnLink</Typography>,
      action: () => {
        handleUnlinkClicked(customer.id, row.tenantId);
      },
      key: `unlink-${row.subscriptionId}`,
    },
  ];

  const formatTimeLinked = (timeLinked: string) => {
    const timestamp = DateTime.fromISO(timeLinked);
    return timestamp.toFormat(dateFormatDayMonthYear);
  };
  return (
    <CellsWrapper>
      <TableCell>
        <Typography color="inherit" variant="body2">
          {row.displayName}
        </Typography>
      </TableCell>
      <TableCell>{capitalizeStartCase(row.tenantId)}</TableCell>
      <TableCell>{capitalizeStartCase(row.subscriptionId)}</TableCell>
      <TableCell>
        <LimitedListWithTooltip
          items={row.features.map((feature: string) => capitalizeStartCase(feature) as string)}
          limit={2}
          title="Enabled features"
        />
      </TableCell>
      <TableCell>{formatTimeLinked(row.timeLinked)}</TableCell>
      <TableCell padding="checkbox">
        <ThreeDotsMenu options={menuOptions} />
      </TableCell>
    </CellsWrapper>
  );
};
