import { type ComponentProps, type ReactNode } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import type Button from "@mui/material/Button";

import { type SegmentEventProperties } from "../utils/useSegmentTrackEvent";
import MixpanelButton from "./MixpanelButton";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

type Props = {
  loading: boolean;
  children: ReactNode;
  mixpanelEventId: string;
  eventProperties?: any;
  target?: string;
  segmentTrackEvent?: (eventName: string, properties?: SegmentEventProperties) => void;
  segmentEventName?: string;
} & ComponentProps<typeof Button>;

const LoadingButton = ({ loading, children, ...otherProps }: Props) => {
  const classes = useStyles();

  return (
    <MixpanelButton {...otherProps} disabled={otherProps.disabled || loading}>
      {children}
      {loading && (
        <CircularProgress disableShrink={true} color="primary" size={24} className={classes.buttonProgress} />
      )}
    </MixpanelButton>
  );
};

export default LoadingButton;
