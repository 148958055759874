import { useCallback, useEffect, useMemo, useState } from "react";

import {
  ContractModel,
  type DataConnectorDatadogModel,
  DataConnectorModel,
  type DataConnectorSnowflakeModel,
  type EntitlementKey,
  EntityModel,
  InternalTierNames,
  TierPackageTypes,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTier } from "../../Context/TierProvider";
import { type CustomerRef } from "../../types";
import { consoleErrorWithSentry } from "../../utils";

export function useIsThereSignedContract({
  entityId,
  typeFilter,
}: {
  entityId: string | null;
  typeFilter: ContractModel["type"][];
}) {
  const [isLoadingContracts, setIsLoading] = useState(true);
  const [isThereSignedContract, setIsSigned] = useState(false);
  const { customer } = useCustomerContext();

  useEffect(() => {
    if (!entityId) {
      setIsLoading(false);
      return;
    }

    const entityRef = getCollection(EntityModel).doc(entityId);

    const query = getCollection(ContractModel)
      .where("customer", "==", customer.ref)
      .where("entity", "==", entityRef)
      .where("active", "==", true)
      .where("type", "in", typeFilter);

    return query.onSnapshot((docSnapshot) => {
      setIsSigned(docSnapshot.docs.length > 0);
      setIsLoading(false);
    });
  }, [entityId, customer.ref, typeFilter]);

  return { isLoadingContracts, isThereSignedContract };
}

export function useIsThereAnySignedContract({
  entityIds,
  typeFilter,
}: {
  entityIds: string[];
  typeFilter: ContractModel["type"][];
}) {
  const [isThereAnySignedContract, setIsAnySigned] = useState(false);
  const { customer } = useCustomerContext();
  const entityRefs: ModelReference<EntityModel>[] = useMemo(() => [], []);
  for (const id of entityIds) {
    entityRefs.push(getCollection(EntityModel).doc(id));
  }
  async function handler({
    entitiesRef,
    customer,
    typeFilter,
  }: {
    entitiesRef: ModelReference<EntityModel>[];
    customer: CustomerRef;
    typeFilter: ContractModel["type"][];
  }) {
    const query = await getCollection(ContractModel)
      .where("customer", "==", customer)
      .where("active", "==", true)
      .where("type", "in", typeFilter)
      .get();
    for (const doc of query.docs) {
      const contract = doc.data();
      if (entitiesRef.find((ref) => ref.id === contract.entity?.id)) {
        setIsAnySigned(true);
        break;
      }
    }
  }
  useEffect(() => {
    handler({
      entitiesRef: entityRefs,
      customer: customer.ref,
      typeFilter,
    });
  }, [entityIds, entityRefs, customer.ref, typeFilter]);
  return { isThereAnySignedContract };
}

export function useAllowedToAddAssets(platform: string, entitlementName: EntitlementKey) {
  const { getCustomerTier, isFeatureEntitled } = useTier();
  const { isDoitEmployee } = useAuthContext();
  const { customer, isProductOnlyCustomer } = useCustomerContext();

  const navigatorCustomerTier = getCustomerTier(TierPackageTypes.NAVIGATOR);
  const solveCustomerTier = getCustomerTier(TierPackageTypes.SOLVE);
  const hasEntitlement = isFeatureEntitled(entitlementName);

  return useMemo(() => {
    if (isDoitEmployee) {
      return { allowedToAddResoldAssets: true, allowedToAddStandaloneAssets: hasEntitlement };
    }

    const allowedToAddResoldAssets =
      navigatorCustomerTier?.name !== InternalTierNames.PRESENTATION &&
      (platform !== "google-cloud" || !isProductOnlyCustomer);

    const allowedToAddStandaloneAssets =
      (!isProductOnlyCustomer || customer?.onboarding?.startTrialInitiated) &&
      ((navigatorCustomerTier !== undefined &&
        navigatorCustomerTier?.name !== InternalTierNames.PRESENTATION &&
        navigatorCustomerTier?.name !== InternalTierNames.ESSENTIALS &&
        navigatorCustomerTier?.name !== InternalTierNames.ZERO_ENTITLEMENTS &&
        !navigatorCustomerTier.name.startsWith(InternalTierNames.HERITAGE)) ||
        (solveCustomerTier !== undefined &&
          solveCustomerTier?.name !== InternalTierNames.ZERO_ENTITLEMENTS &&
          solveCustomerTier.name !== InternalTierNames.HERITAGE));

    return { allowedToAddResoldAssets, allowedToAddStandaloneAssets };
  }, [
    isDoitEmployee,
    navigatorCustomerTier,
    platform,
    isProductOnlyCustomer,
    customer?.onboarding?.startTrialInitiated,
    solveCustomerTier,
    hasEntitlement,
  ]);
}

export function getSnowflakeConfigDocs(customerId: string, callback: (configs: DataConnectorSnowflakeModel[]) => void) {
  return getCollection(DataConnectorModel)
    .doc("snowflake")
    .collection("snowflake-configuration")
    .where("customerId", "==", customerId)
    .onSnapshot(
      (docSnapshot) => {
        const snowflakeConfigs: DataConnectorSnowflakeModel[] = [];
        docSnapshot.forEach((doc) => {
          snowflakeConfigs.push(doc.asModelData());
        });
        callback(snowflakeConfigs);
      },
      () => {
        consoleErrorWithSentry("Error fetching snowflake configurations");
      }
    );
}

export const useDatadogConfigs = (customerId: string, isFeatureActive: boolean) => {
  const [loading, setLoading] = useState(false);
  const [datadogConfigs, setDatadogConfigs] = useState<DataConnectorDatadogModel[] | undefined>();

  const loadConfigs = useCallback(async () => {
    setLoading(true);
    try {
      const configSnaps = await getCollection(DataConnectorModel)
        .doc("datadog")
        .collection("datadog-configuration")
        .where("customerId", "==", customerId)
        .get();

      const configPromises = configSnaps.docs.map((configSnap) => configSnap.asModelData());

      const configs = await Promise.all(configPromises);

      setDatadogConfigs(configs);
      setLoading(false);
    } catch (err) {
      consoleErrorWithSentry(`Error fetching datadog configurations for customer ${customerId}`);
    } finally {
      setLoading(false);
    }
  }, [customerId]);

  useEffect(() => {
    if (isFeatureActive) {
      loadConfigs();
    }
  }, [isFeatureActive, loadConfigs]);

  return { datadogConfigs, loading };
};
