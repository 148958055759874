import React, { useMemo, useState } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Divider, TextField, Typography } from "@mui/material";
import { type AzureFeaturePermission, type AzureFeatureRole } from "@services/cmp/models";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { AZURE_FEATURE_APP, copyCommandNames, copyCommandVariables, rolePathPrefixes } from "../consts";
import { getCommandCopy } from "../utils";
import type { Scope } from "../types";

type Props = {
  feature: AzureFeaturePermission;
  roleChanged: (featureName: string, roleName: string, scope: Scope, resourceId: string, roleId: string) => void;
  openConsoleButton: boolean;
  featureNameTitle: boolean;
};

export const FeatureForm = ({ feature, roleChanged, openConsoleButton, featureNameTitle }: Props) => {
  const [resourcesIds, setResourcesIds] = useState<string[]>([]);
  const [rolesIds, setRoleIds] = useState<string[]>([]);
  const [copyClicked, setCopyClicked] = useState<boolean[]>([]);

  const commands: string[] = useMemo<string[]>(
    () =>
      feature.roles.map(
        (role: AzureFeatureRole) =>
          `az role assignment create --assignee  ${AZURE_FEATURE_APP.clientId} --role ${role.name} --scope ${rolePathPrefixes[role.scope]}/${copyCommandVariables[role.scope]} --query name --output tsv`
      ),
    [feature]
  );

  const updateCopyClicked = (idx: number, state: boolean) => {
    const newCopyClicked = [...copyClicked];
    newCopyClicked[idx] = state;
    setCopyClicked(newCopyClicked);
  };
  return (
    <>
      {featureNameTitle && <Typography variant="h4">{feature.description}</Typography>}
      {feature.roles.map((role, roleIdx) => (
        <React.Fragment key={`role-fragment-${roleIdx}`}>
          <Typography variant="body2" sx={{ opacity: 0.87 }}>
            1. Enter {copyCommandNames[role.scope]}
          </Typography>
          <TextField
            required
            label={copyCommandNames[role.scope]}
            name={copyCommandNames[role.scope]}
            variant="outlined"
            value={resourcesIds[roleIdx]}
            error={!resourcesIds[roleIdx] && copyClicked[roleIdx]}
            onChange={(e) => {
              const resources = [...resourcesIds];
              resources[roleIdx] = e.target.value;
              setResourcesIds(resources);
              roleChanged(feature.name, role.name, role.scope, e.target.value, rolesIds[roleIdx]);
              updateCopyClicked(roleIdx, false);
            }}
          />

          <Typography variant="body2" sx={{ opacity: 0.87 }}>
            2. Run the following commands on the Azure portal
          </Typography>
          {openConsoleButton && (
            <>
              <Button
                startIcon={<OpenInNewIcon />}
                variant="outlined"
                sx={{ alignSelf: "flex-start" }}
                href="https://portal.azure.com/#cloudshell"
                target="_blank"
              >
                Open Azure portal
              </Button>
            </>
          )}
          <Typography variant="body2" sx={{ opacity: 0.6 }}>
            Assign role {feature.roles[roleIdx].name} to {feature.roles[roleIdx].scope}
          </Typography>

          <CopyCodeBlock
            base={commands[roleIdx] || ""}
            callbackClick={() => {
              updateCopyClicked(roleIdx, true);
            }}
            variables={getCommandCopy(feature.roles[roleIdx].scope, resourcesIds[roleIdx])}
          />
          <Typography variant="body2" sx={{ opacity: 0.87 }}>
            3. Paste role ID response
          </Typography>

          <TextField
            required
            key={`roleId-input-${roleIdx}`}
            label={`Role ID`}
            variant="outlined"
            value={rolesIds[roleIdx]}
            onChange={(e) => {
              const roles = [...rolesIds];
              roles[roleIdx] = e.target.value;
              setRoleIds(roles);
              roleChanged(feature.name, role.name, role.scope, resourcesIds[roleIdx], e.target.value);
            }}
          />
          {featureNameTitle && <Divider sx={{ mt: 3, mb: 3 }} />}
        </React.Fragment>
      ))}
    </>
  );
};
