import { type JSX } from "react";

import CloseIcon from "@mui/icons-material/CloseRounded";
import { Alert, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";

type Props = {
  text: string;
  setHidden?: (hidden: boolean) => void;
  actions?: JSX.Element;
};

export default function Info({ setHidden, text, actions }: Props) {
  return (
    <Grid
      container
      spacing={1}
      data-testid="info"
      data-cy="info"
      sx={{
        alignItems: "flex-start",
        pb: 1,
      }}
    >
      <Grid size={12}>
        <Alert
          severity="info"
          action={[
            actions ? actions : <></>,
            setHidden && (
              <IconButton
                key="dismiss"
                aria-label="Dismiss"
                size="small"
                sx={{ ml: 1 }}
                onClick={() => {
                  setHidden(true);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
          ]}
        >
          {text}
        </Alert>
      </Grid>
    </Grid>
  );
}
