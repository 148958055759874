import { collection, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type CustomerModel } from ".";

type AsgItemConfig = {
  fallbackOnDemand: boolean;
  keepUpToDate: boolean;
  excludedInstanceTypes: string[];
  excludedAvailabilityZones: string[];
  excludedSubnets: string[];
};

type AsgAdditionalDetails = {
  allOnDemand: boolean;
  instances: string[];
};

type AsgInstancesCosts = {
  spotHourCost: number;
  onDemandHourCost: number;
};

type AsgCosts = {
  cur: AsgInstancesCosts;
  rec: AsgInstancesCosts;
  averageDesiredCapacity: number;
};

type SpotisizeInfo = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  curAsg: any;
  curAsgDetails: AsgAdditionalDetails;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recAsg: any;
  costs: AsgCosts;
};

type InstanceTypeDetails = {
  compute: number;
  memory: number;
};

type SubnetDetails = {
  availabilityZone: string;
  cidr: string;
  isDefault: boolean;
};

type MonthlyAsgUsage = {
  onDemandInstances: UsageInstancesDetails;
  spotInstances: UsageInstancesDetails;
  totalSavings: number;
};

type AsgInstancesUsage = {
  totalCost: number;
  totalHours: number;
};

type UsageInstancesDetails = AsgInstancesUsage & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instances?: any;
};

class AsgBasicInfo {
  accountId!: string;

  region!: string;

  asgName!: string;
}

@subCollection("asgs")
export class Spot0ModelAsgsModel extends AsgBasicInfo {
  customer!: Reference<CustomerModel>;

  mode!: string;

  timeStartedUsingSpots?: Timestamp;

  config!: AsgItemConfig;

  spotisize!: SpotisizeInfo;

  instanceTypesDetails!: Record<string, InstanceTypeDetails>;

  subnetsDetails!: Record<string, SubnetDetails>;

  spotisizeNotSupported!: boolean;

  spotisizeErrorDesc?: string;

  error?: string;

  accountName!: string;

  managedStatus!: string;

  timeCreated!: Timestamp;

  timeModified!: Timestamp;

  usage!: Record<string, MonthlyAsgUsage>;
}

class SpotDoc {
  subCollections?: {
    asgs: Spot0ModelAsgsModel;
  };
}

@collection("spot0")
export class Spot0Model {
  docs?: {
    spotApp: SpotDoc;
  };
}
