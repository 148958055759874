import { useEffect, useState } from "react";

import CloudDiagramsDemo from "@client/src/assets/clouddiagrams/demo-preview.png";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

declare global {
  interface Window {
    NavatticEmbed?: {
      loadEmbeds: () => void;
    };
  }
}

const NavatticEmbed = () => {
  useEffect(() => {
    const d = document;
    const w = "https://js.navattic.com/embeds.js";

    const loadEmbeds = () => {
      // Check if NavatticEmbed exists in window object
      if (typeof window.NavatticEmbed !== "undefined") {
        window.NavatticEmbed.loadEmbeds();
      } else {
        // Fallback: manually set iframe src if NavatticEmbed is not available
        d.querySelectorAll("iframe[data-navattic-src]:not([src])").forEach((e) => {
          const iframe = e as HTMLIFrameElement;
          if (iframe.dataset.navatticSrc) {
            iframe.src = iframe.dataset.navatticSrc;
          }
        });
      }
    };

    // Check if script is already loaded
    if (d.querySelector(`script[src="${w}"]`) === null) {
      const s = d.createElement("script");
      s.src = w;
      s.onload = loadEmbeds;
      s.onerror = loadEmbeds; // Fallback in case of error
      d.body.appendChild(s);
    } else {
      loadEmbeds(); // If script already exists, just try to load embeds
    }
  }, []);

  return (
    <Box>
      {/* eslint-disable-next-line @eslint-react/dom/no-missing-iframe-sandbox */}
      <iframe
        data-navattic-src="https://capture.navattic.com/cm8q2aiuo003l03ky52yk1ihi"
        data-navattic-placeholder-src="https://app.navattic.com/api/poster/cm8q2aiuo003l03ky52yk1ihi"
        data-navattic-button-text="Take a self-guided tour"
        data-navattic-demo-id="cm8q2aiuo003l03ky52yk1ihi"
        style={{
          position: "absolute",
          border: "none",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%",
          margin: 0,
          padding: 0,
          zIndex: 999999,
        }}
      />
    </Box>
  );
};

const IntroTour = () => {
  const [isDemoOpen, setIsDemoOpen] = useState(false);

  return (
    <Stack sx={{ backgroundColor: "general.backgroundDark" }}>
      <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
        <Grid container justifyContent="center">
          <Grid size={{ sm: 12, md: 8 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "500",
                textAlign: "center",
              }}
              data-cy="clouddiagrams-demo-title"
            >
              Experience Cloud Diagrams in action
            </Typography>
            <Typography variant="body1" sx={{ m: "26px 0" }}>
              Take a self-guided tour to explore how Cloud Diagrams visualizes your cloud architecture in real-time,
              helping you pinpoint issues faster, track changes over time, and break down team knowledge silos.
            </Typography>
            <Box
              onClick={() => {
                setIsDemoOpen(true);
              }}
              sx={{ cursor: "pointer" }}
              data-cy="clouddiagrams-demo-preview"
            >
              <CardMedia
                component="img"
                image={CloudDiagramsDemo}
                alt="Cloud Diagrams Demo"
                sx={{
                  width: "100%",
                  height: "auto",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                }}
              />
            </Box>
            {isDemoOpen && (
              <Dialog
                open={isDemoOpen}
                onClose={() => {
                  setIsDemoOpen(false);
                }}
                // fullWidth
                // maxWidth="lg"
                fullScreen
                sx={{
                  "& .MuiDialog-paper": {
                    height: "calc(100vh - 64px)",
                    width: "calc(100vw - 64px)",
                  },
                }}
              >
                <DialogContent>
                  <NavatticEmbed />
                </DialogContent>
              </Dialog>
            )}
          </Grid>
        </Grid>
      </Container>
    </Stack>
  );
};

export default IntroTour;
