export const errorsText = {
  OUTSIDE_ORGANIZATION: {
    DESCRIPTION: "The report you are trying to run is outside of your organization (%s)",
    LIST: ["contact your admin to gain access"],
  },
  EMPTY: {
    DESCRIPTION:
      "This configuration did not return any results. You can try adjusting the filters, limits, or time range.",
  },
  SERIES_TOO_LARGE: {
    DESCRIPTION:
      "Uh, oh... This report created too many series and cannot be displayed properly.<br /><br />You can try these suggestions:",
    LIST: [
      "Add a filter to reduce the amount of data in your report",
      "Use a metric filter",
      'Use less fields within the "Group by" section',
    ],
  },
  TOO_LARGE: {
    DESCRIPTION:
      "Uh, oh.. This report exceeds data point limit for browser display.<br /><br />To resolve, consider the following actions:",
    LIST: ["Apply filters to dimensions", "Select fewer dimensions", "Choose a more limited time period"],
    LINK: {
      DESC: "Retrieve report via ",
      TITLE: "Reports API",
    },
  },
  QUERY_TIMEOUT: {
    DESCRIPTION:
      "Well, looks like this report is taking too long to compute.<br />Please try again later or contact support.",
  },
  EMPTY_DUE_TO_TREND: {
    DESCRIPTION:
      "This report doesn't have any trending dimensions.<br />Try removing Trending Up/Down checkbox, please.",
  },
  QUERY_TIMEOUT_524: {
    TITLE: "This report took too long to run",
    DESCRIPTION:
      "This report is too large to run. Consider reducing the scope or use the <b>Cache report</b> option to view the report later when it’s available. You’ll also get an <b>in-app notification</b> when the report is ready. ",
  },
  EXCEEDED_FIRESTORE_LIMIT: {
    DESCRIPTION:
      "The report is too large to cache, please try removing some dimensions or filters to reduce the size of the report",
  },
  DEFAULT: {
    DESCRIPTION:
      "Err.. we could not build your report due to a problem with our infrastructure.<br />Our technical staff have been notified and will be looking into this with the utmost urgency.<br /><br />We are sorry for the inconvenience!",
  },
  CACHE_REPORT_BTN: "Cache report",
  NOT_EDITOR_TOOLTIP: "Please contact the report owner to be given permission to cache this report",
  NOT_SAVED_TOOLTIP: "Please save the report before caching",
  ENTITLEMENT_NOT_ENABLED: {
    DESCRIPTION: "You do not have access to the entitlement to run this report",
  },
  LEARN_MORE: "Learn more",
  NO_ACCESS_METRIC: "You no longer have access to this metric, change the metric to see report results",
  BQ_PROXY_NOT_FOUND: {
    DESCRIPTION:
      "The data you requested is unavailable. This may happen if the dataset or table no longer exists or has been removed",
  },
  BQ_PROXY_FORBIDDEN: {
    DESCRIPTION:
      "You do not have the necessary permissions to access this data. This could be due to insufficient access rights or restricted resource settings",
  },
  BQ_PROXY_IAM_PERMISSION_DENIED: {
    DESCRIPTION:
      "Your access permissions do not allow this operation. This is likely due to restrictions in your assigned roles or IAM policy",
  },
};
