import { type PropsWithChildren } from "react";

import { Button, type ButtonProps } from "@mui/material";
import { type Dict } from "mixpanel-browser";

import mixpanel from "../utils/mixpanel";
import { type SegmentEventProperties } from "../utils/useSegmentTrackEvent";

type Props = PropsWithChildren<ButtonProps> & {
  mixpanelEventId: string;
  eventProperties?: Dict;
  segmentTrackEvent?: (eventName: string, properties?: SegmentEventProperties) => void;
  segmentEventName?: string;
};

const MixpanelButton = ({
  mixpanelEventId,
  eventProperties,
  segmentTrackEvent,
  segmentEventName,
  children,
  ...otherProps
}: Props) => {
  const onBtnClicked = (e) => {
    if (otherProps.onClick) {
      mixpanel.track(mixpanelEventId, eventProperties);

      if (segmentTrackEvent && segmentEventName) {
        segmentTrackEvent(segmentEventName, eventProperties);
      }

      otherProps.onClick(e);
    }
  };

  return (
    <Button {...otherProps} onClick={onBtnClicked}>
      {children}
    </Button>
  );
};

export default MixpanelButton;
