import { createContext, type ReactNode, useCallback, useContext, useMemo } from "react";

import { CustomerCreditModel } from "@doitintl/cmp-models";
import {
  getCollectionGroup,
  type ModelReference,
  type TransformMethod,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { getCachingKeys } from "../utils/cachingKeys";
import { sanitizeDate } from "../utils/common";
import { type Entity } from "./customer/EntitiesContext";
import { useCustomerContext } from "./CustomerContext";

type StartEndDate = {
  startDate: DateTime;
  endDate: DateTime;
};

export type CreditDataType = {
  data: Omit<WithFirebaseModel<CustomerCreditModel>, "startDate" | "endDate"> & StartEndDate;
  ref: ModelReference<CustomerCreditModel>;
  _filter?: string;
};

const today = DateTime.utc().startOf("day");

const extendCredit = (
  credit: Readonly<WithFirebaseModel<CustomerCreditModel>>,
  entities: Entity[] | undefined
): CreditDataType["data"] => {
  const result = {
    ...credit,
    startDate: sanitizeDate(DateTime.fromJSDate(credit.startDate.toDate())),
    endDate: sanitizeDate(DateTime.fromJSDate(credit.endDate.toDate())),
    _remaining: credit.amount,
    _status: "active",
    _entity: "N/A",
  };

  if (credit.entity?.id && entities) {
    for (const entity of entities) {
      if (entity.id === credit.entity.id) {
        result._entity = `${entity.priorityId} - ${entity.name}`;
        break;
      }
    }
  }

  for (const monthKey in credit.utilization) {
    for (const key in credit.utilization[monthKey]) {
      result._remaining -= credit.utilization[monthKey][key];
    }
  }

  if (result._remaining <= 1e-2 && credit.depletionDate) {
    // set remaining to absolute zero to avoid $-0.00 because of floating points
    result._remaining = 0;
    result._status = "depleted";
  } else if (DateTime.fromJSDate(credit.endDate.toDate()) < today) {
    result._status = "expired";
  }

  return result;
};

const creditsContext = createContext<CreditsType | null>(null);

const useCredits = (withCustomer: boolean) => {
  const { customer, entities } = useCustomerContext({ allowNull: withCustomer ? undefined : true });
  const transformCredits: TransformMethod<CustomerCreditModel, CreditDataType> = useCallback(
    (docData, docSnapshot) => {
      const data = extendCredit(docData, entities);
      return {
        data,
        ref: docSnapshot.modelRef,
      };
    },
    [entities]
  );

  const creditsQuery = useMemo(() => {
    if (withCustomer) {
      if (!customer?.ref) {
        return;
      }
      return customer.ref.collection("customerCredits");
    } else {
      return getCollectionGroup(CustomerCreditModel);
    }
  }, [withCustomer, customer?.ref]);

  const [credits, loading] = useCollectionData(creditsQuery, {
    transform: transformCredits,
    caching: true,
    cachingKeys: getCachingKeys(withCustomer ? customer!.id : undefined),
  });

  return {
    credits: credits ?? [],
    loading,
  };
};

type CreditsType = {
  credits: CreditDataType[];
  loading: boolean;
};

export const CreditsContextProvider = ({ children, withCustomer }: { children: ReactNode; withCustomer: boolean }) => {
  const { credits, loading } = useCredits(withCustomer);
  const providerValue = useMemo<CreditsType>(
    () => ({
      credits,
      loading,
    }),
    [credits, loading]
  );
  return <creditsContext.Provider value={providerValue}>{children}</creditsContext.Provider>;
};

export const useCreditsContext = () => {
  const context = useContext(creditsContext);
  if (!context) {
    throw new Error("useCreditsContext must be used within a CreditsContextProvider");
  }
  return context;
};
