import React from "react";

import { useCustomerContext } from "@client/src/Context/CustomerContext";
import { MissingGCPRecommenderPermissionsAlert } from "@client/src/Pages/Insights/MissingPermissions/MissingGCPRecommenderPermissions";

import { useFullScreen } from "../../../utils/dialog";
import { useGKEAndCloudConnect } from "../../K8s/hooks";
import { MissingCloudConnectPermissionsAlert } from "../MissingPermissions/MissingCloudConnectPermissionsAlert";
import { MissingCostOptHubPermissionsAlert } from "../MissingPermissions/MissingCostOptHubPermissionsAlert";
import { MissingTrustedAdvisorPermissionsAlert } from "../MissingPermissions/MissingTrustedAdvisorPermissionsAlert";
import { type InsightsAddPermissionsProperties } from "../types";

type Props = {
  accountsWithoutTAPermissions: string[];
  accountsWithoutCostOptHubPermissions: string[];
  missingGCPRecommenderPermissions: boolean;
  addTAPermissionClickedEvent: InsightsAddPermissionsProperties;
  addCostOptHubPermissionClickedEvent: InsightsAddPermissionsProperties;
  addGCPPermissionClickedEvent: InsightsAddPermissionsProperties;
};

export const MissingPermissionsAlerts: React.FC<Props> = ({
  accountsWithoutTAPermissions,
  accountsWithoutCostOptHubPermissions,
  missingGCPRecommenderPermissions,
  addTAPermissionClickedEvent,
  addCostOptHubPermissionClickedEvent,
  addGCPPermissionClickedEvent,
}) => {
  const { isMobile } = useFullScreen();
  const { hasGKE, hasCloudConnect, unhealthyOrganization } = useGKEAndCloudConnect();
  const { hasGCPAssets } = useCustomerContext();
  const showConnectAccountBannerTA = accountsWithoutTAPermissions.length > 0;

  const showConnectAccountBannerCostOptHub = accountsWithoutCostOptHubPermissions.length > 0;

  return (
    <>
      {!isMobile && showConnectAccountBannerTA && (
        <MissingTrustedAdvisorPermissionsAlert
          accountsMissingPermissions={accountsWithoutTAPermissions}
          insightsEvent={addTAPermissionClickedEvent}
        />
      )}
      {!isMobile && showConnectAccountBannerCostOptHub && (
        <MissingCostOptHubPermissionsAlert
          accountsMissingPermissions={accountsWithoutCostOptHubPermissions}
          insightsEvent={addCostOptHubPermissionClickedEvent}
        />
      )}
      {!isMobile && hasGCPAssets && missingGCPRecommenderPermissions && (
        <MissingGCPRecommenderPermissionsAlert insightsEvent={addGCPPermissionClickedEvent} />
      )}
      {!isMobile && hasGKE && !hasCloudConnect && (
        <MissingCloudConnectPermissionsAlert unhealthyOrganization={unhealthyOrganization} />
      )}
    </>
  );
};
