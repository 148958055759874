import { useMemo } from "react";

import { type QueryObserverResult, useQuery } from "@tanstack/react-query";

import { useApiContext } from "../../../api/context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { AzureConnectApi } from "./api";
import type { AzureSubscription } from "./types";

export function useAzureConnectApi() {
  const api = useApiContext();
  return useMemo(() => new AzureConnectApi(api), [api]);
}

export function useCustomerSubscriptions(): [
  AzureSubscription[],
  boolean,
  () => Promise<QueryObserverResult<AzureSubscription[], unknown>>,
] {
  const api = useAzureConnectApi();
  const { customer } = useCustomerContext();
  const { data, isSuccess, refetch } = useQuery<AzureSubscription[]>([customer.id], async () =>
    api.listTenantsSubscriptions(customer.id)
  );

  return [data || [], isSuccess, refetch];
}
