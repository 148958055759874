import { Box, Skeleton } from "@mui/material";

export const TableSkeleton = () => (
  <>
    <Box
      mt={2}
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Skeleton variant="rectangular" height={40} />
      </Box>
    </Box>
    <Box
      sx={{
        pt: 2,
        pb: 2,
      }}
    >
      <Skeleton variant="rectangular" width="100%" height={35} />
    </Box>
    <Skeleton variant="rectangular" width="100%" height={250} />
  </>
);
