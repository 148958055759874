import {
  APP_KEY,
  CloudFlowNodeType,
  type CustomerModel,
  NODE_STATUS,
  type NodeModel,
  type NodeTransition,
} from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  type QueryDocumentSnapshotModel,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import { type FirebaseModelReference } from "@doitintl/models-firestore/src/core";
import { type Edge, getConnectedEdges, type Node } from "@xyflow/react";

import { type CloudFlowNode, EDGE_TYPE, type NodeConfigs, type RFNode } from "../../types";
import { createEdge, createFalsePathEdge, createTruePathEdge } from "./edgeUtils";
import { getPreviousNodesPath } from "./getPreviousNodesPath";
import { isGhostNode } from "./nodeUtils";

export const transformNodeData = (
  data: WithFirebaseModel<NodeModel>,
  docSnapshot: QueryDocumentSnapshotModel<NodeModel> | DocumentSnapshotModel<NodeModel>,
  customerRef: FirebaseModelReference<CustomerModel>
) => ({
  data: { ...data, customer: customerRef },
  id: docSnapshot.id,
  ref: docSnapshot.ref,
});

export const isLeafNode = (node: CloudFlowNode): boolean => !node.transitions || node.transitions.length === 0;

export const isNodeBranching = (node: CloudFlowNode): boolean => node.transitions?.length === 2;

const createGhostNode = (id: string): CloudFlowNode =>
  ({
    type: CloudFlowNodeType.GHOST,
    id,
  }) as unknown as CloudFlowNode;

export const getGhostNodes = (node: CloudFlowNode) => {
  const ghostNodes: CloudFlowNode[] = [];
  if (node.type === CloudFlowNodeType.CONDITION) {
    ghostNodes.push(createGhostNode(`${node.id}-true-ghost`), createGhostNode(`${node.id}-false-ghost`));
  } else {
    ghostNodes.push(createGhostNode(`${node.id}-ghost`));
  }
  return ghostNodes;
};

export const getGhostTransitions = (node: CloudFlowNode) => {
  if (node.type === CloudFlowNodeType.CONDITION) {
    const trueTransition = {
      targetNodeId: `${node.id}-true-ghost`,
      label: "True",
    };
    const falseTransition = {
      targetNodeId: `${node.id}-false-ghost`,
      label: "False",
    };
    return [trueTransition, falseTransition];
  }

  return [
    {
      targetNodeId: `${node.id}-ghost`,
    },
  ];
};

export const mapLeafNodesWithGhosts = (cloudflowNodes: CloudFlowNode[] = [], firstNodeId: string): CloudFlowNode[] => {
  const filterOrphanNodes = cloudflowNodes.filter((node) => {
    const previousNodes = getPreviousNodesPath(node.id, cloudflowNodes);
    const isOrphanNode = previousNodes.length === 0 && node.id !== firstNodeId;
    return !isOrphanNode;
  });

  const nodesWithGhosts: CloudFlowNode[] = filterOrphanNodes.map((node) => ({
    ...node,
    transitions: node.transitions ? [...node.transitions] : [],
  }));

  nodesWithGhosts.forEach((node: CloudFlowNode) => {
    if (isLeafNode(node) && !isGhostNode(node)) {
      nodesWithGhosts.push(...getGhostNodes(node));
      node.transitions = [...(node.transitions || []), ...getGhostTransitions(node)];
    } else if (node.type === CloudFlowNodeType.CONDITION && !isNodeBranching(node)) {
      const exitingTransitionLabel = node.transitions?.[0].label;
      const ghostNodeId = exitingTransitionLabel === "True" ? `${node.id}-false-ghost` : `${node.id}-true-ghost`;
      const ghostLabel = exitingTransitionLabel === "True" ? "False" : "True";

      nodesWithGhosts.push(createGhostNode(ghostNodeId));
      node.transitions = [
        ...(node.transitions || []),
        {
          targetNodeId: ghostNodeId,
          label: ghostLabel,
        },
      ];
    }
  });

  return nodesWithGhosts;
};

export const toReactFlowNode = (node: CloudFlowNode) => {
  const { id, ...nodeData } = node;
  return {
    id,
    type: node.type || CloudFlowNodeType.ACTION,
    position: node.display?.position || { x: 0, y: 0 },
    data: {
      touched: false,
      nodeData,
    },
  };
};

export const mapCloudFlowNodes = (cloudflowNodes?: CloudFlowNode[]): Node<RFNode>[] =>
  cloudflowNodes?.map(toReactFlowNode) || [];

// Maps Cloudflow transitions to edges
export const mapTransitionsToEdges = (cloudflowNodes?: CloudFlowNode[]) =>
  cloudflowNodes?.reduce((edges: Edge[], node: CloudFlowNode) => {
    if (node.transitions) {
      const nodeEdges = node.transitions.map((transition: NodeTransition) => {
        if (node.type === CloudFlowNodeType.CONDITION) {
          return transition.label === "True"
            ? createTruePathEdge(node.id, transition.targetNodeId)
            : createFalsePathEdge(node.id, transition.targetNodeId);
        }
        const childNode = cloudflowNodes.find((n) => n.id === transition.targetNodeId);

        const edgeType = childNode && isGhostNode(childNode) ? EDGE_TYPE.GHOST : EDGE_TYPE.CUSTOM;
        return createEdge(node.id, transition.targetNodeId, edgeType);
      });
      return edges.concat(nodeEdges);
    }
    return edges;
  }, []);

export const sortEdgesByHandle = (edges: Edge[]): Edge[] => {
  const getHandleSuffix = (handle?: string | null) => {
    if (!handle) return "";
    if (handle.endsWith("-true")) return "true";
    if (handle.endsWith("-false")) return "false";
    return "none";
  };

  const order = { true: 1, false: 2, none: 3 };

  return edges.sort((a, b) => {
    const suffixA = getHandleSuffix(a.sourceHandle);
    const suffixB = getHandleSuffix(b.sourceHandle);

    return (order[suffixA] || 0) - (order[suffixB] || 0);
  });
};

export const mapEdgesToTransitions = (node: NodeConfigs, edges: Edge[]) => {
  const transitions = node.transitions || [];
  const connectedEdges = getConnectedEdges([node as unknown as Node<RFNode>], edges);
  const edgeTransitions = connectedEdges
    .filter((edge) => edge.target !== node.id)
    .map((edge) => ({
      targetNodeId: edge.target,
      label: edge.data?.label || null,
    }));
  return [...transitions, ...edgeTransitions];
};

export const mapToCreateNodePayload = <TNodeType extends CloudFlowNodeType>(
  node: Node<RFNode<CloudFlowNodeType>>,
  targetNode?: Node<RFNode>
) => ({
  id: node.id,
  type: node.type as TNodeType,
  name: node.data.nodeData.name,
  appKey: node.data.nodeData.appKey || APP_KEY.INTERNAL,
  status: node.data.nodeData.status || NODE_STATUS.PENDING,
  errorMessages: node.data.nodeData.errorMessages,
  display: node.data.nodeData.display,
  parameters: node.data.nodeData.parameters,
  transitions:
    targetNode && isGhostNode(targetNode)
      ? null
      : targetNode
        ? [
            {
              targetNodeId: targetNode.id,
            },
          ]
        : null,
});

export const mapToUpdateNodePayload = (node: Node<RFNode<CloudFlowNodeType>>) => {
  // Remove references 'createdBy' and 'action' from update payload
  const { createdBy, ...rest } = node.data.nodeData;
  const transitions = rest.transitions || [];

  // Filter out transitions pointing to ghost nodes
  const validTransitions = transitions.filter((t) => !t.targetNodeId.includes("-ghost"));

  return {
    id: node.id,
    ...rest,
    transitions: validTransitions.length ? validTransitions : null,
  };
};

export const mergeCloudflowNodes = (
  cloudflowNodes: Node<RFNode<CloudFlowNodeType>>[],
  localStateNodes: Node<RFNode<CloudFlowNodeType>>[],
  handleAddNode: (nodeType: CloudFlowNodeType, nodeId: string) => void,
  handleEditNode: (node: Node<RFNode<CloudFlowNodeType>>) => void,
  handleDeleteNode: (nodeId: string) => void
) => {
  const isInitialLoading = localStateNodes.length === 0;
  const isMoreThanOneNodeAdded = cloudflowNodes.length - localStateNodes.length > 1;

  return cloudflowNodes.map((node) => {
    const localNode = localStateNodes.find((n) => n.id === node.id);

    const selected = localNode
      ? localNode.selected
      : node.type !== CloudFlowNodeType.START_STEP &&
        node.type !== CloudFlowNodeType.GHOST &&
        !isInitialLoading &&
        !isMoreThanOneNodeAdded;

    const selectable = node.type !== CloudFlowNodeType.GHOST;
    const focusable = node.type !== CloudFlowNodeType.START_STEP;

    return {
      ...node,
      selected,
      selectable,
      focusable,
      data: {
        ...node.data,
        touched: localNode ? localNode.data.touched : node.data.touched,
        onAddNode: handleAddNode,
        onDeleteNode: () => {
          handleDeleteNode(node.id);
        },
        onEditNode: () => {
          handleEditNode(node);
        },
      },
    };
  });
};
