import { type Dispatch, type SetStateAction, useCallback, useMemo } from "react";

import { AnalyticsResourceType, type Collaborators, type PublicAccess } from "@doitintl/cmp-models";
import { type Method } from "axios";

import { useApiContext } from "../../../../api/context";
import { httpMethods, reportText } from "../../../../assets/texts";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useEntitiesContext } from "../../../../Context/customer/EntitiesContext";
// Contexts
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useUserContext } from "../../../../Context/UserContext";
import { useCloudAnalyticsContext } from "../../../../Pages/CloudAnalytics/CloudAnalyticsContext";
import { getNewUsers } from "../../../../Pages/CloudAnalytics/utilities";
// Functions
import Invites from "../../../../Pages/IAM/InviteUserDialog/handleInvite";
// Types and texts
import { type ReportWSnap } from "../../../../types";
import { useSnackbar } from "../../../SharedSnackbar/SharedSnackbar.context";
import useAnalyticsUsers from "../useAnalyticsUsers";

export const useShareReport = (
  report: ReportWSnap | null,
  setShareDialogLoading: Dispatch<SetStateAction<boolean>>,
  handleCloseShareDialog: () => void
): ((collaborators: Collaborators, publicAccess: PublicAccess | boolean) => Promise<void>) => {
  const api = useApiContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { entities } = useEntitiesContext();
  const { onOpen: showSharedSnackbar } = useSnackbar();
  const { handleMissingPermission } = useCloudAnalyticsContext();
  const { invites, userEmails, allUsersAndInvites } = useAnalyticsUsers();
  const { currentUser } = useAuthContext();
  const { customer } = useCustomerContext();

  const isCurrentUserUserManager = useMemo(() => userRoles.usersManager || userRoles.doitEmployee, [userRoles]);

  const addNewUsers = useCallback(
    async (collaborators) => {
      if (currentUser) {
        const emails = await getNewUsers({
          collaborators,
          users: userEmails,
          invites,
          baseEntity: report,
          allUsersAndInvites,
        });
        if (emails.length) {
          await Invites.handleInvite({
            newEmails: emails,
            customer,
            currentUser,
            api,
            entities,
          });
        }
      }
    },
    [api, report, currentUser, customer, invites, userEmails, allUsersAndInvites, entities]
  );

  return useCallback(
    async (collaborators: Collaborators, publicAccess: PublicAccess | boolean) => {
      if (report?.data?.type === AnalyticsResourceType.CUSTOM) {
        try {
          setShareDialogLoading(true);
          if (isCurrentUserUserManager) {
            try {
              await addNewUsers(collaborators);
            } catch (e) {
              handleMissingPermission(e as string);
              setShareDialogLoading(false);
              return;
            }
          }
          await api.request({
            method: httpMethods.PATCH as Method,
            url: `/v1/customers/${customer.id}/analytics/reports/${report.ref.id}/share`,
            data: {
              public: publicAccess ?? undefined,
              collaborators,
            },
          });
          showSharedSnackbar({ message: reportText.REPORT_SHARE_SUCCESS });
          handleCloseShareDialog();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }

        setShareDialogLoading(false);
      }
    },
    [
      report?.data?.type,
      report?.ref?.id,
      customer.id,
      setShareDialogLoading,
      isCurrentUserUserManager,
      api,
      showSharedSnackbar,
      handleCloseShareDialog,
      addNewUsers,
      handleMissingPermission,
    ]
  );
};
