import { AppModel, type AzureFeaturePermission } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

export const getAzureFeatures = async (): Promise<AzureFeaturePermission[]> => {
  const cloudConnect = await getCollection(AppModel).doc("cloud-connect").get();
  const data = cloudConnect.asModelData();
  if (!data) {
    return Promise.reject();
  }

  const featurePermissions = data.azureFeaturePermissions;
  if (!featurePermissions.length) {
    return Promise.reject();
  }

  return data.azureFeaturePermissions as AzureFeaturePermission[];
};
