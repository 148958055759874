import { type FlexsaveStandaloneOnboardingGCPModel, FlexsaveTypes, IntegrationModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { getFlexsaveTypeFullName } from "../../utils/typeUtils";

export function saveSelectedBillingProfile(customerId: string, accountId: string, selectedPriorityId: string) {
  const docId = `${getFlexsaveTypeFullName(FlexsaveTypes.AWS)}-${customerId}`;

  return getCollection(IntegrationModel)
    .doc("flexsave-standalone")
    .collection("fs-onboarding")
    .doc(docId)
    .narrow<FlexsaveStandaloneOnboardingGCPModel>()
    .update({
      [`accounts.${accountId}.selectedPriorityId`]: selectedPriorityId.toString(),
    });
}
