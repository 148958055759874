import { useCallback, useMemo } from "react";

import { type HandshakeModel, IntegrationModel } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type QueryDocumentSnapshotModel,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Handshake } from "../../../types/Handshake";
import { getCachingKeys } from "../../../utils/cachingKeys";

export const useHandshakes = () => {
  const { customer } = useCustomerContext();
  const query = useMemo(() => {
    const dateFilter = DateTime.utc().startOf("day").minus({ months: 1 }).toJSDate();

    return getCollection(IntegrationModel)
      .doc("amazon-web-services")
      .collection("handshakes")
      .where("customer", "==", customer.ref)
      .where("visible", "==", true)
      .where("requestedTimestamp", ">=", dateFilter);
  }, [customer.ref]);

  const transform = useCallback(
    (
      handshake: WithFirebaseModel<HandshakeModel>,
      docSnapshot: QueryDocumentSnapshotModel<HandshakeModel> | DocumentSnapshotModel<HandshakeModel>
    ): Handshake => ({
      data: handshake,
      ref: docSnapshot.modelRef,
    }),
    []
  );

  return useCollectionData(query, { transform, caching: true, cachingKeys: getCachingKeys(customer.id) });
};
