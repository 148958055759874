import { type AxiosInstance, type Method } from "axios";

import { consoleErrorWithSentry } from "../../../utils";

async function doWidgetRequest(
  api: AxiosInstance,
  method: Method,
  customerId: string,
  reportId: string,
  params?: Record<string, any>,
  notifyUser?: boolean
): Promise<any> {
  try {
    if (!customerId || !reportId) {
      return;
    }

    return await api.request({
      method,
      url: `/v1/customers/${customerId}/analytics/reports/${reportId}/widget`,
      params,
      data: {
        customerId,
        reportId,
        notifyUser,
      },
    });
  } catch (error) {
    consoleErrorWithSentry(error);
    return error;
  }
}

// Refreshes the widget report data
export async function refreshCachedReport(
  api: AxiosInstance,
  customerId: string,
  reportId: string,
  notifyUser = false
) {
  return doWidgetRequest(api, "POST", customerId, reportId, { cachedReport: true }, notifyUser);
}

// Refreshes the widget report data
export async function refreshWidget(api: AxiosInstance, customerId: string, reportId: string) {
  return doWidgetRequest(api, "POST", customerId, reportId);
}

// Updates the widget report name and description
export async function updateWidget(api: AxiosInstance, customerId: string, reportId: string) {
  return doWidgetRequest(api, "PATCH", customerId, reportId);
}

// Deletes the report widget data
export async function deleteWidget(api: AxiosInstance, customerId: string, reportId: string) {
  return doWidgetRequest(api, "DELETE", customerId, reportId);
}
