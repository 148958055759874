import { useMemo, useRef, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";
import find from "lodash/find";
import sortBy from "lodash/sortBy";

import { useApiContext } from "../../../../api/context";
import { dashboardsText, globalText, widgetsText } from "../../../../assets/texts";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { reachedMaxWidgetsMessage, useDashboardsContext } from "../../../../Context/useDashboardsContext";
import { consoleErrorWithSentry } from "../../../../utils";
import mixpanel from "../../../../utils/mixpanel";
import { reactRouterAllowedURLChars } from "../../../../utils/string";
import { refreshWidget } from "../../handlers/updateWidgets";
import { type DashboardInfo } from "./types";
import type { ReportWSnap } from "../../../../types";

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: theme.spacing(0, 2),
  },
  formRow: {
    marginLeft: -theme.spacing(0.5),
    marginTop: theme.spacing(1.5),
  },
  newDashboard: {
    marginTop: theme.spacing(2),
    minWidth: 280,
    maxHeight: 34,
  },
  adornedEnd: {
    paddingRight: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(2),
  },
}));

const popoverStyle = {
  top: 48,
  left: -30,
};

type ErrorTypes = "EMPTY" | "TAKEN" | "CHARS" | null;

type Props = {
  anchorEl: any;
  onClose: () => void;
  report: ReportWSnap;
  isTable: boolean;
};

export default function WidgetPopover({ anchorEl, onClose, report, isTable }: Props) {
  const classes = useStyles();
  const api = useApiContext();
  const { dashboards, addWidgetToDashboard, removeWidgetFromDashboard, createDashboard } = useDashboardsContext();
  const { customer } = useCustomerContext();
  const { onOpen: showSharedSnackbar } = useSnackbar();
  const [showNewDashboardInput, setShowNewDashboardInput] = useState<boolean>(false);
  const [inputVal, setInputVal] = useState<string>("");
  const [hasError, setHasError] = useState<ErrorTypes>(null);
  const addingWidgetToDashboard = useRef(false);
  const open = Boolean(anchorEl);
  const id = open ? "widget-popover" : undefined;

  const reportWidget = {
    name: `cloudReports::${customer.id}_${report.ref.id}`,
    cardWidth: isTable ? 12 : 6, // default report widgets, make widget take half the screen
  };

  // Clear new dashboard state when popover closed
  const closePopover = () => {
    setInputVal("");
    setHasError(null);
    setShowNewDashboardInput(false);
    onClose();
  };

  // Filter only the relevant dashboards
  const dashboardsList = useMemo(
    (): DashboardInfo[] =>
      sortBy(
        dashboards.flatMap((dashboard) => {
          // we don't allow to add to global dashboards
          if (dashboard.dashboardType) {
            return [];
          }

          return [
            {
              name: dashboard.name,
              id: dashboard.id,
              sortNumber: dashboard.sortNumber,
              checked: !!find(dashboard.widgets, { name: reportWidget.name }),
              reachedMaxWidgets: Boolean(dashboard.reachedMaxWidgets),
            },
          ];
        }),
        "sortNumber"
      ),
    [dashboards, reportWidget.name]
  );

  const showAddSnackbar = (dashboard) => {
    const dashName = dashboard.name === globalText.ACCOUNT ? globalText.DEFAULT : dashboard.name;
    const route = `/customers/${customer.id}/dashboards/${dashName}`;
    showSharedSnackbar({
      message: `Adding widget to "${dashboard.name}" dashboard`,
      action: (
        <Button key="dashboard-open" component={RouterLink} to={route} variant="outlined" color="inherit">
          {globalText.OPEN}
        </Button>
      ),
    });
  };

  const handleSelectDashboard = async (dashboard: DashboardInfo) => {
    if (addingWidgetToDashboard.current) {
      return;
    }
    try {
      addingWidgetToDashboard.current = true;
      await addWidgetToDashboard(dashboard.id, reportWidget.name, { width: reportWidget.cardWidth });
      showAddSnackbar(dashboard);
      // refresh widget data once added to dashboard
      const res = await refreshWidget(api, customer.id, report.ref.id);
      // remove enabledWidget from report, since the data has not been saved
      if (res?.status !== 200) {
        await removeWidgetFromDashboard(dashboard.id, reportWidget.name);
        showSharedSnackbar({
          message: globalText.CONNECTIVITY_ERROR,
          variant: "error",
        });
        return;
      }
      mixpanel.track("analytics.report.add-to-dashboard", {
        reportId: report.ref.id,
        reportName: report.data.name,
        view: report.data.config?.renderer,
      });
    } catch (error) {
      consoleErrorWithSentry(error);
    } finally {
      addingWidgetToDashboard.current = false;
    }
  };

  const handleClickDashboard = async (dashboard: DashboardInfo) => {
    if (dashboard.checked) {
      try {
        await removeWidgetFromDashboard(dashboard.id, reportWidget.name);

        mixpanel.track("analytics.report.remove-from-dashboard", {
          reportId: report.ref.id,
          reportName: report.data.name,
        });
        showSharedSnackbar({ message: `Removing widget from "${dashboard.name}"` });
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    } else {
      handleSelectDashboard(dashboard).catch(consoleErrorWithSentry);
    }
  };

  const handleSaveNewDashboard = async () => {
    const dashboardId = await createDashboard({
      name: inputVal,
      isPublic: false,
      widgets: [{ name: reportWidget.name, cardWidth: reportWidget.cardWidth }],
    });
    if (!dashboardId) {
      return;
    }

    mixpanel.track("dashboard.create", {
      "dashboard.name": inputVal,
    });
    setShowNewDashboardInput(false);
    setInputVal("");
    setHasError(null);
  };

  const isNameValid = (name: string) => {
    const isURLValid = reactRouterAllowedURLChars(name);

    const canonicalName = name.trim().toLowerCase();
    const isAvailable =
      canonicalName !== globalText.DEFAULT.trim().toLowerCase() &&
      dashboards.every((dashboard) => dashboard.name.toLowerCase() !== canonicalName);

    const emptyOrNull = name.trim().length ? null : "EMPTY";
    const charsOrNull = !isURLValid ? "CHARS" : emptyOrNull;
    const errorType = !isAvailable ? "TAKEN" : charsOrNull;
    setHasError(errorType);
  };

  const handleInput = (event) => {
    setInputVal(event.target.value.toString().slice(0, 30));
    isNameValid(event.target.value);
  };

  const renderNewDashboardAction = () => {
    if (!showNewDashboardInput) {
      return (
        <Button
          classes={{ root: classes.newDashboard }}
          variant="outlined"
          fullWidth
          onClick={() => {
            setShowNewDashboardInput(true);
          }}
        >
          {widgetsText.NEW_DASHBOARD}
        </Button>
      );
    }
    return (
      <TextField
        classes={{ root: classes.newDashboard }}
        variant="outlined"
        margin="dense"
        value={inputVal ?? ""}
        onChange={handleInput}
        fullWidth
        placeholder={widgetsText.DASHBOARD_INPUT}
        error={hasError !== null}
        className={hasError !== null ? classes.error : ""}
        helperText={dashboardsText[hasError]}
        slotProps={{
          input: {
            classes: { adornedEnd: classes.adornedEnd },
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={handleSaveNewDashboard}
                  color="primary"
                  size="small"
                  variant="contained"
                  disabled={inputVal.length < 1 || hasError !== null}
                >
                  {globalText.SAVE}
                </Button>
              </InputAdornment>
            ),
          },
        }}
      />
    );
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={closePopover}
      style={popoverStyle}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Card>
        <CardContent>
          <Grid container direction="column">
            <Grid>
              <Typography noWrap variant="subtitle1" color="textSecondary">
                {widgetsText.YOUR_DASHBOARDS}
              </Typography>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {dashboardsList.map((dashboard) => (
                    <Tooltip
                      key={dashboard.id}
                      title={dashboard.reachedMaxWidgets ? reachedMaxWidgetsMessage : ""}
                      placement="left"
                    >
                      <span>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={dashboard.checked}
                              onChange={() => handleClickDashboard(dashboard)}
                              disabled={dashboard.reachedMaxWidgets && !dashboard.checked}
                            />
                          }
                          label={
                            <Typography noWrap color="textSecondary">
                              {dashboard.name}
                            </Typography>
                          }
                          disabled={dashboard.reachedMaxWidgets && !dashboard.checked}
                        />
                      </span>
                    </Tooltip>
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid>{renderNewDashboardAction()}</Grid>
          </Grid>
        </CardContent>
      </Card>
    </Popover>
  );
}
