import { useMemo } from "react";

import { Redirect, useParams } from "react-router-dom";

import { useBudgets } from "../../../Components/hooks/cloudAnalytics/budgets/useBudgets";
import { useHasCloudAnalyticsMetadata } from "../../../Components/hooks/cloudAnalytics/useHasCloudAnalyticsMetadata";
import CreateBudgetStepper from "./CreateBudgetSteper/CreateBudgetStepper";

export const BudgetWrapper = () => {
  const { budgetId } = useParams<{ budgetId: string }>();

  const hasMetadata = useHasCloudAnalyticsMetadata();
  const [_allBudgets, budgets, budgetsLoading] = useBudgets();
  return useMemo(() => {
    if (hasMetadata === undefined || budgetsLoading) {
      return null;
    }

    const budget = budgets.find((b) => b.ref.id === budgetId);

    if (!budget || !hasMetadata) {
      return <Redirect to="." />;
    }

    return <CreateBudgetStepper budget={budget} isNewBudget={false} />;
  }, [hasMetadata, budgetsLoading, budgets, budgetId]);
};
