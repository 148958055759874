import { type CloudAnalyticsModelWidgetModel, type Col, type Key } from "@doitintl/cmp-models";
import type { WithFirebaseModel } from "@doitintl/models-firestore";

import { getMetricOptions } from "../../../Pages/CloudAnalytics/utilities";
import { CSPCustomerID } from "../../../utils/common";
import { type WidgetWithExtraData, type WidgetWithExtraDataWrapper } from "./hooks";

type NameSumMap = {
  [key: string]: number;
};

const cleanName = (name: string) => name.replace("Flexsave Commitment v1: ", "").replace(" for 1 Year", "");

export const getVals = (
  reportConfig: Pick<WidgetWithExtraData["config"], "cols" | "metric" | "rows" | "count">,
  customerId: string
) => {
  const { cols, metric, rows, count } = reportConfig ?? {};

  const isCSP = customerId === CSPCustomerID;
  const metricOffset = (rows?.length || 0) + (cols?.length || 0);
  return getMetricOptions(metricOffset, metric, isCSP, !!count);
};

export const getFiltersByType = (report: WidgetWithExtraData, cloudAnalyticsKey: Key, customerId: string) => {
  const { config, rawRows } = report;
  const filterIndex = config.rows.findIndex((row) => row.key === cloudAnalyticsKey);

  const vals = getVals(report.config, customerId);

  if (!vals) {
    return [];
  }

  const mappedNames = rawRows.map((row) => row[filterIndex]);
  const uniqueNames = [...new Set(mappedNames)];

  const nameSumMap: NameSumMap = uniqueNames.reduce((acc: NameSumMap, name: string) => {
    acc[name] = rawRows.reduce((sum: number, row) => {
      if (row[filterIndex] === name) {
        sum += vals.reduce((valSum: number, val: number) => valSum + (row[val] as number), 0);
      }
      return sum;
    }, 0);
    return acc;
  }, {});

  const topTenNames = Object.entries(nameSumMap)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 10)
    .map(([name]) => name);

  const cuttedNameS = topTenNames.map((name) => cleanName(name));

  return ["All", ...cuttedNameS];
};

export const filterDataByType = (report: WidgetWithExtraData, cloudAnalyticsKey: Key, selectedFilter: string) => {
  const { config, rawRows } = report;
  const filterIndex = config.rows.findIndex((row) => row.key === cloudAnalyticsKey);

  const removedFilterConfigRows = config.rows.filter((row) => row.key !== cloudAnalyticsKey);

  const mappedNames = rawRows.filter((row) => cleanName(row[filterIndex]) === selectedFilter);
  const rows = selectedFilter === "All" ? rawRows : mappedNames;

  const removedFilterRawRows = rows.map((row) => row.filter((_, index) => index !== filterIndex));
  return [removedFilterRawRows, removedFilterConfigRows];
};

export const getLabelOfKey = (rows: Col[], key: Key) => {
  const row = rows.find((row) => row.key === key);
  return row?.label ?? "";
};

export function createWidgetWithExtraData(
  reportWithData: WithFirebaseModel<CloudAnalyticsModelWidgetModel>
): WidgetWithExtraDataWrapper {
  const temp: WidgetWithExtraData = {
    ...reportWithData,
    reportId: reportWithData.report ?? "",
    description: reportWithData.description ? reportWithData.description : "cloud cost and usage analytics",
    rawRows: reportWithData.data.rows ? Object.values(reportWithData.data.rows) : [],
    rawForecastRows: reportWithData.data.forecastRows ? Object.values(reportWithData.data.forecastRows) : [], // firestore does not support nested arrays, this restores the data to be like BQ rows
  };
  return {
    data: {
      title: reportWithData.name,
      description: reportWithData.description,
      rawReport: temp,
      updateTime: new Date(),
    },
    loading: false,
  };
}

export const cleanDashboardLensWidgetName = (name: string) => name.replace(/\[(datadog|snowflake) lens\]/gi, "").trim();
