import { useCallback, useEffect } from "react";

import {
  type GcpMarketplaceEntitlementModel,
  type GcpMarketplaceEntitlementState,
  MarketplaceModel,
} from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type ModelReference,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";

import { globalText } from "../../../../assets/texts";
import { formatFirestoreDate } from "../../../CloudAnalytics/utilities";
import { MarketplaceProduct } from "../../Messages/type";
import { getBillingAccountType } from "../Accounts/utils";
import { type AccountConfig, type AccountsNameMap } from "./useMarketplaceAccounts";
import { type CustomersIdsMap, type CustomerWithRef } from "./useMarketplaceCustomers";

const transformEntitlementState = (state: GcpMarketplaceEntitlementState) => {
  switch (state) {
    case "ENTITLEMENT_STATE_UNSPECIFIED":
      return "State unspecified";
    case "ENTITLEMENT_ACTIVATION_REQUESTED":
      return "Activation requested";
    case "ENTITLEMENT_ACTIVE":
      return "Active";
    case "ENTITLEMENT_PENDING_CANCELLATION":
      return "Pending cancellation";
    case "ENTITLEMENT_CANCELLED":
      return "Cancelled";
    case "ENTITLEMENT_PENDING_PLAN_CHANGE":
      return "Pending plan change";
    case "ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL":
      return "Pending plan change approval";
    case "ENTITLEMENT_SUSPENDED":
      return "Suspended";
    case "ENTITLEMENT_DELETED":
      return "Deleted";
    default:
      return state;
  }
};

export type EntitlementConfig = {
  data: WithFirebaseModel<GcpMarketplaceEntitlementModel>;
  ref: ModelReference<GcpMarketplaceEntitlementModel>;
  transform: {
    procurementAccountId: string;
    createTime: string;
    updateTime: string;
    product: string;
    state: string;
  };
  customer: CustomerWithRef | null;
  billingAccountType: string;
};

export const useMarketplaceEntitlements = (
  customersIdsMap: CustomersIdsMap,
  accountsNameMap: AccountsNameMap,
  accounts: AccountConfig[] | undefined
): [EntitlementConfig[] | undefined, boolean] => {
  const dataQuery = getCollection(MarketplaceModel).doc("gcp-marketplace").collection("gcpMarketplaceEntitlements");

  const transformDoc = (
    data: WithFirebaseModel<GcpMarketplaceEntitlementModel>,
    docSnapshot: DocumentSnapshotModel<GcpMarketplaceEntitlementModel>
  ): EntitlementConfig => {
    const { account, createTime, updateTime, productExternalName, state } = data.procurementEntitlement;
    return {
      data,
      ref: docSnapshot.ref,
      transform: {
        procurementAccountId: account.substring(account.lastIndexOf("/") + 1),
        createTime: formatFirestoreDate(createTime),
        updateTime: formatFirestoreDate(updateTime),
        product: productExternalName.split(".")[0],
        state: transformEntitlementState(state),
      },
      customer: null,
      billingAccountType: globalText.NA,
    };
  };

  const [entitlements, entitlementsLoading] = useCollectionData(dataQuery, {
    transform: transformDoc,
  });

  const setCostAnomalyEnabled = useCallback(
    (entitlement, customerWithRef) => {
      const product = entitlement.data.procurementEntitlement.product.split(".")[0];
      if (product === MarketplaceProduct.COST_ANOMALY_DETECTION) {
        if (entitlement.data.procurementEntitlement.state === "ENTITLEMENT_ACTIVE") {
          customerWithRef.costAnomalyEnabled = true;
          customerWithRef.activeProcurementAccounts = customerWithRef.activeProcurementAccounts || [];
          const account = accounts?.find(
            (account) => account.data.procurementAccount?.name === entitlement.data.procurementEntitlement.account
          );
          if (account) {
            account.costAnomalyEnabled = true;
          }
        }
      }
    },
    [accounts]
  );

  useEffect(() => {
    if (!entitlements || entitlements.length === 0 || !customersIdsMap || !accountsNameMap) {
      return;
    }

    entitlements.forEach((entitlement) => {
      const account = accountsNameMap.get(entitlement.data.procurementEntitlement.account);

      if (account?.customer) {
        entitlement.billingAccountType = getBillingAccountType(account?.billingAccountType);
        const customerWithRef = customersIdsMap.get(account.customer.id);
        if (customerWithRef) {
          setCostAnomalyEnabled(entitlement, customerWithRef);
          entitlement.customer = customerWithRef;
        }
      }
    });
  }, [accountsNameMap, entitlements, customersIdsMap, setCostAnomalyEnabled]);

  return [entitlements, entitlementsLoading];
};
