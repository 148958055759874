import React from "react";

import { useHistory } from "react-router";
import { EarlyAccessFeature } from "@services/cmp/models";

import { useCustomerContext } from "../../../Context/CustomerContext";
import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import { type InsightsAddPermissionsProperties } from "../types";
import { PermissionsAlert } from "./PermissionsAlert";

export const MissingGCPRecommenderPermissionsAlert: ({
  insightsEvent,
}: {
  insightsEvent: InsightsAddPermissionsProperties;
}) => React.JSX.Element | null = ({ insightsEvent }): React.JSX.Element | null => {
  const history = useHistory();
  const { trackEvent } = useSegmentTrackEvent();
  const { customer } = useCustomerContext();

  const hasGCPRecommenderBigQueryExportInsightsAccess =
    customer?.earlyAccessFeatures?.includes(EarlyAccessFeature.GCP_RECOMMENDER_BIGQUERY_INSIGHTS) ?? false;

  if (!hasGCPRecommenderBigQueryExportInsightsAccess) {
    return null;
  }

  const handleClick = () => {
    trackEvent("Insights Add GCP Recommender Permissions Clicked", insightsEvent);
    history.push(`/customers/${customer.id}/settings/gcp/connect/dataset`);
  };

  return (
    <PermissionsAlert onClick={handleClick}>
      Set-up and connect your Google Cloud Recommender BigQuery export to get these recommendations here.
    </PermissionsAlert>
  );
};
