import {
  type AccountManagerModel,
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  CloudAnalyticsModel,
  type CloudAnalyticsModelGkeCostAllocationModel,
  type CloudAnalyticsModelWidgetModel,
  ContractModel,
  type CurrencyCode,
  CurrencyCodes,
  CustomerModel,
  type CustomerModelAccountAssumption,
  type CustomerModelCloudConnectModel,
  type FlexsaveConfigurationModel,
  type FlexsaveType,
  FlexsaveTypes,
  IntegrationModel,
  type IntegrationModelGKETablesModel,
  type OutputModel,
  RampModel,
  SuperQueryModel,
} from "@doitintl/cmp-models";
import {
  getCollection,
  type ModelId,
  type ModelReference,
  type QueryModel,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import chunk from "lodash/chunk";
import sum from "lodash/sum";
import { DateTime } from "luxon";

import { asyncConvertCurrencyTo } from "../../Context/AsyncCurrencyConverterContext";
import { type CustomerRef } from "../../types";
import { CSPCustomerID, partnerOrgsMap, sortAlphabetically } from "../../utils/common";
import { FirestoreMaxInOperator, loadDocumentsByQueriesIfPossible } from "../../utils/firebase";
import { getWidgetDocId } from "../../utils/widgets";
import { getTotalActuals, sumPlannedUpToLastMonth } from "../RampPlans/RampList/itemDetails";
import {
  type AccountManagers,
  type CloudConnectStatus,
  type FlexsaveSavings,
  FlexsaveStatus,
  type GkeCostAllocationStatus,
  type RampPlanAttainment,
  Status,
} from "./types";

const cloudAnalytics = getCollection(CloudAnalyticsModel).doc("widgets").collection("cloudAnalyticsWidgets");

const thisMonthVsLast = "Anl2FHDAgyxR4GFellrA";

export const getCustomerCurrentMonthSpend = (customerId: string, partnerCompany?: string) => {
  const docId = getWidgetDocId(customerId, thisMonthVsLast, partnerCompany ? partnerOrgsMap[partnerCompany] : null);
  return cloudAnalytics.doc(docId);
};

function getCustomerFlexsaveDoc(customerId: string): ModelReference<FlexsaveConfigurationModel> {
  const flexsavePath = getCollection(IntegrationModel).doc("flexsave").collection("configuration");
  return flexsavePath.doc(customerId);
}

function getCustomerCloudConnect(customerId: string): QueryModel<CustomerModelCloudConnectModel> {
  return getCollection(CustomerModel)
    .doc(customerId)
    .collection("cloudConnect")
    .where("cloudPlatform", "==", AssetTypeGoogleCloud)
    .limit(1);
}

function getCustomerBigqueryLens(customerId: string): ModelReference<OutputModel> {
  return getCollection(SuperQueryModel).doc("simulation-optimisation").collection("output").doc(customerId);
}

export function getCustomerGKEMetering(customerId: string): ModelReference<IntegrationModelGKETablesModel> {
  return getCollection(IntegrationModel).doc("google-cloud").collection("googleKubernetesEngineTables").doc(customerId);
}

export function getCustomerGKECostAllocation(
  customerId: string
): ModelReference<CloudAnalyticsModelGkeCostAllocationModel> {
  return getCollection(CloudAnalyticsModel)
    .doc("gke-cost-allocations")
    .collection("cloudAnalyticsGkeCostAllocations")
    .doc(customerId);
}

export function getCustomerAccountAssumptionSettings(
  customerId: string
): ModelReference<CustomerModelAccountAssumption> {
  return getCollection(CustomerModel).doc(customerId).collection("accountAssumption").doc("accountAssumptionSettings");
}
const getCustomerDoc = (customerId: string): CustomerRef => getCollection(CustomerModel).doc(customerId);

const getAccountMangersForCustomer = (
  customer: ModelId<CustomerModel> | undefined
): ModelReference<AccountManagerModel>[] =>
  customer?.accountTeam ? customer.accountTeam.filter((am) => am.company === "doit").map((am) => am.ref) : [];

/**
 * Get customers account managers
 * returns all assigned account managers for each given customer
 * struct:
 * {
 *  customerId: string;
 *  accountManagers: string[];  //  Account Manager name
 * }
 */
export async function getAccountManagers(
  customerIds: string[],
  _partnerCompany: string | undefined,
  isDoer: boolean
): Promise<AccountManagers[]> {
  const customerRefs = customerIds.map((c) => getCustomerDoc(c));
  const customersData = await loadDocumentsByQueriesIfPossible(customerRefs, {
    useQueryToRetrieveDocs: isDoer,
    idField: "id",
  });
  const customerAccountManagersRefs = customersData.map((customerData) => getAccountMangersForCustomer(customerData));

  const allCustomerAccountManagers = customerAccountManagersRefs
    .map((accountManagersRefs) => accountManagersRefs.map((am) => am))
    .flat();

  const customerAccountManagersRefsSet = new Set(allCustomerAccountManagers);

  const allCustomerAccountManagersDocs = await Promise.all(
    Array.from(customerAccountManagersRefsSet).map((am) => am.get())
  );

  const allCustomerAccountManagersMapping = Object.fromEntries(
    allCustomerAccountManagersDocs.map((doc) => [doc.id, doc.asModelData()])
  );

  return customerIds.map((customerId, index) => {
    const accountManagers = customerAccountManagersRefs[index].flatMap((amRef) => {
      const am = allCustomerAccountManagersMapping[amRef.id];
      const name = am?.name;

      if (name) {
        return [name];
      }

      return [];
    });

    accountManagers.sort(sortAlphabetically);
    return {
      accountManagers,
    };
  });
}

function getInActiveStatus(doc: FlexsaveConfigurationModel | undefined, platformType: FlexsaveType) {
  if (typeof doc?.[platformType]?.enabled === "undefined") {
    return Status.Na;
  }
  const estimation = `Estimated $${Math.round(doc?.[platformType]?.savingsSummary?.nextMonth?.savings ?? 0)}/mo`;

  return doc?.[platformType]?.reasonCantEnable !== "" ? `${Status.NotEligible} (${estimation})` : estimation;
}

export const getCustomersMonthVsLastReport = async (
  customerIds: string[],
  partnerCompany: string | undefined,
  isDoer: boolean
): Promise<({ report: ModelId<CloudAnalyticsModelWidgetModel> } | null)[]> => {
  const docRefs = customerIds.map((c) => getCustomerCurrentMonthSpend(c, partnerCompany));

  const results = await loadDocumentsByQueriesIfPossible(docRefs, { useQueryToRetrieveDocs: isDoer, idField: "id" });

  return results.flatMap((data) => {
    if (!data) {
      return [null];
    }
    return [{ report: data }];
  });
};

/**
 * Get customers Flexsave savings
 */
export async function getCustomersFlexsaveSavings(
  customersIds: string[],
  _partnerCompany: string | undefined,
  isDoer: boolean
): Promise<FlexsaveSavings[]> {
  const promises = customersIds.map((c) => getCustomerFlexsaveDoc(c));
  const results = await loadDocumentsByQueriesIfPossible(promises, { useQueryToRetrieveDocs: isDoer });

  return customersIds.map((customerId, index) => {
    const doc = results[index];
    const awsMonth = doc?.AWS?.savingsSummary?.currentMonth.month ?? null;
    const awsSavings = awsMonth ? (doc?.AWS?.savingsHistory?.[awsMonth]?.savings ?? 0) : FlexsaveStatus.Na;

    const awsInActive = getInActiveStatus(doc, FlexsaveTypes.AWS);

    const awsStatus = !doc?.AWS?.enabled ? awsInActive : awsSavings;

    return {
      aws: awsStatus,
    };
  });
}

const getCustomersRampPlanAttainment = async (
  customersIds: string[],
  contractType: "amazon-web-services" | "google-cloud"
): Promise<RampPlanAttainment[]> => {
  const customersIdsChunks = chunk(customersIds, FirestoreMaxInOperator);

  const chunkRampPlansQueries = customersIdsChunks.map(async (customersIdsChunk) => {
    const customerChunksRefs = customersIdsChunk.map((customerId) => getCollection(CustomerModel).doc(customerId));
    return getCollection(RampModel)
      .where("customerRef", "in", customerChunksRefs)
      .where("active", "==", true)
      .where("platform", "==", contractType)
      .orderBy("targetAmount", "desc")
      .get();
  });

  const chunkRampPlansQueriesResults = await Promise.all(chunkRampPlansQueries);
  const docs = chunkRampPlansQueriesResults.flatMap((chunkRampPlansQueriesResult) => chunkRampPlansQueriesResult.docs);

  const orderIndex = docs.reduce(
    (acc, doc, index) => {
      const key = doc.asModelData().customerRef.id;
      if (acc[key] === undefined) {
        // Check if the key does not exist in the accumulator
        acc[key] = index; // Assign the index to this key in the accumulator
      }
      return acc; // Return the updated accumulator
    },
    {} as Record<string, number>
  );

  return customersIds.map((customerId) => {
    const customerRampPlansDoc = docs[orderIndex[customerId]];

    if (!customerRampPlansDoc) {
      return {
        status: Status.Na,
      };
    }

    const doc = customerRampPlansDoc.asModelData();
    const totalActual = getTotalActuals(doc);

    return {
      total: doc.attainment,
      onTrack: totalActual >= sumPlannedUpToLastMonth(doc),
      status: Status.Active,
      planName: doc.name,
      planId: customerRampPlansDoc.id,
    };
  });
};
/**
 * Get customers Ramp Plan Attainment progress bar
 */
export const getCustomersAwsRampPlanAttainment = (customersIds: string[]): Promise<RampPlanAttainment[]> =>
  getCustomersRampPlanAttainment(customersIds, AssetTypeAmazonWebServices);

/**
 * Get customers Ramp Plan Attainment progress bar

 */
export const getCustomersGcpRampPlanAttainment = (customersIds: string[]): Promise<RampPlanAttainment[]> =>
  getCustomersRampPlanAttainment(customersIds, AssetTypeGoogleCloud);

export async function getCustomersContracts(
  customersIds: string[],
  contractType: "amazon-web-services" | "google-cloud"
): Promise<({ contract: WithFirebaseModel<ContractModel> } | null)[]> {
  const customersIdsChunks = chunk(customersIds, FirestoreMaxInOperator);

  const promises = customersIdsChunks.map(async (customersIdsChunk) => {
    const customerChunksRefs = customersIdsChunk.map((customerId) => getCollection(CustomerModel).doc(customerId));
    return getCollection(ContractModel)
      .where("customer", "in", customerChunksRefs)
      .where("active", "==", true)
      .where("type", "==", contractType)
      .where("isCommitment", "==", true)
      .get();
  });

  const docs = (await Promise.all(promises)).flatMap((querySnapshot) => querySnapshot.docs);

  const orderIndex = Object.fromEntries(docs.map((doc, index) => [doc.asModelData().customer.id, index]));

  return customersIds.map((customerId) => {
    const customerContractDoc = docs[orderIndex[customerId]];

    if (!customerContractDoc) {
      return null;
    }

    return { contract: customerContractDoc.asModelData() };
  });
}

/**
 * Get customers AWS Contracts
 */
export async function getCustomersAwsContracts(customersIds: string[]) {
  return getCustomersContracts(customersIds, AssetTypeAmazonWebServices);
}

/**
 * Get customers GCP Contracts
 */
export async function getCustomersGCPContracts(customersIds: string[]) {
  return getCustomersContracts(customersIds, AssetTypeGoogleCloud);
}

export async function getCustomersGkeCostAllocationStatus(
  customers: string[],
  _partnerCompany: string | undefined,
  isDoer: boolean
): Promise<GkeCostAllocationStatus[]> {
  const refs = customers.map((customerId) => getCustomerGKECostAllocation(customerId));

  const customersGkeCostAllocation = await loadDocumentsByQueriesIfPossible(refs, { useQueryToRetrieveDocs: isDoer });

  return customers.map((customerId, index) => {
    if (customerId === CSPCustomerID) {
      return {
        gkeCostAllocation: Status.Na,
      };
    } else {
      const gkeCostAllocation = customersGkeCostAllocation[index];
      const gkeStatus = gkeCostAllocation?.enabled ? Status.Active : Status.InActive;
      return {
        gkeCostAllocation: gkeStatus,
      };
    }
  });
}

/**
 * Get customers BigQuery Lens, GKE Metering feature enable
 */
export async function getCustomersCloudConnectStatus(
  customers: string[],
  _partnerCompany: string | undefined,
  isDoer: boolean
): Promise<CloudConnectStatus[]> {
  const cloudConnectPromises = customers.map((c) => getCustomerCloudConnect(c));
  const bigqueryLensPromises = customers.map((c) => getCustomerBigqueryLens(c));
  const gkeMeteringPromises = customers.map((c) => getCustomerGKEMetering(c));
  const [customersCloudConnect, bigqueryLens, customersGkeMetering] = await Promise.all([
    Promise.all(
      cloudConnectPromises.map(async (query) => {
        const querySnap = await query.get();
        if (querySnap.empty) {
          return undefined;
        }
        return querySnap.docs[0].asModelData();
      })
    ),
    loadDocumentsByQueriesIfPossible(bigqueryLensPromises, { useQueryToRetrieveDocs: isDoer }),
    loadDocumentsByQueriesIfPossible(gkeMeteringPromises, { useQueryToRetrieveDocs: isDoer }),
  ]);

  return customers.map((customerId, index) => {
    const gcpConnect = customersCloudConnect[index];

    if (!gcpConnect) {
      return {
        bigQueryLens: Status.Na,
        gkeMetering: Status.Na,
      };
    }

    const gkeMetering = customersGkeMetering[index];

    const bigqueryLensDoc = bigqueryLens[index];
    const finOpsPermissions = gcpConnect.categoriesStatus?.["bigquery-finops"];
    const status = bigqueryLensDoc?.progress === 100 ? Status.Active : Status.InActive;
    const gkeStatus = gkeMetering?.completedSuccesfully ? Status.Active : Status.InActive;

    return {
      customerId,
      bigQueryLens: finOpsPermissions === 1 ? status : Status.Na,
      gkeMetering: gkeMetering ? gkeStatus : Status.Na,
    };
  });
}

/**
 * Update account manager fields
 */
export async function updateAccountManager(
  name: string,
  phone: string,
  ref: ModelReference<AccountManagerModel>
): Promise<void> {
  await ref.update({
    name,
    phone,
  });
}

export async function asyncSumCustomersMonthSpend(
  report: WithFirebaseModel<CloudAnalyticsModelWidgetModel> | undefined,
  { lastMonth, countAllDays, currency }: { lastMonth: boolean; countAllDays?: boolean; currency?: CurrencyCode }
): Promise<number | null> {
  if (!report) {
    return null;
  }

  const getDateRange = () => {
    const now = DateTime.utc();
    const twoDatesBefore = (dt: DateTime) => {
      const twoDayBefore = dt.minus({ days: 2 }); // up to two days ago
      const startOfMonth = dt.startOf("month");

      return twoDayBefore < startOfMonth ? null : twoDayBefore;
    };

    const geValues = () => {
      if (lastMonth) {
        // Calculate start and end dates for the previous month
        const start = now.minus({ months: 1 }).startOf("month");
        const end = countAllDays ? start.endOf("month") : twoDatesBefore(now.minus({ months: 1 }));
        return {
          start,
          end,
        };
      }

      // Calculate start and end dates for the current month based on countAllDays
      const start = now.startOf("month");

      return {
        start,
        end: countAllDays ? now : twoDatesBefore(now),
      };
    };

    const { start, end } = geValues();

    if (!end) {
      return null;
    }

    return { start, end };
  };

  const filterDates = getDateRange();

  if (!filterDates) {
    return 0;
  }

  const { start, end } = filterDates;

  const filtersValues = Object.entries(report.data.rows).reduce((acc, [_rowIndex, row]) => {
    const date = DateTime.fromObject(
      {
        year: parseInt(row[0].toString(), 10),
        month: parseInt(row[1].toString(), 10),
        day: parseInt(row[2].toString(), 10),
      },
      { zone: "utc" }
    );

    if (date < start || date > end) {
      return acc;
    }

    acc.push(
      asyncConvertCurrencyTo(
        parseInt(row[3].toString(), 10),
        date,
        report.config.currency ?? CurrencyCodes.USD,
        currency ?? CurrencyCodes.USD
      )
    );

    return acc;
  }, [] as Promise<number>[]);

  return sum(await Promise.all(filtersValues));
}

/**
 * Calculate Customer Trend by this month vs last month
 * @param report
 */
export async function asyncCalculateTrend(
  report: WithFirebaseModel<CloudAnalyticsModelWidgetModel> | undefined
): Promise<number | null> {
  if (!report) {
    return null;
  }

  const sumCurrentMonth = await asyncSumCustomersMonthSpend(report, { lastMonth: false });
  const sumLastMonth = await asyncSumCustomersMonthSpend(report, { lastMonth: true });

  return sumLastMonth === null || sumCurrentMonth === null || sumLastMonth === 0
    ? null
    : ((sumCurrentMonth - sumLastMonth) / sumLastMonth) * 100;
}
