import {
  AppModel,
  AppRoleNotificationModel,
  CustomerModel,
  DashboardModel,
  EntityModel,
  InviteModel,
  NotificationProviderType,
  type ProductEnum,
  UserModel,
  type UserNotification,
} from "@doitintl/cmp-models";
import { getBatch, getCollection, type ModelReference, type WithModelValue } from "@doitintl/models-firestore";
import type * as axios from "axios";

import { type Customer } from "../../types";
import { serverTimestamp } from "../../utils/firebase";

export type CreateInviteArg = Omit<InviteModel, "subCollections" | "timestamp" | "invitationSentTimestamp"> &
  WithModelValue<Pick<InviteModel, "timestamp" | "invitationSentTimestamp">>;

export const getUserNotifications = async () => {
  const userNotificationsQuery = await getCollection(AppModel).doc("userNotifications").get();
  return userNotificationsQuery.asModelData()?.notifications ?? [];
};

export const getColumnsData = async () => {
  const usersCsvColumnsDataQuery = await getCollection(AppModel).doc("users-csv-columns").get();
  return usersCsvColumnsDataQuery.data();
};

export const getEntities = async (customer: Customer) => {
  const entitiesQuery = await getCollection(EntityModel).where("customer", "==", customer.ref).get();
  return entitiesQuery.docs.map((doc) => ({ id: doc.id, ref: doc.ref, ...doc.data() }));
};

export const addUsers = (users) => {
  const batch = getBatch();
  users.forEach((user) => {
    const userRef = getCollection(InviteModel).newDoc();
    batch.set(userRef, user);
  });
  return batch.commit();
};

export const updateUsers = async (users, customer) => {
  const batch = getBatch();
  await Promise.all(
    users.map(async (user) => {
      const userInviteQuery = await getCollection(InviteModel)
        .where("customer.ref", "==", customer.ref)
        .where("email", "==", user.email)
        .get();
      const userQuery = await getCollection(UserModel)
        .where("customer.ref", "==", customer.ref)
        .where("email", "==", user.email)
        .get();

      const userRef = userInviteQuery.docs[0]?.ref ?? userQuery.docs[0]?.ref;
      batch.update(userRef, user);
    })
  );

  return batch.commit();
};

export async function setInvitedUserNotificationsByPermissions(
  inviteRef: ModelReference<InviteModel>,
  inviteData: Pick<InviteModel, "email" | "customer">,
  defaultNotifications: number[]
) {
  if (defaultNotifications?.length) {
    return inviteRef
      .collection("userPersonalNotifications")
      .doc("from-invite") // using predetermined id and the set operator to maintain a single document per invite
      .set({
        createdAt: serverTimestamp(),
        createdBy: inviteData.email,
        name: "My email notifications",
        providerTarget: { [NotificationProviderType.EMAIL]: [inviteData.email] },
        selectedNotifications: Object.fromEntries(
          defaultNotifications.map((notificationType) => [notificationType, {}])
        ),
        selectedNotificationsKeys: defaultNotifications,
        customerRef: inviteData.customer.ref,
      });
  }

  return null;
}

export const createInvite = async (newUser: CreateInviteArg, defaultNotifications: number[]) => {
  const doc = await getCollection(InviteModel).add(newUser);
  await setInvitedUserNotificationsByPermissions(doc, newUser, defaultNotifications);
  return doc.id;
};

export const updateUserInvitation = async (
  email: string,
  user: Partial<WithModelValue<InviteModel>>,
  customerId: string,
  defaultNotifications: UserNotification[]
) => {
  const customerRef = getCollection(CustomerModel).doc(customerId);
  const userQuery = await getCollection(InviteModel)
    .where("customer.ref", "==", customerRef)
    .where("email", "==", email)
    .get();

  const doc = userQuery.docs[0];

  if (doc) {
    const userInviteRef = doc.modelRef;
    await userInviteRef.update(user);
    await setInvitedUserNotificationsByPermissions(doc.ref, doc.asModelData(), defaultNotifications);
    return userInviteRef.id;
  }
};

export const getUserUidByEmail = async (email: string, api: axios.AxiosInstance, customerId: string) => {
  const response = await api.request({
    method: "get",
    params: { email },
    url: `/v1/customers/${customerId}/users/getUserId`,
  });
  return response?.data;
};

export const getNotificationsPerRole = async (rRefId: string): Promise<number[]> => {
  const notifsPerRole = await getCollection(AppRoleNotificationModel).doc("notifications-per-role").get();
  if (!notifsPerRole.exists()) {
    return [];
  }
  const perRoleData = notifsPerRole.asModelData();
  return perRoleData?.roleNotifications.find((r) => r?.roleRef.id === rRefId)?.notifications ?? [];
};

export const getBaseDailyDigestAttribution = (
  dailyDigestsAttributionIds: { assetId: ProductEnum; value: string }[],
  customer: Customer
) => {
  const res = dailyDigestsAttributionIds.filter((d) => customer?.assets?.includes(d.assetId));
  return res.map((attr) =>
    getCollection(DashboardModel).doc("google-cloud-reports").collection("attributions").doc(attr.value)
  );
};
