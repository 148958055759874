import type { AxiosInstance } from "axios";

import type { AzureSubscription, RoleRequest } from "./types";

export class AzureConnectApi {
  private readonly apiPrefix: string;

  constructor(private readonly api: AxiosInstance) {
    this.api = api;
    this.apiPrefix = `/v1/azure`;
  }

  public async healthcheck(customerID: string, tenantID: string): Promise<AzureSubscription[]> {
    const response = await this.api.get(`${this.apiPrefix}/customer/${customerID}/health-check/${tenantID}`);
    return response.data as AzureSubscription[];
  }

  public async listTenantsSubscriptions(customerID: string): Promise<AzureSubscription[]> {
    const response = await this.api.get(`${this.apiPrefix}/customer/${customerID}/list-subscriptions`);
    return (response.data || []) as AzureSubscription[];
  }

  public async addAzureRole(customerId: string, roleRequest: RoleRequest | undefined) {
    await this.api.post(`${this.apiPrefix}/customer/${customerId}/role`, roleRequest);
  }

  public async removeAzureRole(customerId: string, tenantID: string) {
    await this.api.delete(`${this.apiPrefix}/customer/${customerId}/tenant/${tenantID}`);
  }
}
