import React, { lazy, Suspense } from "react";

import { Redirect, Switch } from "react-router-dom";

import { routes } from "../../constants/navigation";
import { useAuthContext } from "../../Context/AuthContext";
import { useUserContext } from "../../Context/UserContext";
import { RedirectPage } from "../../Pages/CloudDiagrams/RedirectPage";
import FlexsaveOrders from "../../Pages/Flexsave/AllCustomers";
import InsufficientPermissions from "../../Pages/InsufficientPermissions";
import NotFound from "../../Pages/NotFound/NotFound";
import { doitCustomerId } from "../../utils/customers";
import { AnnouncekitAuthRedirect } from "../AnnouncekitAuth/AnnouncekitAuthRedirect";
import { DoitEmployeeGuard, Guard } from "../Guard";
import { DeveloperDocs } from "../hooks/useDeveloperDocs";
import { HomeRoute } from "./HomeRoute";
import { NoTraceRoute, TraceRoute } from "./TraceRoutes";

const ConfigurationPage = lazy(
  () => import(/* webpackChunkName: 'configuration-page' */ "../../Pages/ConfigurationPage/ConfigurationPage")
);

export const AllRoutes = () => {
  const { userRoles } = useUserContext();
  const { userId, currentUser, customerId, isDoitEmployee, isDoitPartner } = useAuthContext({
    mustHaveUser: true,
  });

  if (!userRoles) {
    return null;
  }

  return (
    <Switch>
      <NoTraceRoute component={NotFound} path="/not-found" />
      {/* simple route to test the error boundary, should be removed after validation in prod */}
      <NoTraceRoute
        exact
        path="/boom"
        render={() => {
          throw new Error("boom");
        }}
      />
      <TraceRoute path="/clouddiagrams" component={RedirectPage} />
      <TraceRoute
        // login is legacy, sign-in is the one used instead
        path="/login"
        render={(props) => (
          <Redirect
            to={{
              pathname: props.history.location.pathname.replace("/login", routes.signin),
              search: props.history.location.search,
            }}
          />
        )}
      />
      <TraceRoute exact path="/signup" render={() => <Redirect to="/" />} />
      <NoTraceRoute path="/announcekit-auth" render={() => <AnnouncekitAuthRedirect />} />
      <NoTraceRoute
        exact
        path={routes.configuration}
        render={(props) =>
          ["adam.g@doit-intl.com", "eben@doit-intl.com", "tomer@doit-intl.com"].includes(currentUser.email) ? (
            <Suspense fallback={<div>Loading...</div>}>
              <ConfigurationPage {...props} />
            </Suspense>
          ) : (
            <NotFound />
          )
        }
      />
      <TraceRoute
        path="/flexsave"
        render={() => (
          <DoitEmployeeGuard>
            <FlexsaveOrders />
          </DoitEmployeeGuard>
        )}
      />
      <TraceRoute path="/profile/api">
        <Guard isAllowed={!isDoitPartner && !!userId && (customerId || isDoitEmployee)}>
          <Redirect to={`/customers/${isDoitEmployee ? doitCustomerId : customerId}/profile/${userId}/api`} />
        </Guard>
      </TraceRoute>
      <NoTraceRoute exact path="/developer-docs" component={DeveloperDocs} />
      <NoTraceRoute
        exact
        path="/noaccess/:origin_url?/:missing_permissions?/:troubleshoot_url?"
        render={() => <InsufficientPermissions />}
      />
      <HomeRoute />
      <NoTraceRoute component={NotFound} />
    </Switch>
  );
};
