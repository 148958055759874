import { copyCommandNames, copyCommandVariables } from "./consts";
import { type RoleIds, type Scope } from "./types";

export const camelToTitleCase = (str: string): string =>
  str.replace(/([A-Z])/g, " $1").replace(/^./, (s): string => s.toUpperCase());

export const getNewResources = (
  resourcesIds: RoleIds[],
  featureName: string,
  roleName: string,
  scope: Scope,
  resourceId: string,
  roleId: string
): RoleIds[] => {
  const newRolesIds = resourcesIds.filter(
    (resourceId) =>
      resourceId.featureName !== featureName || resourceId.roleName !== roleName || resourceId.scope !== scope
  );
  newRolesIds.push({
    featureName,
    roleName,
    scope,
    resourceId,
    roleId,
  });

  return newRolesIds;
};

export const getCommandCopy = (scope: Scope, value: string) => {
  const result: { [key: string]: { value: string; name: string } } = {};
  result[copyCommandVariables[scope]] = { value, name: copyCommandNames[scope] };
  return result;
};
