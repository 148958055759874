import { useCallback, useEffect } from "react";

import { type GcpMarketplaceAccountModel, MarketplaceModel } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type ModelReference,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import maxBy from "lodash/maxBy";

import { useMap } from "../../../../utils/useMap";
import { formatFirestoreDate } from "../../../CloudAnalytics/utilities";
import { type CustomersIdsMap, type CustomerWithRef } from "./useMarketplaceCustomers";

export type AccountsNameMap = Omit<
  Map<string, WithFirebaseModel<GcpMarketplaceAccountModel>>,
  "set" | "clear" | "delete"
>;

export type AccountConfig = {
  data: WithFirebaseModel<GcpMarketplaceAccountModel>;
  ref: ModelReference<GcpMarketplaceAccountModel>;
  transform?: {
    isPending: boolean;
    createTime: string;
    updateTime: string;
    state: string;
  };
  customer?: CustomerWithRef;
  costAnomalyEnabled?: boolean;
};

export const useMarketplaceAccounts = (
  customersIdsMap: CustomersIdsMap
): [AccountConfig[] | undefined, AccountsNameMap, boolean] => {
  const [accountsNameMap, accountsNameMapActions] = useMap<string, WithFirebaseModel<GcpMarketplaceAccountModel>>(
    new Map()
  );

  const createTransform = (data: WithFirebaseModel<GcpMarketplaceAccountModel>) => {
    if (!data.procurementAccount) {
      return undefined;
    }

    const res = {
      isPending: false,
      createTime: formatFirestoreDate(data.procurementAccount.createTime),
      updateTime: formatFirestoreDate(data.procurementAccount.updateTime),
      state: "",
    };

    switch (data.procurementAccount.state) {
      case "ACCOUNT_ACTIVE": {
        let statusText = "Active";
        const signUpApprovals = data.procurementAccount.approvals.filter((el) => el.name === "signup");
        const latestApproval = maxBy(signUpApprovals, (o) => o.updateTime?.seconds ?? 0);

        if (latestApproval?.state === "PENDING") {
          statusText += " (sign up pending)";
          res.isPending = true;
        } else if (latestApproval?.state === "REJECTED") {
          statusText += " (sign up rejected)";
        }

        res.state = statusText;
        break;
      }
      case "ACCOUNT_DELETED":
        res.state = "Deleted";
        break;
      case "ACCOUNT_STATE_UNSPECIFIED":
        res.state = "Unspecified";
        break;
      default:
    }

    return res;
  };

  const transformDoc = useCallback(
    (
      data: WithFirebaseModel<GcpMarketplaceAccountModel>,
      docSnapshot: DocumentSnapshotModel<GcpMarketplaceAccountModel>
    ): AccountConfig => ({
      data,
      ref: docSnapshot.ref,
      transform: createTransform(data),
    }),
    []
  );

  const [accounts, accountsLoading] = useCollectionData(
    getCollection(MarketplaceModel).doc("gcp-marketplace").collection("gcpMarketplaceAccounts"),
    {
      transform: transformDoc,
    }
  );

  useEffect(() => {
    accounts?.forEach((a) => {
      if (a.data.procurementAccount?.name) {
        accountsNameMapActions.set(a.data.procurementAccount.name, a.data);
      }
    });
  }, [accountsNameMapActions, accounts]);

  useEffect(() => {
    if (!accounts || accounts.length === 0 || !customersIdsMap) {
      return;
    }

    accounts.forEach((account) => {
      if (account.data.customer?.id) {
        const customerWithRef = customersIdsMap.get(account.data.customer.id);
        if (customerWithRef) {
          account.customer = customerWithRef;
        }
      }
      return account;
    });
  }, [accounts, customersIdsMap]);

  return [accounts, accountsNameMap, accountsLoading];
};
