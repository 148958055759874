import { type AttributionFilter, type CloudAnalyticsModelMetricModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";

import { attributionText, globalText } from "../../../assets/texts";
import { type Transforms } from "../../../Components/hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import { type MetadataOption, type MetricWSnap } from "../../../types";
import { createDefaultMDOption } from "../utilities";

export const getCalcMetricFromAlert = (
  alertCalcMetric: ModelReference<CloudAnalyticsModelMetricModel> | null,
  calcMetrics: MetricWSnap[]
): MetricWSnap | null => (alertCalcMetric && calcMetrics?.find((a) => a.ref.id === alertCalcMetric.id)) ?? null;

export const prepareScopeDescription = (
  filter: AttributionFilter,
  dimensions: MetadataOption[],
  transforms: Transforms
) => {
  const values = [...(filter.values ?? [])];
  const dimension = dimensions.find((d) => d.id === filter.id);
  const label = dimension?.data.label ?? "";
  let desc = globalText.NA;
  let resValues = "";

  if (filter.allowNull && dimension?.data.nullFallback && !values.includes(dimension.data.nullFallback)) {
    values.unshift(dimension.data.nullFallback);
  }
  if (values.length > 0 && dimension) {
    const valuesTransformed = values.map((v) => transforms?.[filter.id ?? ""]?.(v, dimension.data.nullFallback) ?? v);
    const firstThree = valuesTransformed.splice(0, 3);
    const restOfString = valuesTransformed.length > 0 ? `and +${valuesTransformed.length}` : "";
    desc = filter.inverse ? attributionText.IS_NOT : attributionText.IS;
    resValues = `${firstThree.join(", ")} ${restOfString}`;
  }
  return {
    desc,
    label,
    values: resValues,
  };
};

export const getScopeDescription = (
  filters: AttributionFilter[] | undefined,
  dimensions: MetadataOption[],
  transforms: Transforms
) => {
  const filter = filters?.at(0);
  if (!filter) {
    return {
      desc: globalText.NA,
      label: "",
      values: "",
    };
  }

  const scopeDesc = prepareScopeDescription(filter, dimensions, transforms);
  let { desc, values } = scopeDesc;
  if (filter?.regexp?.length) {
    desc = filter.inverse ? attributionText.REGEX_NO_MATCH : attributionText.REGEX_MATCH;
    values = filter.regexp;
  }

  return {
    desc,
    label: scopeDesc.label,
    values,
  };
};

export const getFilteredDimension = (dimension: MetadataOption, filter: AttributionFilter): MetadataOption | null => {
  const newDimension: MetadataOption = {
    ...createDefaultMDOption(),
    id: dimension.id,
    data: dimension.data,
    _values: dimension._values ?? { awsValues: [], gcpValues: [] },
    _regexp: filter.regexp ?? null,
    _inverse: filter.inverse,
    _filter: [...(filter.values ?? [])],
  };
  if (filter.allowNull && dimension.data.nullFallback && !filter.values?.includes(dimension.data.nullFallback)) {
    newDimension._filter?.unshift(dimension.data.nullFallback);
  }

  return newDimension;
};

export const getFilteredDimensionFromDimensions = (
  dimensions: MetadataOption[],
  filter?: AttributionFilter
): MetadataOption | null => {
  if (!filter) {
    return null;
  }
  const dimension = dimensions.find((d) => d.id === filter.id);
  if (!dimension) {
    return null;
  }

  return getFilteredDimension(dimension, filter);
};
