import { useEffect } from "react";

import { type GcpMarketplaceAdjustmentModel, MarketplaceModel } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type ModelReference,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";

import { formatFirestoreDate } from "../../../CloudAnalytics/utilities";
import { type CustomersIdsMap, type CustomerWithRef } from "../hooks/useMarketplaceCustomers";

export type AdjustmentConfig = {
  data: WithFirebaseModel<GcpMarketplaceAdjustmentModel>;
  ref: ModelReference<GcpMarketplaceAdjustmentModel>;
  transform: {
    targetTime: string;
    timeCreated: string;
    timeReported: string;
    timeUpdated: string;
    value: number;
  };
  customer?: CustomerWithRef;
};

export const useMarketplaceAdjustments = (
  customersIdsMap: CustomersIdsMap
): [AdjustmentConfig[] | undefined, boolean] => {
  const dataQuery = getCollection(MarketplaceModel).doc("gcp-marketplace").collection("gcpMarketplaceAdjustments");

  const transformDoc = (
    data: WithFirebaseModel<GcpMarketplaceAdjustmentModel>,
    docSnapshot: DocumentSnapshotModel<GcpMarketplaceAdjustmentModel>
  ): AdjustmentConfig => ({
    data,
    ref: docSnapshot.ref,
    transform: {
      targetTime: formatFirestoreDate(data.targetTime),
      timeCreated: formatFirestoreDate(data.timeCreated),
      timeReported: formatFirestoreDate(data.timeReported),
      timeUpdated: formatFirestoreDate(data.timeUpdated),
      value: data.value / 100,
    },
  });

  const [adjustments, loading] = useCollectionData(dataQuery, {
    transform: transformDoc,
  });

  useEffect(() => {
    if (!adjustments || adjustments.length === 0 || !customersIdsMap) {
      return;
    }

    adjustments.forEach((adjustment) => {
      if (adjustment.data.customer.id) {
        const customerWithRef = customersIdsMap.get(adjustment.data.customer.id);
        if (customerWithRef) {
          adjustment.customer = customerWithRef;
        }
      }
    });
  }, [adjustments, customersIdsMap]);

  return [adjustments, loading];
};
