import { useCallback } from "react";

import { type DashboardModelSavedReportsModel } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";

import { analyticsResourcesValues } from "../../../assets/texts/CloudAnalytics";
import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import { type AttributionWRef } from "../../../types";
import { useFullScreen } from "../../../utils/dialog";
import { shortenString } from "../../../utils/string";
import { type AttributionGroupWithRef } from "../attributionGroups/types";

export type Report = { data: WithFirebaseModel<DashboardModelSavedReportsModel> };

type Resource = {
  id: string;
  name: string;
  owner?: string;
};

export type AffectedResources = {
  id: string;
  resources: Record<string, Resource[]>;
};

const limitAllocations = 3;

type Props = {
  deleteValidationResponse: AffectedResources[] | null;
  onClose: () => void;
  resources: (AttributionGroupWithRef | AttributionWRef)[];
};

export const DeleteValidationDialog = ({ deleteValidationResponse, onClose, resources }: Props) => {
  const { isMobile: xsDown } = useFullScreen("sm");

  const getResource = useCallback((resource) => {
    const { resourceType, resources }: { resourceType: string; resources: Resource[] } = resource;
    if (resourceType === "dailyDigests") {
      return (
        <div>
          <b>{analyticsResourcesValues[resourceType]}</b>
        </div>
      );
    }

    const resourceNames = resources.map(
      (resource) => resource.name + (resource.owner ? ` (owner: ${resource.owner}) ` : "")
    );

    return (
      <Typography variant="body2">
        <b>{analyticsResourcesValues[resourceType]}</b>: {shortenString(resourceNames.join(", "), limitAllocations)}
      </Typography>
    );
  }, []);

  const getAllocationNameById = useCallback(
    (id: string) => resources.find((a) => a.ref.id === id)?.data.name,
    [resources]
  );

  const parseDeleteValidationData = useCallback(
    (data) => {
      const { id, resources }: { id: string; resources: { type: string; ids: string[] }[] } = data;
      const attributionName = getAllocationNameById(id);

      return (
        <li key={id}>
          <b>{attributionName}</b>
          <ul>
            {Object.entries(resources).map(([resourceType, blockingResources]) => (
              <li key={resourceType}>{getResource({ resourceType, resources: blockingResources })}</li>
            ))}
          </ul>
        </li>
      );
    },
    [getResource, getAllocationNameById]
  );

  const getContent = useCallback(() => {
    if (!deleteValidationResponse) {
      return null;
    }

    return (
      <Typography variant="body2">
        <ul>{deleteValidationResponse.map(parseDeleteValidationData)}</ul>
      </Typography>
    );
  }, [deleteValidationResponse, parseDeleteValidationData]);

  return (
    <Dialog fullScreen={xsDown} open={!!deleteValidationResponse} onClose={onClose} data-cy="deleteAssetDialog">
      <DialogTitle>{allocationTxt.DELETE_VALIDATION_DIALOG_TITLE}</DialogTitle>
      {!!deleteValidationResponse && (
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">{allocationTxt.DELETE_VALIDATION_DIALOG_CONTENT}</Typography>
            {getContent()}
          </DialogContentText>
        </DialogContent>
      )}
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" data-cy="cancelBtn">
          {allocationTxt.DONE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
