import { useCallback, useState } from "react";

import {
  type ApiServiceBaseOperationDescriptor,
  type CloudFlowNodeType,
  CloudFlowProvider,
  type Member,
  ModelType,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Alert, Button, List, ListItem, Stack, Typography } from "@mui/material";

import { CopyCodeBlock } from "../../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { Loader } from "../../../../../Components/Loader";
import LoadingButton from "../../../../../Components/LoadingButton";
import { type NodeConfigs, PermissionValidityStatus } from "../../../types";
import { ApiActionParametersForm } from "../../ApiActionParametersForm/ApiActionParametersForm";
import AWSPermissionForm from "../../ApiActionParametersForm/AWSPermissionForm";
import GCPPermissionForm from "../../ApiActionParametersForm/GCPPermissionForm";
import { useGetOperationById } from "../../Common/hooks/useGetOperationById";
import { useUnwrappedApiActionModel } from "../../Common/hooks/useUnwrappedApiActionModel";
import { GCPHierarchicalResource } from "../../utils/gcpUtils";
import { isGcpPermissionConfigValues, type PermissionConfigValues, useCloudPermissions } from "../hooks";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

const GcpPermissionFormWrapper = ({
  operation,
  permissionFormValues,
  setConfigurationFormValid,
  onConfigurationValuesChange,
  isOperationLoading,
  modelId,
  resetPermissions,
}: {
  operation: WithFirebaseModel<ApiServiceBaseOperationDescriptor> | null;
  permissionFormValues: object;
  setConfigurationFormValid: (valid: boolean) => void;
  onConfigurationValuesChange: (values: unknown) => void;
  isOperationLoading: boolean;
  modelId: string | undefined;
  resetPermissions: () => void;
}) => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const isGCPProvider = nodeConfig.parameters.operation.provider === CloudFlowProvider.GCP;

  const permissionsFormModel = operation?.parameters && {
    ...operation.parameters,
    members: {
      ...operation.parameters.members,
      ...(isGCPProvider && {
        permissionsProjectId: {
          model: { type: ModelType.STRING },
        } satisfies Member,
        hierarchicalResource: {
          model: {
            type: ModelType.STRING,
            enum: [GCPHierarchicalResource.ORGANIZATION, GCPHierarchicalResource.PROJECT],
          },
        } satisfies Member,
      }),
    },
    requiredMembers: isGCPProvider
      ? ["organization", "serviceAccount", "hierarchicalResource"]
      : operation.parameters.requiredMembers,
  };

  return (
    <Loader loading={isOperationLoading || modelId !== operation?.inputModel}>
      {permissionsFormModel && (
        <ApiActionParametersForm
          key={nodeConfig.id}
          inputModel={permissionsFormModel}
          values={permissionFormValues}
          onValidityChange={setConfigurationFormValid}
          onValuesChange={onConfigurationValuesChange}
        >
          <GCPPermissionForm resetPermissions={resetPermissions} inputModel={permissionsFormModel} />
        </ApiActionParametersForm>
      )}
    </Loader>
  );
};

const PermissionCheckResults = ({
  nodeConfig,
  permissionFormValues,
  loading,
  status,
  requiredPermissions,
  command,
}: {
  nodeConfig: NodeConfigs<CloudFlowNodeType.ACTION>;
  permissionFormValues: PermissionConfigValues;
  loading: boolean;
  status: PermissionValidityStatus;
  requiredPermissions: string[];
  command: string;
}) => {
  const getConsoleLink = (): string => {
    if (nodeConfig.parameters?.provider === "AWS") {
      return `https://console.aws.amazon.com/cloudshell/home?account=${nodeConfig.parameters.configurationValues.accountId}`;
    }
    if (nodeConfig.parameters?.provider === "GCP" && nodeConfig.parameters?.configurationValues?.organization) {
      if (!isGcpPermissionConfigValues(permissionFormValues)) {
        return "";
      }
      if (permissionFormValues.hierarchicalResource === GCPHierarchicalResource.PROJECT) {
        return `https://console.cloud.google.com/iam-admin/iam?project=${permissionFormValues.permissionsProjectId}`;
      }

      const orgId = nodeConfig.parameters?.configurationValues?.organization.split("/").pop();
      return `https://console.cloud.google.com/iam-admin/iam?organizationId=${orgId}`;
    }

    return "#";
  };

  if (loading) return null;

  switch (status) {
    case PermissionValidityStatus.VALID: {
      return (
        <Alert severity="success" sx={{ px: 2, alignSelf: "stretch" }}>
          <Typography variant="body2">All permissions are in place to run this action</Typography>
        </Alert>
      );
    }
    case PermissionValidityStatus.UNABLE_TO_VERIFY: {
      return (
        <Alert severity="warning" sx={{ px: 2, alignSelf: "stretch" }}>
          <Typography variant="body2">
            We couldn't verify the necessary permissions or no permission is associated with this action.
          </Typography>
        </Alert>
      );
    }
    case PermissionValidityStatus.INVALID: {
      return (
        <>
          <Alert severity="warning">
            <Typography variant="body2">We require the following permissions in order to run this node: </Typography>
            <List sx={{ listStyleType: "disc", pl: 2 }}>
              {requiredPermissions?.map((permission) => (
                <ListItem key={permission} sx={{ display: "list-item", wordBreak: "break-all" }}>
                  {permission}
                </ListItem>
              ))}
            </List>
          </Alert>
          <Stack spacing={1} alignItems="flex-start">
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              Run the following command to grant the permission:
            </Typography>
            <CopyCodeBlock base={command} />
            <Button variant="outlined" component="a" href={getConsoleLink()} target="_blank">
              <OpenInNewIcon
                sx={{
                  width: 20,
                  height: 20,
                  mr: 1,
                }}
              />
              Go to {nodeConfig.parameters?.provider} Console
            </Button>
          </Stack>
        </>
      );
    }
    default:
      return null;
  }
};

const PermissionsTab = () => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  const { configurationValues } = nodeConfig.parameters;

  const [permissionFormValues, setPermissionFormValues] = useState<PermissionConfigValues>(configurationValues);

  const { requiredPermissions, command, loading, updatePermissions, status, resetPermissions } = useCloudPermissions({
    permissionConfigValues: permissionFormValues,
  });

  const {
    operationData: { operation, operationPointer },
    loading: isOperationLoading,
  } = useGetOperationById(nodeConfig.parameters.operation);
  const { modelId } = useUnwrappedApiActionModel(operationPointer, operation?.inputModel);
  const [configurationFormValid, setConfigurationFormValid] = useState<boolean>(true);

  const onConfigurationValuesChange = useCallback((newValues: unknown) => {
    setPermissionFormValues(newValues as PermissionConfigValues);
  }, []);

  return (
    <Stack spacing={2} p={2} alignItems="flex-start">
      {nodeConfig.parameters?.provider === "AWS" && <AWSPermissionForm />}
      {nodeConfig.parameters?.provider === "GCP" && (
        <GcpPermissionFormWrapper
          resetPermissions={resetPermissions}
          operation={operation}
          permissionFormValues={permissionFormValues}
          setConfigurationFormValid={setConfigurationFormValid}
          onConfigurationValuesChange={onConfigurationValuesChange}
          isOperationLoading={isOperationLoading}
          modelId={modelId}
        />
      )}
      <LoadingButton
        variant="outlined"
        onClick={updatePermissions}
        loading={loading}
        disabled={!configurationFormValid}
        mixpanelEventId="cloudflow.check-permissions"
        sx={{ alignSelf: "flex-start" }}
      >
        Check for required permissions
      </LoadingButton>
      <PermissionCheckResults
        nodeConfig={nodeConfig}
        permissionFormValues={permissionFormValues}
        loading={loading}
        status={status}
        requiredPermissions={requiredPermissions}
        command={command}
      />
    </Stack>
  );
};

export default PermissionsTab;
