import { useCallback, useEffect } from "react";

import { AssetModel, AssetTypeGoogleCloud, type GoogleCloudAssetModel } from "@doitintl/cmp-models";
import { getCollection, type TransformMethod, useCollectionData } from "@doitintl/models-firestore";

import { useAuthContext } from "../../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../../../Context/useCustomerOrPresentationModeCustomer";
import { type Asset } from "../../../../../types";
import { consoleErrorWithSentry } from "../../../../../utils";

const EMPTY_COLLECTION = [];

export function useCustomerGenuineGCPPartnerAssets(): Asset<GoogleCloudAssetModel>[] {
  const { isDoitEmployee } = useAuthContext();
  const { customer } = useCustomerContext();

  const shouldLoadCustomerGenuineAssets = isDoitEmployee && isCustomerInPresentationMode(customer);

  const transform: TransformMethod<GoogleCloudAssetModel, Asset<GoogleCloudAssetModel>> = useCallback(
    (data, docSnapshot) => ({
      data,
      id: docSnapshot.id,
      ref: docSnapshot.modelRef,
    }),
    []
  );

  const [customerGenuineGCPAssets, _loading, error] = useCollectionData(
    shouldLoadCustomerGenuineAssets
      ? getCollection(AssetModel)
          .where("customer", "==", customer.ref)
          .where("type", "==", AssetTypeGoogleCloud)
          .narrow<GoogleCloudAssetModel>()
      : undefined,
    {
      transform,
    }
  );

  useEffect(() => {
    if (error) {
      consoleErrorWithSentry(error);
    }
  }, [error]);

  return customerGenuineGCPAssets ?? EMPTY_COLLECTION;
}
