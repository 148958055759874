import {
  Aggregator,
  type CloudAnalyticsModelWidgetModel,
  type Col,
  CurrencyCodes,
  Feature,
  type ForecastSettings,
  Metric,
  Renderer,
  type ReportFilter,
  Roles,
  Sort,
  TimeInterval,
  TimeSettingsMode,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { handleCustomTimeRange } from "../../Pages/CloudAnalytics/generateReport/generateReport";
import {
  type createReportParams,
  type createReportPayload,
  type PayloadConfig,
} from "../../Pages/CloudAnalytics/generateReport/types";
import { type DataRecord } from "../../Pages/CloudAnalytics/ReportData";
import { type Customer } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import { TimestampFromDate } from "../../utils/firebase";
import mixpanel from "../../utils/mixpanel";
import { type AvaQueryRequest } from "./types";

function mapDataRecords(records: DataRecord[]): Col[] {
  return records.map((record: DataRecord) => ({
    allowNull: false,
    field: record.field,
    id: record.id,
    includeInFilter: false,
    inverse: false,
    key: record.key,
    label: record.label,
    limit: 0,
    position: record.position,
    relation: "",
    type: record.type,
  })) as Col[];
}
function transformArray(input: any[][] | null | undefined): { [key: string]: Array<number | null | string> } {
  const result: { [key: string]: Array<number | null | string> } = {};

  input?.forEach((subArray, index) => {
    const key = `${index}`;
    result[key] = subArray.map((element) => element);
  });

  return result;
}

function getRowsMap(
  rows: any[][] | null | undefined
): Record<string, [year: string, month: string, day: string, num1: number, num2: number, num3: number]> {
  const rowsMap: Record<string, [year: string, month: string, day: string, num1: number, num2: number, num3: number]> =
    {};

  rows?.forEach((row, i) => {
    rowsMap[i.toString()] = row as [year: string, month: string, day: string, num1: number, num2: number, num3: number];
  });

  return rowsMap;
}

function getFeatures(request: AvaQueryRequest): Feature[] | null {
  const features: Feature[] = [];
  if (request.trends && request.trends.indexOf(Feature.TREND_NONE) === -1) {
    features.push(...request.trends);
  }

  return features;
}

function getForecastSettings(request: AvaQueryRequest): ForecastSettings | null {
  if (request.forecastSettings) {
    return {
      historicalTimeIntervals: request.forecastSettings?.historicalTimeIntervals ?? 12,
      futureTimeIntervals: request.forecastSettings?.futureTimeIntervals ?? 12,
      mode: request.forecastSettings?.mode ?? "totals",
    };
  }
  return null;
}

export function constructWidgetFromAvaResponse(
  request: AvaQueryRequest,
  response: any,
  customer: Customer,
  userEmail: string
): WithFirebaseModel<CloudAnalyticsModelWidgetModel> {
  return {
    description: "",
    name: "",
    customer: customer.ref,
    customerID: customer.id,
    collaborators: [{ email: userEmail, role: Roles.OWNER }],
    isPublic: true,
    organization: customer.ref as any, // TODO: fix this
    report: "",
    size: 0,
    timeRefreshed: TimestampFromDate(new Date()),
    type: "custom",
    config: {
      calculatedMetric: null,
      comparative: null,
      count: null,
      aggregator: request.aggregator ?? Aggregator.TOTAL,
      colOrder: Sort.A_TO_Z,
      cols: mapDataRecords(request.cols),
      currency: request.currency ?? CurrencyCodes.USD,
      excludePartialData: request.excludePartialData ?? false,
      extendedMetric: request.extendedMetric ?? "",
      extendedMetricType: "",
      includeSubtotals: false,
      features: getFeatures(request),
      logScale: false,
      metric: request.metric ?? Metric.COST,
      renderer: request.chartType ?? Renderer.STACKED_COLUMN_CHART,
      rowOrder: Sort.A_TO_Z,
      rows: mapDataRecords(request.rows),
      timezone: request.timezone ?? "UTC",
      forecastSettings: getForecastSettings(request),
    },
    data: {
      forecastRows: transformArray(response?.data.forecastRows) ?? [],
      rows: getRowsMap(response?.data.rows) ?? [],
    },
  };
}
function mapReportFilters(filters: ReportFilter[]): any {
  return filters.map((filter: ReportFilter) => ({
    id: filter?.id,
    inverse: filter?.inverse,
    regexp: filter?.regexp,
    values: filter?.values,
    limit: filter?.limit,
  }));
}
export function constructReportPayloadFromAvaResponse(
  request: AvaQueryRequest,
  customer: Customer
): createReportPayload | createReportParams {
  let reportFilters = [];
  if (request?.filters && request?.filters.length > 0) {
    reportFilters = mapReportFilters(request.filters);
  }
  const reportConfig: PayloadConfig = {
    features: getFeatures(request),
    filters: reportFilters,
    cols: request?.cols.map((col: DataRecord) => col.id),
    rows: request?.rows.map((row: DataRecord) => `${row.type}:${row.key}`),
    colOrder: Sort.A_TO_Z,
    rowOrder: Sort.A_TO_Z,
    currency: request?.currency ?? CurrencyCodes.USD,
    metric: request.metric ?? Metric.COST,
    metricFilters: request?.metricFilters ?? [],
    calculatedMetric: null,
    extendedMetric: request?.extendedMetric,
    optional: null,
    renderer: request.chartType ?? Renderer.STACKED_COLUMN_CHART,
    timeInterval: (request.timeSettings?.interval as TimeInterval) ?? TimeInterval.DAY,
    aggregator: request.aggregator ?? Aggregator.TOTAL,
  };
  if (request.forecastSettings && request.forecast) {
    reportConfig.forecastSettings = {
      historicalTimeIntervals: request.forecastSettings.historicalTimeIntervals,
      futureTimeIntervals: request.forecastSettings.futureTimeIntervals,
      mode: request.forecastSettings.mode,
    };
  }
  const startTimeString = request.timeSettings?.from.toString();
  const endTimeString = request.timeSettings?.to.toString();
  if (startTimeString && endTimeString) {
    const startTime = DateTime.fromFormat(startTimeString, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    let endTime = DateTime.fromFormat(endTimeString, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    if (
      startTime.hasSame(endTime, "year") &&
      startTime.hasSame(endTime, "month") &&
      startTime.hasSame(endTime, "day")
    ) {
      endTime = endTime.plus({ days: 1 });
    }
    reportConfig.customTimeRange = {
      to: endTime,
      from: startTime,
    };
    reportConfig.customTimeRange = handleCustomTimeRange(reportConfig.customTimeRange);
    reportConfig.timeSettings = {
      mode: TimeSettingsMode.Fixed,
    } as any;
  }
  return {
    customer: customer.ref,
    draft: true,
    name: `Ava generated Report: ${DateTime.now().toFormat("MM-dd-yyyy")}`,
    config: reportConfig,
  };
}

export function AvaMixpanelCallbackFunc(event: string) {
  try {
    mixpanel.track(event);
  } catch (error) {
    consoleErrorWithSentry(error);
  }
}
