import { type BillingStandaloneOnboardingAWSModel, type SaaSConsoleType } from "@doitintl/cmp-models";
import { type UpdateFields } from "@services/cmp/models-firestore/src/core";

import { onboardingDocRef } from "../utils";

export async function updateStackCreationState(accountId: string, customerId: string, type: SaaSConsoleType) {
  const updateData: UpdateFields<BillingStandaloneOnboardingAWSModel> = {
    [`accounts.${accountId}.stackCreationState`]: "loading",
  };

  await onboardingDocRef(customerId, type).narrow<BillingStandaloneOnboardingAWSModel>().update(updateData);
}
