import { useCallback } from "react";

import { type CatalogGSuiteCloudService, CatalogModel, type CatalogOffice365CloudService } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type ModelData,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";

export type LegacyOffice365DataFormat = {
  type: "office-365";
  data: ModelData<CatalogOffice365CloudService>;
  snapshot: DocumentSnapshotModel<CatalogOffice365CloudService>;
};

export type LegacyGSuiteDataFormat = {
  type: "g-suite";
  data: ModelData<CatalogGSuiteCloudService>;
  snapshot: DocumentSnapshotModel<CatalogGSuiteCloudService>;
};

export type LegacyDataFormat = LegacyGSuiteDataFormat | LegacyOffice365DataFormat;

const useGSuiteCatalogContext = () => {
  const licenseQuery = getCollection(CatalogModel)
    .doc("g-suite")
    .collection("services")
    .orderBy("id", "asc")
    .narrow<CatalogGSuiteCloudService>();

  const transformGSuite = useCallback(
    (
      val: WithFirebaseModel<CatalogGSuiteCloudService>,
      docSnapshot: DocumentSnapshotModel<CatalogGSuiteCloudService>
    ) =>
      ({
        type: "g-suite",
        data: val,
        snapshot: docSnapshot,
      }) as const,
    []
  );

  return useCollectionData(licenseQuery, {
    transform: transformGSuite,
    caching: true,
    cachingKeys: ["g-suite"],
  });
};

export const useOffice365CatalogContext = () => {
  const licenseQuery = getCollection(CatalogModel)
    .doc("office-365")
    .collection("services")
    .orderBy("id", "asc")
    .narrow<CatalogGSuiteCloudService>();

  const transformOffice365 = useCallback(
    (
      val: WithFirebaseModel<CatalogOffice365CloudService>,
      snapshot: DocumentSnapshotModel<CatalogOffice365CloudService>
    ) =>
      ({
        type: "office-365",
        data: val,
        snapshot,
      }) as const,
    []
  );

  return useCollectionData(licenseQuery, {
    transform: transformOffice365,
    caching: true,
    cachingKeys: ["office-365"],
  });
};

export const CatalogHooks = {
  useGSuiteCatalogContext,
  useOffice365CatalogContext,
};
