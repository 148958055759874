import { Link } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";

import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useCustomerSubscriptions } from "../hooks";
import { RowComponent } from "./RowComponent";
import { filters, headerColumns } from "./table";
import { TableSkeleton } from "./TableSkeleton";

export const SubscriptionsPage = () => {
  const { customer } = useCustomerContext();
  const [subscriptions, isSubscriptionListLoaded, refetch] = useCustomerSubscriptions();

  return (
    <>
      <Box p={2}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent="space-between"
          gap={2}
        >
          <Typography variant="h1">Microsoft Azure</Typography>
          <Stack direction={{ xs: "column", sm: "row" }} gap={[1, 2]}>
            <Button
              variant="contained"
              component={Link}
              to={`/customers/${customer.id}/settings/azure/connect`}
              sx={{ whiteSpace: "nowrap" }}
            >
              Connect Azure subscription
            </Button>
          </Stack>
        </Stack>
        {!isSubscriptionListLoaded ? (
          <TableSkeleton />
        ) : (
          <>
            <FilterTable
              rowComponent={(row) => <RowComponent row={row.row} refetch={refetch} />}
              tableItems={subscriptions}
              headerColumns={headerColumns}
              filterColumns={filters}
              emptyTableComponent={
                <Typography variant="body2" textAlign="center" my={3} sx={{ opacity: 0.6 }}>
                  No connected Azure subscriptions
                </Typography>
              }
            />
          </>
        )}
      </Box>
    </>
  );
};
