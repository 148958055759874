import { useCallback } from "react";

import {
  type CommonSubscriptionNotificationSettings,
  CustomerModel,
  InviteModel,
  NotificationProviderType,
  type ReportSubscriptionNotificationSettings,
  UserModel,
  UserNotification,
} from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import { type Reference } from "@doitintl/models-types";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { RootOrgId, TimestampFromDateTime } from "../../utils/common";
import { isDoitEmployeeEmail } from "../../utils/email";
import { getCurrentTime } from "./api";
import { calcNextAt } from "./helpers";
import {
  type CommonFormCreationFields,
  type FormUpdateFields,
  type SubscriptionByType,
  type SubscriptionType,
} from "./types";

async function getCommonCreateValues({
  customerId,
  createdBy,
  orgRef,
  time,
  timeZone,
  startDate,
  frequency,
  customPeriodAmount,
  customPeriodUnit,
  emails,
  slackChannels,
  subscriptionType,
}: CommonFormCreationFields & { subscriptionType: SubscriptionType }) {
  const nextAt = calcNextAt({
    deliveryFrequency: frequency,
    startDate,
    time,
    timeZone,
    customPeriodAmount,
    customPeriodUnit,
    currentDateTime: await getCurrentTime(timeZone),
  });

  return {
    createdAt: new Date(),
    createdBy,
    selectedNotificationsKeys: [subscriptionType],
    selectedNotifications: {
      [subscriptionType]: {
        orgRef,
        frequency,
        timeZone,
        customPeriodAmount,
        customPeriodUnit,
        time: TimestampFromDateTime(time),
        startDate: TimestampFromDateTime(startDate),
        nextAt: TimestampFromDateTime(nextAt),
      },
    },
    customerRef: getCollection(CustomerModel).doc(customerId),
    providerTarget: {
      [NotificationProviderType.SLACK]: slackChannels,
      [NotificationProviderType.EMAIL]: emails,
    },
  };
}

export async function createDashboardSubscriptionDoc(
  args: CommonFormCreationFields & { dashboardName: string; dashboardPath: string }
) {
  const dataToAdd = await getCommonCreateValues({
    ...args,
    subscriptionType: UserNotification.DashboardSubscription,
  });

  const doc = await getCollection(CustomerModel)
    .doc(args.customerId)
    .collection("notifications")
    .add({
      ...dataToAdd,
      name: `${args.dashboardName} (Dashboard Subscription)`,
      selectedNotifications: {
        [UserNotification.DashboardSubscription]: {
          ...dataToAdd.selectedNotifications[UserNotification.DashboardSubscription],
          dashboardPath: args.dashboardPath,
        },
      },
    });

  return doc;
}

export async function createReportSubscriptionDoc(
  args: CommonFormCreationFields & {
    reportName: string;
    reportRef: ReportSubscriptionNotificationSettings["reportRef"];
  }
) {
  const dataToAdd = await getCommonCreateValues({
    ...args,
    subscriptionType: UserNotification.ReportSubscription,
  });

  const doc = await getCollection(CustomerModel)
    .doc(args.customerId)
    .collection("notifications")
    .add({
      ...dataToAdd,
      name: `${args.reportName} (Report Subscription)`,
      selectedNotifications: {
        [UserNotification.ReportSubscription]: {
          ...dataToAdd.selectedNotifications[UserNotification.ReportSubscription],
          reportRef: args.reportRef,
        },
      },
    });

  return doc;
}

export async function updateSubscriptionDoc<subscriptionType extends SubscriptionType>({
  doc,
  time,
  timeZone,
  startDate,
  frequency,
  customPeriodAmount,
  customPeriodUnit,
  emails,
  slackChannels,
  subscriptionType,
}: FormUpdateFields<subscriptionType>) {
  const docSettings = doc.selectedNotifications[subscriptionType];

  if (!docSettings) {
    throw new Error("Subscription settings not found");
  }

  const nextAt = calcNextAt({
    deliveryFrequency: frequency,
    startDate,
    time,
    timeZone,
    customPeriodAmount,
    customPeriodUnit,
    currentDateTime: await getCurrentTime(timeZone),
  });

  const newSettings: Partial<CommonSubscriptionNotificationSettings> = {
    frequency,
    timeZone,
    customPeriodAmount,
    customPeriodUnit,
    startDate: TimestampFromDateTime(startDate),
    time: TimestampFromDateTime(time),
    nextAt: TimestampFromDateTime(nextAt),
  };

  return doc.ref.set(
    {
      selectedNotifications: {
        [subscriptionType]: {
          ...newSettings,
        },
      } as any,
      providerTarget: {
        [NotificationProviderType.SLACK]: slackChannels,
        [NotificationProviderType.EMAIL]: emails,
      },
    },
    { merge: true }
  );
}

export async function deleteSubscriptionDoc<ST extends SubscriptionType>({
  _subscriptionType,
  doc,
}: {
  _subscriptionType: ST;
  doc: SubscriptionByType<ST>;
}) {
  return doc.ref.delete();
}

export function getActualOrg({
  orgs,
  customerId,
  isDoitEmployee,
}: {
  orgs: UserModel["organizations"];
  customerId: string;
  isDoitEmployee: boolean | undefined;
}) {
  if (isDoitEmployee || !orgs?.length) {
    return getCollection(CustomerModel).doc(customerId).collection("customerOrgs").doc(RootOrgId);
  }

  return orgs[0];
}

export function useDashboardSubscriptions({ dashboardPath }: { dashboardPath: string | null }) {
  const getQuery = useGetDashboardSubscriptionsQuery({});
  const query = getQuery({ dashboardPath });
  const [notifications] = useCollectionData(query, {
    idField: "id",
    refField: "ref",
  });

  return (notifications ?? []) as SubscriptionByType<UserNotification.DashboardSubscription>[];
}

export function useReportSubscriptions({
  reportRef,
}: {
  reportRef: Reference<ReportSubscriptionNotificationSettings["reportRef"]>;
}) {
  const getQuery = useGetReportSubscriptionsQuery({});
  const query = getQuery({ reportRef });
  const [notifications] = useCollectionData(query, {
    idField: "id",
    refField: "ref",
  });

  return (notifications ?? []) as SubscriptionByType<UserNotification.ReportSubscription>[];
}

export function useGetDashboardSubscriptionsQuery({ useAllOrgs }: { useAllOrgs?: boolean }) {
  const { customer } = useCustomerContext();
  const { userModel } = useUserContext({ allowNull: false });
  const { isDoitEmployee } = useAuthContext();

  return useCallback(
    ({ dashboardPath }: { dashboardPath: string | null }) => {
      const userOrg = getActualOrg({
        orgs: userModel.organizations,
        customerId: customer.id,
        isDoitEmployee,
      });

      let query = getCollection(CustomerModel)
        .doc(customer.id)
        .collection("notifications")
        .where("selectedNotificationsKeys", "array-contains", UserNotification.DashboardSubscription)
        .where(`selectedNotifications.${UserNotification.DashboardSubscription}.dashboardPath`, "==", dashboardPath);

      if (!useAllOrgs) {
        query = query.where(`selectedNotifications.${UserNotification.DashboardSubscription}.orgRef`, "==", userOrg);
      }

      return query;
    },
    [customer.id, isDoitEmployee, useAllOrgs, userModel.organizations]
  );
}

export function useGetReportSubscriptionsQuery({ useAllOrgs }: { useAllOrgs?: boolean }) {
  const { customer } = useCustomerContext();
  const { userModel } = useUserContext({ allowNull: false });
  const { isDoitEmployee } = useAuthContext();

  return useCallback(
    ({ reportRef }: { reportRef: Reference<ReportSubscriptionNotificationSettings["reportRef"]> }) => {
      const userOrg = getActualOrg({
        orgs: userModel.organizations,
        customerId: customer.id,
        isDoitEmployee,
      });

      let query = getCollection(CustomerModel)
        .doc(customer.id)
        .collection("notifications")
        .where("selectedNotificationsKeys", "array-contains", UserNotification.ReportSubscription)
        .where(`selectedNotifications.${UserNotification.ReportSubscription}.reportRef`, "==", reportRef);

      if (!useAllOrgs) {
        query = query.where(`selectedNotifications.${UserNotification.ReportSubscription}.orgRef`, "==", userOrg);
      }

      return query;
    },
    [customer.id, isDoitEmployee, useAllOrgs, userModel.organizations]
  );
}

export function useSubscribeSelf<ST extends SubscriptionType>({
  _subscriptionType,
  subscription,
}: {
  _subscriptionType: ST;
  subscription: SubscriptionByType<ST>;
}) {
  const { userModel } = useUserContext({ allowNull: false });

  return useCallback(async () => {
    const emailsToSave = [...(subscription.providerTarget?.[NotificationProviderType.EMAIL] ?? [])];
    emailsToSave.push(userModel.email);
    return subscription.ref.update("providerTarget", {
      ...subscription.providerTarget,
      [NotificationProviderType.EMAIL]: emailsToSave,
    });
  }, [subscription.providerTarget, subscription.ref, userModel.email]);
}

export function useUnsubscribeSelf<ST extends SubscriptionType>({
  _subscriptionType,
  subscription,
}: {
  _subscriptionType: ST;
  subscription: SubscriptionByType<ST>;
}) {
  const { userModel } = useUserContext({ allowNull: false });

  return useCallback(async () => {
    const emailsToSave = [...(subscription.providerTarget?.[NotificationProviderType.EMAIL] ?? [])].filter(
      (target) => target.toLowerCase() !== userModel.email.toLowerCase()
    );
    return subscription.ref.update("providerTarget", {
      ...subscription.providerTarget,
      [NotificationProviderType.EMAIL]: emailsToSave,
    });
  }, [subscription.providerTarget, subscription.ref, userModel.email]);
}

export async function getUserOrInviteByEmail({
  email,
  customerRef,
}: {
  email: string;
  customerRef: Reference<CustomerModel>;
}): Promise<Pick<UserModel, "organizations" | "customer" | "email">> {
  let userQuery = getCollection(UserModel).where("email", "==", email.trim().toLowerCase());
  let inviteQuery = getCollection(InviteModel).where("email", "==", email.trim().toLowerCase());

  if (!isDoitEmployeeEmail(email)) {
    userQuery = userQuery.where("customer.ref", "==", customerRef);
    inviteQuery = inviteQuery.where("customer.ref", "==", customerRef);
  }

  const inviteP = inviteQuery.get();
  const user = (await userQuery.get()).docs?.[0]?.asModelData();
  if (user) {
    return user;
  }

  const invite = (await inviteP).docs?.[0]?.asModelData();
  return invite;
}
