import { useCallback } from "react";

import { type AwsMarketplaceAccountModel, CustomerModel, MarketplaceModel } from "@doitintl/cmp-models";
import {
  getCollection,
  type ModelReference,
  type TransformMethod,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";

export const useAwsCustomersData = () =>
  useCollectionData(getCollection(CustomerModel).where("marketplace.AWS.accountExists", "==", true), { idField: "id" });

type AccountData = {
  account: WithFirebaseModel<AwsMarketplaceAccountModel>;
  accountRef: ModelReference<AwsMarketplaceAccountModel>;
};

export const useAccountData = () => {
  const transform: TransformMethod<AwsMarketplaceAccountModel, AccountData> = useCallback(
    (data, docSnapshot): AccountData => ({ account: data, accountRef: docSnapshot.modelRef }),
    []
  );

  return useCollectionData(
    getCollection(MarketplaceModel).doc("aws-marketplace").collection("awsMarketplaceAccounts"),
    {
      transform,
    }
  );
};
