import React, { useCallback, useState } from "react";

import { type CurrencyCode, TimeInterval } from "@doitintl/cmp-models";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { DateTime } from "luxon";

import { getCurrencySymbol, onKeyPressPreventNonNumeric } from "../../../../utils/common";

interface PeriodPullOverProps {
  currency: CurrencyCode;
  disabled: boolean;
  seasonalAmounts?: number[];
  onAmountsChange: (amounts: number[]) => void;
  periodType: TimeInterval;
  startDate?: DateTime;
  onValidityChange: (isValid: boolean) => void;
}

const getInitialPeriods = (periodType: TimeInterval, startDate?: DateTime) => {
  switch (periodType) {
    case TimeInterval.DAY: {
      const weekStart = DateTime.now().set({ weekday: 1 });
      return Array.from({ length: 7 }, (_, i) => {
        const dayName = weekStart.plus({ days: i }).toFormat("EEEE");
        return dayName;
      });
    }
    case TimeInterval.MONTH:
      return Array.from({ length: 12 }, (_, i) =>
        DateTime.now()
          .set({ month: i + 1 })
          .toFormat("MMMM")
      );
    case TimeInterval.QUARTER:
      return Array.from({ length: 4 }, (_, i) => `Q${i + 1}`);
    case TimeInterval.YEAR: {
      const startYear = startDate?.year ?? DateTime.now().year;
      return Array.from({ length: 5 }, (_, i) => String(startYear + i));
    }
    default:
      return [];
  }
};

const PeriodPullOver: React.FC<PeriodPullOverProps> = ({
  currency,
  disabled,
  seasonalAmounts,
  onAmountsChange,
  periodType,
  startDate,
  onValidityChange,
}) => {
  const periodNames = getInitialPeriods(periodType, startDate);
  const [localValid, setLocalValid] = useState(false);

  const [values, setValues] = useState<{ [key: string]: string }>(() => {
    const initialValues = periodNames.reduce(
      (acc, name, index) => ({
        ...acc,
        [name]: seasonalAmounts?.[index] ? seasonalAmounts[index].toString() : "",
      }),
      {}
    );

    const isInitiallyValid = periodNames.every((name) => {
      const value = initialValues[name];
      if (!value) return false;
      const parsed = parseFloat(value);
      return !isNaN(parsed) && parsed > 0;
    });
    setLocalValid(isInitiallyValid);
    onValidityChange(isInitiallyValid);

    return initialValues;
  });

  const checkValidity = useCallback(
    (currentValues: { [key: string]: string }) =>
      periodNames.every((name) => {
        const value = currentValues[name];
        if (!value) return false;
        const parsed = parseFloat(value);
        return !isNaN(parsed) && parsed > 0;
      }),
    [periodNames]
  );

  const notifyAmountsChange = useCallback(
    (currentValues: { [key: string]: string }) => {
      const amounts = periodNames.map((name) => {
        const val = currentValues[name];
        if (!val) return 0;
        const parsed = parseFloat(val);
        return isNaN(parsed) ? 0 : parsed;
      });
      onAmountsChange(amounts);
    },
    [onAmountsChange, periodNames]
  );

  const handleChange = (periodKey: string, value: string) => {
    const newValues = {
      ...values,
      [periodKey]: value,
    };
    setValues(newValues);

    const isValid = checkValidity(newValues);
    if (isValid !== localValid) {
      setLocalValid(isValid);
      onValidityChange(isValid);
    }

    notifyAmountsChange(newValues);
  };

  return (
    <Stack sx={{ gap: 3 }}>
      {periodNames.map((periodKey) => (
        <TextField
          key={`${periodType}-${periodKey}`}
          variant="outlined"
          type="number"
          fullWidth
          required
          disabled={disabled}
          label={periodKey}
          value={values[periodKey] ?? ""}
          onChange={(e) => {
            handleChange(periodKey, e.target.value);
          }}
          onKeyUp={onKeyPressPreventNonNumeric}
          slotProps={{
            input: {
              startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
              inputProps: { min: "0" },
            },
          }}
        />
      ))}
    </Stack>
  );
};

export default PeriodPullOver;
