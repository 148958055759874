import {
  AnalyticsResourceType,
  type AssetType,
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeMicrosoftAzure,
  type AttributionFilter,
  type ReportConfig,
} from "@doitintl/cmp-models";

import AmazonWebServicesIcon from "../../../assets/aws-logo.svg";
import AzureIcon from "../../../assets/azure-logo.svg";
import GoogleCloudIcon from "../../../assets/google-cloud.png";
import { attributionText } from "../../../assets/texts";
import { type Transforms } from "../../../Components/hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import { type IAsset, type MetadataOption, type ReportWSnap, type TemplateConfiguration } from "../../../types";
import { assetTypeName } from "../../../utils/common";
import { prepareScopeDescription } from "../alerts/shared";
import { type ReportTemplateCard, type ReportTemplateVisibleVersion, type ReportTemplateWithVersion } from "./types";

export const TemplatesCloud = {
  AWS: assetTypeName(AssetTypeAmazonWebServices),
  GOOGLE_CLOUD: assetTypeName(AssetTypeGoogleCloud),
  AZURE: assetTypeName(AssetTypeMicrosoftAzure),
};

export const TemplateCloudDefaultOptions: IAsset[] = [
  {
    key: AssetTypeAmazonWebServices,
    label: TemplatesCloud.AWS,
    selected: false,
  },
  {
    key: AssetTypeMicrosoftAzure,
    label: TemplatesCloud.AZURE,
    selected: false,
  },
  {
    key: AssetTypeGoogleCloud,
    label: TemplatesCloud.GOOGLE_CLOUD,
    selected: false,
  },
];

export const TemplatesCategories = ["Compute", "Networking", "Storage", "Data Warehouses", "Reservations", "Finance"];

export const getCloudValue = (label: string) => {
  switch (label) {
    case TemplatesCloud.AWS:
      return AssetTypeAmazonWebServices;
    case TemplatesCloud.AZURE:
      return AssetTypeMicrosoftAzure;
    case TemplatesCloud.GOOGLE_CLOUD:
      return AssetTypeGoogleCloud;
    default:
      return "";
  }
};

export const getCloudLabel = (label: string) => {
  switch (label) {
    case AssetTypeAmazonWebServices:
      return TemplatesCloud.AWS;
    case AssetTypeMicrosoftAzure:
      return TemplatesCloud.AZURE;
    case AssetTypeGoogleCloud:
      return TemplatesCloud.GOOGLE_CLOUD;
    default:
      return "";
  }
};

export const getCloudIcon = (cloud: AssetType) => {
  switch (cloud) {
    case AssetTypeAmazonWebServices:
      return AmazonWebServicesIcon;
    case AssetTypeGoogleCloud:
      return GoogleCloudIcon;
    case AssetTypeMicrosoftAzure:
      return AzureIcon;
    default:
      return null;
  }
};

export const searchInCategories = (cardVisibleVersion: ReportTemplateVisibleVersion, searchText: string) => {
  for (const category of cardVisibleVersion.categories ?? []) {
    if (category.toLowerCase().includes(searchText)) {
      return true;
    }
  }
  return false;
};

export const searchInClouds = (cardVisibleVersion: ReportTemplateVisibleVersion, searchText: string) => {
  for (const cloud of cardVisibleVersion.cloud ?? []) {
    const cloudName = assetTypeName(cloud).toLowerCase();
    if (cloudName.includes(searchText)) {
      return true;
    }
  }
  return false;
};

export const templateLibraryConfiguration = (
  transforms: Transforms,
  config: ReportConfig,
  dimensions?: MetadataOption[]
): TemplateConfiguration | undefined => {
  const filters =
    config.filters.flatMap((f) => {
      const filterVal = prepareScopeDescription(f as AttributionFilter, dimensions || [], transforms);
      let { desc, values } = filterVal;
      if (f?.regexp) {
        desc = f.inverse ? attributionText.REGEX_NO_MATCH : attributionText.REGEX_MATCH;
        values = f.regexp;
      }

      return desc && filterVal.label && values.length > 0
        ? {
            desc,
            label: filterVal.label,
            values,
          }
        : [];
    }) || [];

  const grouping =
    config.rows?.flatMap((r) => {
      const item = dimensions?.find((option) => option.id === r);
      return item?.data.label || [];
    }) || [];

  const configDimensions =
    config.cols?.flatMap((c) => {
      const item = dimensions?.find((option) => option.id === c);
      return item?.data.label || [];
    }) || [];
  return { filters, grouping, configDimensions };
};

export const getReportTemplatesCards = (
  reportTemplatesWithVersions: ReportTemplateWithVersion[] | null,
  reports: ReportWSnap[]
) => {
  const managedReports = reports.filter((report) => report.data.type === AnalyticsResourceType.MANAGED);
  let reportTemplateCards: ReportTemplateCard[] = [];

  if (reportTemplatesWithVersions && managedReports.length > 0) {
    reportTemplateCards = reportTemplatesWithVersions.flatMap((templateWithVersion) => {
      const version = templateWithVersion.version;
      const cardReport = managedReports.find((report) => report.ref.id === version.report?.id);
      if (!cardReport?.data.config) {
        return [];
      }
      const changesLen = version.approval.changes?.length;
      return {
        visibleVersion: {
          active: version.active,
          categories: version.categories,
          cloud: version.cloud,
          config: cardReport.data.config,
          createdBy: version.createdBy,
          description: cardReport.data.description,
          name: cardReport.data.name,
          reportId: cardReport.ref.id,
          rejectMessage: changesLen ? version.approval.changes[changesLen - 1].text : "",
          status: version.approval.status,
          visibility: version.visibility,
          timeModified: version.timeModified,
        },
        templateRefId: version.template.id,
        activeReportId: templateWithVersion.template.activeReport?.id,
      };
    });
  }

  return reportTemplateCards.sort((a, b) => a.visibleVersion.name.localeCompare(b.visibleVersion.name));
};
