import React, { useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { type AzureFeaturePermission } from "@doitintl/cmp-models";
import { Alert, Box, Button, Container, Typography } from "@mui/material";
import type { AlertColor, AlertPropsColorOverrides } from "@mui/material/Alert/Alert";
import type { OverridableStringUnion } from "@mui/types";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { customersRoute } from "../../../../Navigation/core/utils";
import { consoleErrorWithSentry } from "../../../../utils";
import { useAzureConnectApi } from "../hooks";
import { FeatureForm } from "../Stepper/FeatureForm";
import { type AzureRole, type RoleIds, type RoleRequest, type Scope } from "../types";
import { getNewResources } from "../utils";

type Props = {
  submitClicked: number;
  azureFeatures: AzureFeaturePermission[] | undefined;
  selectedFeaturesNames: string[];
  tenantID: string;
  roleRequestData: RoleRequest | undefined;
  setRoleRequestData: React.Dispatch<React.SetStateAction<RoleRequest | undefined>>;
  roles: AzureRole[];
  setRoles: React.Dispatch<React.SetStateAction<AzureRole[]>>;
  setStepsValid: React.Dispatch<React.SetStateAction<boolean[]>>;
  stepsValid: boolean[];
  currentStepIndex: number;
};

const ConnectResourcesStep = ({
  submitClicked,
  azureFeatures,
  selectedFeaturesNames,
  roleRequestData,
  tenantID,
  setRoleRequestData,
  setStepsValid,
  stepsValid,
  currentStepIndex,
}: Props) => {
  const history = useHistory();
  const [showTestConnectionAlert, setShowTestConnectionAlert] = useState(false);
  const [testConnectionAlert, setTestConnectionAlert] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<OverridableStringUnion<AlertColor, AlertPropsColorOverrides>>("success");

  const [rolesIds, setRolesIds] = useState<RoleIds[]>([]);
  const [connectionValid, setConnectionValid] = useState(false);

  const showSnackbar = (severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>, title: string) => {
    setSnackbarSeverity(severity);
    setTestConnectionAlert(title);
    setShowTestConnectionAlert(true);
  };

  const customerId = useCustomerId();

  const selectedFeatures = useMemo(
    () =>
      azureFeatures?.filter((feature: AzureFeaturePermission) => selectedFeaturesNames.includes(feature.name)) || [],
    [azureFeatures, selectedFeaturesNames]
  );

  useEffect(() => {
    const rolesValid =
      rolesIds.every((role) => role.roleId !== "" && role.resourceId !== "") &&
      rolesIds.length ===
        selectedFeatures.reduce((acc: number, feature: AzureFeaturePermission) => acc + feature.roles.length, 0);
    const isFormValid = rolesValid && tenantID !== "";
    const stateValid = isFormValid && rolesValid && connectionValid;
    const newStatesValid = [...stepsValid];
    newStatesValid[currentStepIndex] = stateValid;
    setStepsValid(newStatesValid);
  }, [stepsValid, setStepsValid, currentStepIndex, rolesIds, selectedFeatures, connectionValid, tenantID]);

  const azureConnectApi = useAzureConnectApi();

  useEffect(() => {
    setRoleRequestData({
      tenantId: tenantID,
      features: selectedFeatures.map((feature) => ({
        name: feature.name,
        roles: rolesIds
          .filter((role) => role.featureName === feature.name)
          .map((role) => ({
            resourceId: role.resourceId,
            roleId: role.roleId,
            scope: role.scope,
          })),
      })),
    });
  }, [setRoleRequestData, tenantID, selectedFeatures, rolesIds]);

  useEffect(() => {
    const handleSubmit = async () => {
      try {
        await azureConnectApi.addAzureRole(customerId, roleRequestData);
        showSnackbar("success", "Role added successfully");
      } catch (err) {
        showSnackbar("error", "Error adding role");
        consoleErrorWithSentry(err);
      }
    };

    async function handleSubmitClicked() {
      await handleSubmit();
    }

    if (submitClicked > 0) {
      handleSubmitClicked()
        .then(() => {
          history.push(`${customersRoute(customerId)}/settings/azure`);
        })
        .catch();
    }
  }, [customerId, azureConnectApi, roleRequestData, submitClicked, history]);

  const handleTestConnection = async () => {
    try {
      const subscription = await azureConnectApi.healthcheck(customerId, tenantID);
      if (subscription.length) {
        showSnackbar("success", "Connection successful");
        setConnectionValid(true);
      } else {
        setConnectionValid(false);
      }
    } catch (err) {
      setConnectionValid(false);
      showSnackbar("error", "Connection failed");
    }
  };

  const updateRoles = (featureName: string, roleName: string, scope: Scope, resourceId: string, roleId: string) => {
    setRolesIds(getNewResources(rolesIds, featureName, roleName, scope, resourceId, roleId));
  };

  return (
    <Container maxWidth={false} sx={{ maxWidth: "688px" }} disableGutters key={"connect-azure-resource-container"}>
      <>
        <Typography
          variant="h3"
          sx={{
            mb: 3,
            fontSize: 20,
          }}
        >
          Grant access to feature resources
        </Typography>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 4,
          }}
          noValidate
          autoComplete="off"
        >
          <>
            {selectedFeatures.map((feature, featureIdx) => (
              <FeatureForm
                key={`feature-fragment-${featureIdx}`}
                feature={feature}
                roleChanged={updateRoles}
                openConsoleButton={featureIdx === 0}
                featureNameTitle={selectedFeatures.length > 1}
              />
            ))}
            {selectedFeatures.length > 1 ? (
              <Typography variant="h4">Test connection</Typography>
            ) : (
              <Typography variant="body2" sx={{ opacity: 0.87 }}>
                4. Test connection
              </Typography>
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="outlined" color="primary" onClick={handleTestConnection}>
                Test connection
              </Button>
            </Box>
            {showTestConnectionAlert && <Alert severity={snackbarSeverity}>{testConnectionAlert}</Alert>}
          </>
        </Box>
      </>
    </Container>
  );
};

export default ConnectResourcesStep;
