import { useCallback } from "react";

import { type QueryKey, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { type DocumentData, type FirestoreError, type SnapshotListenOptions } from "firebase/firestore";

import {
  type FirebaseDocumentSnapshotModel,
  type FirebaseModelReference,
  type FirebaseQuerySnapshotModel,
  type GetSnapshotOptions,
} from "../core";
import { type QueryType, resolveQuery, type UseFirestoreHookOptions } from "./index";
import {
  useSubscriptionDocument,
  type UseSubscriptionDocumentOptions,
  useSubscriptionQuery,
  type UseSubscriptionQueryOptions,
} from "./useSubscriptionQuery";

type NextOrObserverQuery<TModel extends DocumentData> = (data: FirebaseQuerySnapshotModel<TModel>) => void;

type NextOrObserverDocument<TModel extends DocumentData> = (data: FirebaseDocumentSnapshotModel<TModel>) => void;

export const useFirestoreCollection = <TModel extends DocumentData, R = FirebaseQuerySnapshotModel<TModel>>(
  queryKey: QueryKey | undefined,
  query: QueryType<TModel> | undefined,
  options?: UseFirestoreHookOptions,
  useQueryOptions?: Omit<UseQueryOptions<FirebaseQuerySnapshotModel<TModel>, FirestoreError, R>, "queryFn">
): UseQueryResult<R, FirestoreError> => {
  const isSubscription = !!options?.subscribe;

  const subscribeFn = useCallback(
    async (callback: NextOrObserverQuery<TModel>, onError: (error: any) => void) => {
      const resolvedQuery = await resolveQuery<TModel>(query);

      if (resolvedQuery) {
        return resolvedQuery.onSnapshotWithOptions(options as SnapshotListenOptions | undefined, callback, onError);
      }

      return undefined;
    },
    [options, query]
  );

  const fetchFn = useCallback(
    async (): Promise<FirebaseQuerySnapshotModel<TModel> | undefined> =>
      resolveQuery<TModel>(query).then((resolvedQuery) =>
        resolvedQuery?.get(options as GetSnapshotOptions | undefined)
      ),
    [options, query]
  );

  return useSubscriptionQuery(queryKey, {
    ...useQueryOptions,
    enabled: Boolean(query) && Boolean(queryKey),
    subscribeFn: isSubscription ? subscribeFn : undefined,
    isSubscription,
    fetchFn: isSubscription ? undefined : fetchFn,
  } as UseSubscriptionQueryOptions<TModel, FirestoreError, R>);
};

export const useFirestoreDocument = <TModel extends DocumentData, R = FirebaseDocumentSnapshotModel<TModel>>(
  queryKey: QueryKey | undefined,
  docRef: FirebaseModelReference<TModel> | undefined,
  options?: UseFirestoreHookOptions,
  useQueryOptions?: Omit<UseQueryOptions<FirebaseModelReference<TModel>, FirestoreError, R>, "queryFn">
): UseQueryResult<R, FirestoreError> => {
  const isSubscription = !!options?.subscribe;

  const subscribeDocFn = useCallback(
    (callback: NextOrObserverDocument<TModel>, onError: (error: any) => void) => {
      if (docRef) {
        return docRef.onSnapshotWithOptions(options as SnapshotListenOptions | undefined, callback, onError);
      }

      return undefined;
    },
    [options, docRef]
  );

  const fetchFn = useCallback(
    async (): Promise<FirebaseDocumentSnapshotModel<TModel> | undefined> =>
      docRef?.get(options as GetSnapshotOptions | undefined),
    [options, docRef]
  );

  return useSubscriptionDocument(queryKey, {
    ...useQueryOptions,
    enabled: Boolean(docRef) && Boolean(queryKey),
    subscribeFn: isSubscription ? subscribeDocFn : undefined,
    isSubscription,
    fetchFn: isSubscription ? undefined : fetchFn,
  } as UseSubscriptionDocumentOptions<TModel, FirestoreError, R>);
};
