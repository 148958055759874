import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../Components/FilterTable/filterTableUtils";
import type { AzureSubscription } from "../types";

export const itemsDescriptions: FilterTableItemDescriptor<AzureSubscription>[] = [
  {
    headerText: "Subscription name",
    value: "displayName",
  },
  {
    headerText: "Tenant ID",
    value: "tenantId",
  },
  {
    headerText: "Subscription ID",
    value: "subscriptionId",
  },
  {
    headerText: "Features",
    value: "features",
  },
  {
    headerText: "Link time",
    value: "timeLinked",
  },
  {
    headerText: "",
    value: "@",
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
