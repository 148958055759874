import { type CloudAnalyticsModelAttributionGroupsModel, Roles } from "@doitintl/cmp-models";
import { type DocumentSnapshotModel, type WithFirebaseModel } from "@doitintl/models-firestore";

import { type AnalyticsResourcesAttributionGroups } from "../analyticsResources/types";
import { BasicMenuOptions, formatFirestoreDate } from "../utilities";
import { type AttributionGroupWithRef } from "./types";

export const attributionGroupsTransform = (
  data: WithFirebaseModel<CloudAnalyticsModelAttributionGroupsModel>,
  docSnapshot: DocumentSnapshotModel<CloudAnalyticsModelAttributionGroupsModel>
): AttributionGroupWithRef => ({
  data,
  ref: docSnapshot.ref,
  transform: {
    owner: data.collaborators.find((c) => c.role === Roles.OWNER)?.email,
    timeModified: formatFirestoreDate(data.timeModified),
  },
});

export type AttributionGroupsMenuOption = {
  label: string;
  value: string;
  disabled?: boolean;
  color?: string;
};

export const attributionGroupMenuOptions: AttributionGroupsMenuOption[] = [
  {
    label: BasicMenuOptions.SHARE,
    value: BasicMenuOptions.SHARE,
  },
  {
    label: BasicMenuOptions.DUPLICATE,
    value: BasicMenuOptions.DUPLICATE,
  },
  {
    label: BasicMenuOptions.DELETE,
    value: BasicMenuOptions.DELETE,
    color: "error",
  },
];

export const getAttributionsIDsFromAG = (
  ag: AttributionGroupWithRef | AnalyticsResourcesAttributionGroups | undefined
): string[] => ag?.data.attributions.map((attribution) => attribution.id) ?? [];
