import "reflect-metadata";

const NAME_KEY = Symbol("@coll");

export type ModelDocumentFieldValue = any;

export interface ModelDocumentData {
  [field: string]: ModelDocumentFieldValue;
}

export function collection(className: string) {
  return (Reflect as any).metadata(NAME_KEY, className);
}

export function doc(className: string) {
  return (Reflect as any).metadata(NAME_KEY, className);
}

export const subCollection = collection;

export function getCollectionName(type: any): string {
  return (Reflect as any).getMetadata(NAME_KEY, type);
}

export type OmitDeclFields<TModel> = Omit<TModel, "docs" | "subCollections">;

type HaveSubCollections = {
  subCollections?: ModelDocumentData;
};

export type SubCollectionIdOfTModel<TModel> = TModel extends HaveSubCollections
  ? keyof NonNullable<TModel["subCollections"]>
  : string;

export type SubCollectionOfTMModel<TModel, TSubCollectionId> = TModel extends HaveSubCollections
  ? TSubCollectionId extends keyof NonNullable<TModel["subCollections"]>
    ? NonNullable<TModel["subCollections"]>[TSubCollectionId]
    : never
  : never;

type HaveDocs = {
  docs?: ModelDocumentData;
};

export type DocIdOfTModel<TModel extends ModelDocumentData> = TModel extends HaveDocs
  ? keyof NonNullable<TModel["docs"]>
  : string;

export type DocModelOfTModel<TModel extends ModelDocumentData, TDocId extends string> = TModel extends HaveDocs
  ? TDocId extends keyof NonNullable<TModel["docs"]>
    ? NonNullable<TModel["docs"]>[TDocId]
    : never
  : TModel;
